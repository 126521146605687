.image-radio-field {
  padding-bottom: 40px;
  position: relative;

  .image-radio-field__label {
    @include typography(heading-5, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .image-radio-field__container {
    column-gap: var(--grid-gutter);
    display: grid;
    row-gap: 20px;

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .image-radio-field__item {
    position: relative;

    &:focus-within {
      outline: 1px solid;
      outline-offset: 2px;
    }
  }

  .image-radio-field__tag {
    @include typography(tag);

    background-color: $color-orange;
    border-radius: 2px;
    color: $color-purple-dark;
    left: 10px;
    padding: 5px 10px;
    pointer-events: none;
    position: absolute;
    top: 10px;
    z-index: 1;
  }

  .image-radio-field__input-label {
    cursor: pointer;
    user-select: none;
  }

  .image-radio-field__radio-container {
    margin-top: 12px;
    position: relative;
  }

  .image-radio-field__input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:checked {
      + .image-radio-field__radio-container {
        .image-radio-field__icon-foreground {
          opacity: 1;
        }
      }
    }
  }

  .image-radio-field__input-label-span {
    display: inline-block;
    padding-left: 32px;
  }

  .image-radio-field__icon {
    border-radius: 50%;
    color: $color-grey-extradark;
    display: block;
    left: 0;
    position: absolute;
    top: .1em;
    transition: opacity $easing-change-faster, background-color $easing-change-faster, color $easing-change-faster;
  }

  .image-radio-field__icon-background {
    border: 1px solid $color-purple-lighter;
    height: 20px;
    opacity: 1;
    width: 20px;
  }

  .image-radio-field__icon-foreground {
    background: $color-purple-dark;
    border-radius: 50%;
    height: 14px;
    margin: 3px;
    opacity: 0;
    width: 14px;
  }

  &.image-radio-field--error {
    &:not(:focus-within) {
      .image-radio-field__container {
        outline: 1px solid $color-error;
        outline-offset: 2px;
      }
    }

    .image-radio-field__error {
      @include typography(field-helptext);

      margin-top: 10px;
    }

    .image-radio-field__error-icon {
      @include field-error-icon;
    }
  }
}
