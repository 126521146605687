.accordion-item {
  border-bottom: 1px solid $color-purple-dark;
  cursor: pointer;
  margin-top: 5px;
  padding: 5px 0;
  position: relative;

  .accordion-item__title {
    @include typography(heading-4);
  }

  .accordion-item__links {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease-in-out;

    .link:last-child {
      padding-bottom: 20px;
    }

    .link:first-child {
      padding-top: 15px;
    }
  }

  .accordion-item__button {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .accordion-item__button::after {
    content: '+';
    font-weight: 200 !important;
    height: fit-content;
    transition: content .3s ease-in-out;
    width: fit-content;

    @include typography(heading-1);
  }

  &.open {
    .accordion-item__button::after {
      content: '–';
    }
  }
}
