
.article-video {
  padding: var(--component-margin) 0;
  position: relative;

  .article-video__grid {
    @include grid-container;
  }

  .article-video__wrapper {
    grid-column: var(--layout-content-image);
  }
}

.article-video--full-height {
  @include notebook {
    min-height: 100vh;
  }
}
