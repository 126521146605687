@include animated-underline('.button-toggle', '.button-toggle__span');

.button-toggle {
  --icon-size: var(--introduction-line-height);

  @include typography(introduction);

  &.button-toggle--is-collapsed {
    .button-toggle__icon--expanded,
    .button-toggle__span--toggle {
      display: none;
    }
  }

  &:not(.button-toggle--is-collapsed) {
    .button-toggle__icon--collapsed,
    .button-toggle__span {
      display: none;
    }
  }

  .button-toggle__icon {
    display: inline-block;
    height: var(--icon-size);
    position: relative;
    top: 1px;
    transform: none;
    transform-origin: 50% 50%;
    vertical-align: top;
    width: var(--icon-size);
  }
}
