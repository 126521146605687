.article-calendar {
  padding: calc(var(--component-margin) * 1.5) 0;
  position: relative;

  .article-calendar__grid {
    @include grid-container;
  }

  .article-calendar__wrapper {
    grid-column: var(--layout-content-large);
  }

  .article-calendar__title {
    @include typography(heading-3, false, true);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .article-calendar__introduction {
    @include typography(paragraph);

    margin-bottom: 1.5rem;
  }

  .article-calendar__items-grid {
    display: grid;
    gap: var(--grid-gutter);

    @include notebook {
      grid-template-columns: .6fr .4fr;

      .article-calendar__items {
        order: -1;
      }
    }
  }

  .article-calendar__item {
    background-color: $color-grey;
    border-radius: 10px;
    color: $color-purple-dark;
    display: flex;
    padding: 20px 15px;

    &:not(:first-child) {
      margin-top: var(--grid-gutter);
    }

    &:focus,
    &:hover {
      .article-calender__item-title span {
        background-size: 100% 1px;
      }
    }

    @include notebook {
      margin-right: 10%;
    }
  }

  .article-calender__item-date {
    margin-right: 20px;
  }

  .article-calender__item-title {
    @include typography(heading-5);

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }

  .article-calender__item-excerpt {
    &.just-occured {
      .article-calender__item-excerpt-text {
        text-decoration: line-through;
      }

      &::after {
        @include typography(heading-6);

        content: 'Net plaatsgevonden';
        display: inline;
        margin-left: .25rem;
      }
    }
  }

  .article-calendar__overview-link {
    margin-top: 20px;
  }
}
