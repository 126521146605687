.dossier-testimonials {
  padding: var(--component-margin) 0;
  position: relative;

  @include notebook {
    padding: calc(var(--component-margin) * 5) 0;
  }

  .dossier-testimonials__grid {
    @include grid-container;
  }

  .dossier-testimonials__wrapper {
    grid-column: var(--layout-content);
  }

  .dossier-testimonials__title {
    @include typography(heading-2);
  }

  .dossier-testimonials__navigation {
    margin: 6px 0 6px -10px;
  }

  .dossier-testimonials__item {
    display: grid;
    margin-left: var(--single-grid-column-with-gutter);
    margin-right: var(--single-grid-column-with-gutter);
    row-gap: calc(var(--grid-gutter) * 2);

    @include notebook {
      column-gap: calc(var(--grid-gutter) + var(--single-grid-column-with-gutter));
      grid-template-columns: calc((var(--single-grid-column) * 5) + (var(--grid-gutter) * 4)) 1fr;
      margin-left: calc(var(--single-grid-column-with-gutter) * 2);
      margin-right: calc(var(--single-grid-column-with-gutter) * 2);
    }

    @include desktop {
      margin-left: calc(var(--single-grid-column-with-gutter) * 3);
      margin-right: calc(var(--single-grid-column-with-gutter) * 3);
    }
  }

  .dossier-testimonials__item--liststyle {
    color: $color-purple-dark;
    margin-bottom: 10px;
    row-gap: 0;

    @include notebook {
      column-gap: 0;
      grid-template-columns: calc((var(--single-grid-column) * 3) + (var(--grid-gutter) * 4)) 1fr;
    }

    .picture--full-height {
      height: 100%;
    }

    .picture--rounded-corners {
      border-radius: 10px 10px 0 0;

      @include notebook {
        border-radius: 10px 0 0 10px;
      }

      .picture__image {
        border-radius: 10px 10px 0 0;

        @include notebook {
          border-radius: 10px 0 0 10px;
        }
      }
    }

    &:hover {
      .dossier-testimonials__item-title {
        text-decoration: underline;
      }

      .dossier-testimonials__content {
        background-color: rgba(255, 255, 255, .5);
      }
    }
  }

  .dossier-testimonials__content--white-background {
    background-color: $color-white;
    border-radius: 0 0 10px 10px;
    padding: 30px 20px;

    @include notebook {
      border-radius: 0 10px 10px 0;
      padding: 50px;
    }
  }

  .dossier-testimonials__item-kicker {
    @include typography(caption);
  }

  .dossier-testimonials__item-title {
    @include typography(heading-3, false, true);
  }

  .dossier-testimonials__item-link {
    margin-top: 30px;
  }
}

.dossier-testimonials--list {
  padding: var(--component-margin) 0;

  .dossier-testimonials__title {
    margin-bottom: 15px;

    @include notebook {
      margin-bottom: 20px;
    }

    @include desktop {
      margin-bottom: 30px;
    }
  }

  .dossier-testimonials__item-kicker {
    margin-bottom: 5px;
  }
}
