.u-margin-top-10 {
  margin-top: 0;

  @include tablet {
    margin-top: 10px;
  }
}

.u-margin-top-20 {
  margin-top: 0;

  @include tablet {
    margin-top: 20px;
  }
}

.u-margin-bottom-10 {
  margin-bottom: 0;

  @include tablet {
    margin-bottom: 10px;
  }
}

.u-margin-bottom-20 {
  margin-bottom: 0;

  @include tablet {
    margin-bottom: 20px;
  }
}
