.modal {
  color: $color-black;
  opacity: 0;
  pointer-events: none;

  &.is-open {
    opacity: 1;
    pointer-events: all;

    .modal__container {
      max-width: 500px;
      transform: scale(1);
    }
  }

  &--confirm {
    .modal__wrapper {
      max-width: 100%;

      @include tablet {
        max-width: 500px;
      }
    }

    .modal__form {
      display: flex;
      margin-top: 20px;
    }

    .modal__button-cancel {
      margin-left: 20px;

      * {
        // The modal checks the event target, which we want to always be the element itself */
        pointer-events: none;
      }
    }
  }

  .modal__overlay {
    background: rgba($color-purple-dark, .25);
    bottom: 0;
    display: grid;
    left: 0;
    place-items: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20000;
  }

  .modal__container {
    background-color: $color-white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    max-height: 100vh;
    overflow-y: auto;
    transform: scale(.1);
    transition: transform .3s ease;

    @include tablet {
      min-width: 500px;
    }
  }

  .modal__wrapper {
    padding: 30px;
  }

  .modal__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .modal__title {
    @include typography(heading-4);

    margin-bottom: 0;
    margin-top: 0;
    max-width: 426px;
  }

  .modal__close {
    background: transparent;
    border: 0;
    margin-left: 20px;

    svg {
      height: 20px;
      pointer-events: none;
      width: 20px;
    }
  }

  .modal__content {
    @include typography(body-small);
  }

  .modal-cao-downloads__outro {
    margin-bottom: var(--component-margin);
  }

  .cao-header__link {
    display: block;
    padding: 10px;
    position: relative;
    top: 10px;
  }

  .modal__intro {
    margin-bottom: 10px;
  }

  .modal__form-btn {
    display: flex;
    justify-content: flex-end;
  }
}
