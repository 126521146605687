.profile-detail-actions {
  .profile-detail-actions__link {
    float: left;
    font-size: 14px;

    &:first-child {
      margin-right: 50px;
      text-align: left;
      width: 90px;
    }

    .button-toggle__span,
    .button-toggle__span--toggle {
      left: 10px;
      position: relative;
    }
  }
}
