.article-nav-link {
  padding-bottom: calc(3 * var(--component-margin));
  padding-top: calc(.5 * var(--component-margin));

  .article-nav-link__grid {
    @include grid-container;
  }

  .article-nav-link__wrapper {
    grid-column: var(--layout-content-image);
  }

  .link {
    @include typography(caption);

    color: $color-purple-light;
    font-family: var(--introduction-font-stack);
    font-size: var(--introduction-font-size);
    font-style: var(--introduction-font-style);
    font-variant: var(--introduction-font-variant);
    font-weight: var(--introduction-font-weight);
    line-height: var(--introduction-line-height);
  }

  .link__icon {
    height: var(--link-line-height);
    width: var(--link-line-height);
  }
}
