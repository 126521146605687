.card-grid-generic {
  position: relative;

  .card-grid-generic__header {
    margin-bottom: var(--component-margin);
  }

  .card-grid-generic__cards {
    display: grid;
    gap: var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .card-grid-generic__footer {
    margin-top: var(--component-margin);
  }
}

.card-grid-generic--standalone {
  padding: var(--component-margin) 0;
}

.card-grid-generic--columns-2 {
  .card-grid-generic__cards {
    display: grid;
    gap: var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.card-grid-generic--columns-3 {
  .card-grid-generic__cards {
    display: grid;
    gap: var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
