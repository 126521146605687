.poll {
  background-color: $color-grey-lighter;
  border: 1px solid $color-purple-light;
  border-radius: 10px;
  color: $color-purple-dark;
  margin: 20px;
  max-width: 358px;
  padding: 25px;

  .poll__container {
    transform: translateZ(0);
  }

  .poll__title {
    letter-spacing: -.37px;
  }

  .time-remaining {
    color: $color-purple-light;
    line-height: 2;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      border: solid 1px $color-purple-light;
      border-radius: 2px;
      margin-bottom: 3px;

      button {
        display: flex;
        padding: 10px;
        width: 100%;

        &:focus {
          .circle {
            height: 20px;
            width: 20px;
          }

          .circle-center {
            background-color: $color-purple-dark;
            transition: background-color .4s ease-in-out;
          }
        }

        .circle {
          height: 20px;
          margin-right: 18px;
          width: 20px;
        }

        .circle-center {
          height: 14px;
          margin: 2px;
          width: 14px;
        }

        .button-label {
          flex-grow: 1;
          letter-spacing: -.27px;
          line-height: 1.4;
          margin-top: -3px;
          text-align: left;
        }
      }
    }
  }

  .total-votes {
    color: $color-purple-light;
    margin-top: 20px;
  }

  .poll__results {
    ul {
      li {
        display: flex;
        position: relative;

        &.selected {
          border-color: $color-orange;
          border-width: 2px;

          .progress-bar {
            background-color: $color-orange;
          }
        }

        .result-percentage {
          font-size: 14px;
          margin: 9px 10px 0;
        }

        .answer-label {
          flex-grow: 1;
          margin: 8px 0 10px 10px;
        }

        .progress-bar {
          background-color: $color-purple-light2;
          border-radius: 2px;
          height: 100%;
          position: absolute;
          transition: width 2s ease-in-out;
          width: 0%;
          z-index: -1;
        }
      }
    }
  }

  .poll__modal-wrapper {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
