.conversion-banner {
  color: $color-white;
  overflow-x: hidden;
  padding-bottom: var(--component-margin);
  padding-top: var(--component-margin);

  .conversion-banner__grid {
    @include grid-container;
  }

  .conversion-banner__balloon-left {
    background: $color-purple;
    border-bottom-left-radius: var(--round-corner-size);
    border-top-left-radius: var(--round-corner-size);
    grid-column: 1 / span 1;
    margin-right: calc(var(--grid-gutter) * -1 + -1px);

    @include notebook {
      grid-column: 2 / span 1;
    }

    @include desktop {
      grid-column: 3 / span 1;
    }
  }

  .conversion-banner__balloon-center {
    align-items: center;
    background: $color-purple;
    display: flex;
    flex-direction: column;
    grid-column: 2 / span 12;
    padding: calc(var(--component-margin) * 2) 0;
    position: relative;
    z-index: 1;

    @include notebook {
      grid-column: 3 / span 10;
    }

    @include desktop {
      grid-column: 4 / span 10;
    }
  }

  .conversion-banner__balloon-right {
    background: $color-purple;
    grid-column: 14 / span 1;
    margin-left: calc(var(--grid-gutter) * -1 + -1px);
    position: relative;

    @include notebook {
      grid-column: 13 / span 2;
    }

    @include desktop {
      grid-column: 14 / span 3;
    }
  }

  .conversion-banner__title {
    @include typography(heading-1);

    text-align: center;
  }

  .conversion-banner__introduction {
    @include typography(introduction, true);
  }

  .conversion-banner__button {
    margin-top: var(--component-margin);
  }

  .conversion-banner__microcopy {
    margin-top: calc(var(--component-margin) / 3);
    text-align: center;

    @include tablet {
      max-width: 50%;
    }
  }

  .conversion-banner__form {
    margin-top: var(--component-margin);
    max-width: 538px;
    width: 100%;

    .form__wrapper {
      display: grid;
      gap: 10px;

      @include notebook {
        align-items: center;
        grid-template-columns: 1fr auto;
      }

      .button {
        justify-self: center;
      }
    }
  }

  .conversion-banner__form-approval {
    order: 3;
  }

  .conversion-banner__usps {
    display: grid;
    list-style: none;
    margin-top: var(--component-margin);
    padding: 0;
    row-gap: 25px;
    width: auto;

    @include notebook {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .conversion-banner__usp {
    @include typography(paragraph);

    align-items: baseline;
    display: grid;
    grid-template-columns: var(--paragraph-line-height) auto;

    > svg {
      height: var(--paragraph-line-height);
      width: var(--paragraph-line-height);
    }

    @include notebook {
      width: calc(100% / 3);

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
}

.conversion-banner--bottom-page {
  --section-background: #{$color-white};

  background: $color-purple;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  .conversion-banner__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column: var(--layout-content-large);
    padding-bottom: calc(var(--component-margin) * 2.5);
    padding-top: calc(var(--component-margin) * 2.5);
  }
}

.conversion-banner--bottom-page-section-background-grey {
  --section-background: #{$color-grey};
}

.conversion-banner--bottom-page-stripe-left,
.conversion-banner--bottom-page-stripe-left-indented {
  &::before {
    background-color: var(--section-background);
    border-bottom-left-radius: var(--round-corner-size);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;

    @include desktop {
      margin-right: 0;
      position: relative;
    }
  }
}

.conversion-banner--bottom-page-stripe-right,
.conversion-banner--bottom-page-stripe-right-indented {
  &::before {
    background-color: var(--section-background);
    border-bottom-right-radius: var(--round-corner-size);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;

    @include desktop {
      margin-left: 0;
      position: relative;
    }
  }
}

.conversion-banner--bottom-page-stripe-right-indented {
  &::before {
    right: var(--single-grid-column-with-gutter);
  }
}

.conversion-banner--bottom-page-stripe-left-indented {
  &::before {
    left: var(--single-grid-column-with-gutter);
  }
}

.conversion-banner--bottom-page-stripe-right-big {
  &::before {
    background-color: var(--section-background);
    border-bottom-right-radius: var(--round-corner-size);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;

    @include desktop {
      position: relative;
      right: var(--single-grid-column-with-gutter);
    }
  }
}

.conversion-banner--bottom-page-stripe-fullwidth {
  &::before {
    margin-left: 0;
    right: 0;
  }
}
