.toggle {
  &__main-label {
    @include typography(heading-5, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  &__input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;

    &:checked ~ .toggle__handle-container {
      .toggle {
        &__background {
          @extend .background-primary--lighten-50 !optional;
        }

        &__handle {
          @extend .background-primary--darken-10 !optional;

          left: 63px;

          &::before {
            @extend .background-primary--darken-10 !optional;
          }
        }
      }
    }

    &:checked ~ .toggle__label-container .toggle__label::before {
      opacity: 0;
    }

    &:checked ~ .toggle__label-container .toggle__label::after {
      opacity: 1;
    }
  }

  &__label-container {
    display: inline-block;
    height: 26px;
    position: relative;
  }

  &__label {
    display: block;
    height: 26px;
    position: relative;
    transition: all .15s ease-out 0s;

    &::before,
    &::after {
      line-height: 1;
      margin-top: -.5em;
      position: absolute;
      top: 50%;
      transition: inherit;
    }

    &::before {
      @extend .text-primary !optional;

      content: attr(data-off);
      margin-left: 7px;
    }

    &::after {
      @extend .text-primary !optional;

      content: attr(data-on);
      opacity: 0;
    }
  }

  &__control {
    border-radius: 12px;
    cursor: pointer;
    display: block;
    position: relative;
    width: 90px;
  }

  &__handle-container {
    display: inline-block;
    height: 26px;
    position: relative;
    width: 42px;
  }

  &__background {
    @extend .background-primary--lighten-60 !optional;

    background-image: none;
    background-position: 0 0;
    background-repeat: repeat scroll;
    border-radius: 14px;
    display: block;
    height: 26px;
    left: 46px;
    position: absolute;
    top: 0;
    transition: all .15s ease-out 0s;
    width: inherit;
  }

  &__handle {
    @extend .background-primary !optional;

    border-radius: 100%;
    height: 22px;
    left: 49px;
    position: absolute;
    top: 2px;
    transition: left .15s ease-out 0s;
    width: 22px;

    &::before {
      @extend .background-primary !optional;

      border-radius: 6px;
      content: '';
      height: 12px;
      left: 50%;
      margin: -6px 0 0 -6px;
      position: absolute;
      top: 50%;
      width: 12px;
    }
  }
}
