.profile-work {
  grid-column: var(--layout-grid-inline);
  width: 100%;

  @include notebook {
    width: 73%;
  }

  .profile-work__title {
    font-size: 20px;
    margin-bottom: 5px;
  }

  &--public {
    .profile-work__title {
      margin-bottom: 16px;
    }
  }

  .profile-work__text {
    margin-bottom: 15px;
  }

  .profile-work__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 60px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .profile-work__hidden-triggers {
    display: none;
  }
}

.profile-work-list {
  display: contents;
}

.profile-work-card {
  background-color: $color-white;
  border: 1px solid rgba(212, 202, 215, .5);
  float: left;
  min-height: 125px;
  padding: 10px;
  position: relative;

  .button-toggle__icon {
    margin-top: 3px;
  }

  .button-toggle__title {
    font-size: small;
  }

  .dropdown-menu-simple {
    float: right;

    .dropdown-menu-simple__items {
      bottom: 30px;
      top: auto;
    }
  }

  &.profile-work-card--featured {
    background-color: rgba(212, 202, 215, .5);
  }

  .profile-work-card__link-icon {
    background-color: $color-purple-lightest;
    border-radius: 5px;
    box-shadow: inset 0px -1px 0px #bcabc1;
    float: right;
    height: 32px;
    width: 32px;

    svg {
      left: 6px;
      position: relative;
      top: 3px;
    }
  }

  .profile-work-card__content {
    min-height: 80px;
  }

  .profile-work-card__subtitle {
    color: $color-purple-light;
    font-size: 12px;
    font-weight: bold;
  }

  .profile-work-card__title {
    color: $color-purple-dark;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    min-height: 50px;
    width: 85%;
  }

  .profile-work-card__link {
    display: block;

    &:hover {
      .profile-work-card__link-icon {
        background-color: #bcabc1;
        box-shadow: inset 0px -1px 0px $color-purple-light;
      }

      .profile-work-card__title {
        text-decoration: underline;
      }
    }
  }

  .profile-work-card__add-button {
    bottom: 15px;
    font-size: 13px;
    padding: 4px 15px 6px 10px;
    position: absolute;

    svg {
      position: relative;
      top: 2px;
    }
  }

  .profile-work-card__action-dropdown {
    width: 13px;
  }
}
