
// Mixins
@mixin field-error-icon {
  align-items: center;
  border-radius: 50%;
  color: $color-white;
  display: inline-flex;
  height: var(--field-helptext-font-size);
  justify-content: center;
  margin-right: 2px;
  position: relative;
  top: -2px;
  width: var(--field-helptext-font-size);

  svg {
    position: relative;
    top: 3px;

    g {
      stroke-width: 5;
    }
  }
}

@mixin quote-icon-start {
  background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="' + $color-purple + '"><path d="M13.308 27V15.078H7.077c0-3.764 1.846-5.254 6.23-5.254V3C4.386 3 0 7.47 0 16.49V27h13.308zM30 27V15.078h-6.23c0-3.764 1.845-5.254 6.23-5.254V3c-8.923 0-13.308 4.47-13.308 13.49V27H30z"/></svg>') 50% 50% no-repeat;
  display: block;
  height: 20px;
  // left: -30px;
  position: relative;
  width: 20px;

  @include notebook {
    height: 30px;
    left: -45px;
    width: 30px;
  }
}

@mixin quote-icon-end {
  background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="' + $color-purple + '"><path d="M13.308 27V15.078H7.077c0-3.764 1.846-5.254 6.23-5.254V3C4.386 3 0 7.47 0 16.49V27h13.308zM30 27V15.078h-6.23c0-3.764 1.845-5.254 6.23-5.254V3c-8.923 0-13.308 4.47-13.308 13.49V27H30z"/></svg>') 50% 50% no-repeat;
  display: block;
  float: right;
  height: 20px;
  position: relative;
  // right: -30px;
  transform: rotateZ(180deg);
  width: 20px;

  @include notebook {
    height: 30px;
    right: -45px;
    width: 30px;
  }
}
