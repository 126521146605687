
.card-grid {
  padding: var(--component-margin) 0;
  position: relative;

  .card-grid__grid {
    @include grid-container;
  }

  .card-grid__wrapper {
    grid-column: var(--layout-content-image);
  }

  .card-grid__content {
    @include grid-container;
  }

  .card-grid__title {
    @include typography(heading-2);

    grid-column: 2 / 16;
    margin-bottom: 10px;

    @include notebook {
      margin-bottom: 20px;
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }

    @include notebook {
      grid-column: 4 / 16;
    }

    &.card-grid__title--indent {
      grid-column: 2 / 14;

      @include notebook {
        grid-column: 4 / 14;
      }

      @include desktop {
        grid-column: 5 / 14;
      }
    }
  }

  .card-grid__subtitle {
    @include typography(heading-4);

    margin-bottom: 15px;
  }

  .card-grid__pre-title {
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: var(--tag-letter-spacing);
    padding-right: 10px;
    text-transform: uppercase;

    @include notebook {
      display: block;
      transform: translate(-80px, 25px);
    }

    @include desktop {
      transform: translate(-80px, 29px);
    }
  }

  .card-grid__introduction {
    @include typography(introduction);

    margin-bottom: 30px;
  }

  .card-grid__text {
    font-size: 20px;
    grid-column: 2 / 14;
    line-height: 26px;
    margin-bottom: 30px;

    @include notebook {
      grid-column: 3 / 14;
    }

    &.card-grid__text--indent {
      grid-column: 2 / 14;

      @include notebook {
        grid-column: 3 / 14;
      }

      @include desktop {
        grid-column: 4 / 14;
      }
    }
  }

  .card-grid__links {
    grid-column: 2 / 14;
    margin-bottom: 50px;

    @include notebook {
      grid-column: 4 / 14;
      margin-bottom: 60px;
    }

    &.card-grid__links--indent {
      grid-column: 2 / 14;

      @include notebook {
        grid-column: 4 / 14;
      }

      @include desktop {
        grid-column: 5 / 14;
      }
    }

    .link {
      font-size: 20px;
    }
  }

  .card-grid__categories {
    margin-bottom: 10px;

    @include notebook {
      margin-bottom: 25px;
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }

    .card-grid__category {
      background-color: $color-grey;
      border-radius: 30px;
      color: $color-purple-dark;
      display: inline-block;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .07em;
      line-height: 14px;
      margin-bottom: 5px;
      margin-right: 10px;
      padding: 5px 10px;
      text-transform: uppercase;

      &:hover,
      &.selected {
        background: $color-purple-dark;
        color: $color-white;
      }
    }
  }

  .card-grid__categories--large {
    margin-bottom: 10px;

    .filter-button {
      background: none;
      border-radius: 10px;
      font-size: 0px;
      font-weight: 500;
      margin-top: 5px;
      outline: 1px solid $color-purple-dark;
      padding: 7px 20px 9px;

      @include notebook {
        margin-top: 0;
      }

      @include typography(heading-5);
    }
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .card-grid__pagination {
    margin-top: calc(var(--component-margin) * 2);
    text-align: center;
  }
}

.filter-button {
  cursor: pointer;
}

.card-grid--compact {
  .card-grid__wrapper {
    grid-column: var(--layout-content-large);
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.card-grid--two-columns {
  .card-grid__wrapper {
    grid-column: var(--layout-content-image);
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .card-grid__links .link {
    @include typography(introduction, true, true);
  }

  .card-grid__title {
    grid-column: 2 / 16;
    line-height: 36px;

    @include notebook {
      grid-column: 3 / 16;
    }
  }

  .card-grid__text {
    margin-top: 0;
  }

  .card-grid__links {
    margin-top: 10px;
  }
}

.card-grid--three-columns {
  .card-grid__wrapper {
    grid-column: var(--layout-content-image);
  }

  .card-grid__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .card-grid__title {
    grid-column: 2 / 16;
    line-height: 36px;

    @include notebook {
      grid-column: 3 / 16;
    }

    @include desktop {
      grid-column: 4 / 16;
    }
  }

  .card-grid__text {
    margin-top: 0;

    @include typography(paragraph, true, true, true);
  }

  .card-grid__links {
    margin-top: 10px;

    .link {
      @include typography(link);
    }
  }
}

.section-wrapper--background-white {
  .card-grid .card-grid__categories .dropdown-menu,
  .card-grid .card-grid__categories .card-grid__category {
    background: $color-grey;

    &:hover,
    &.selected {
      background: $color-purple-dark;
      color: $color-white;
    }
  }
}

.card-grid--full-image {
  .card-grid__wrapper {
    grid-column: var(--layout-content-large);
  }

  .card-grid__title {
    @include typography(heading-2);
  }

  .card {
    background: inherit;
    border-radius: 0;
    display: block;
    position: inherit;

    .card__image {
      border-radius: 10px;
      display: block;
      overflow: hidden;
      position: relative;

      &::after {
        display: none;
      }
    }

    .card__content {
      color: $color-purple-dark;
      display: block;
      padding-left: 0;
      padding-top: 15px;
      position: relative;
    }
  }
}

.section-wrapper--background-grey {
  .card-grid__categories .dropdown-menu {
    background-color: $color-white;
  }
}
