.content-carousel {
  overflow-x: hidden;
  padding: var(--component-margin) 0;
  position: relative;

  .content-carousel__grid {
    @include grid-container;
  }

  .content-carousel__wrapper {
    grid-column: 2 / 14;

    @include notebook {
      grid-column: 3 / 13;
    }

    @include desktop {
      grid-column: 4 / 15;
    }
  }

  .content-carousel__title {
    @include typography(heading-3, false, true);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .content-carousel__navigation {
    margin-left: -10px;
    margin-top: -14px;

    @include notebook {
      margin-left: calc(var(--single-grid-column-with-gutter) - 10px);
    }
  }

  .content-carousel__content {
    position: relative;
    width: 100%;
  }

  .swiper-container {
    clip-path: inset(-100vw -100vw -100vw 0); // trick to have overflow only on the right side
    overflow: visible;
    position: relative;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
  }
}

.content-carousel--simple {
  .swiper-wrapper {
    @include notebook {
      flex-direction: column;
    }
  }
}
