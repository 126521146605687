.article-banner {
  color: $color-purple-dark;
  padding-bottom: var(--component-margin);
  padding-top: var(--component-margin);
  position: relative;

  .article-banner__grid {
    @include grid-container;
  }

  .article-banner__image-container {
    border-top-right-radius: var(--round-corner-size);
    grid-column: 1 / -2;
    overflow: hidden;
    padding-top: 75%;
    position: relative;
    transform: translateZ(0); // fixes border radius with overflow hidden not working in safari

    @include notebook {
      border-top-right-radius: 0;
      grid-column: 1 / span 4;
      width: calc(100% + var(--grid-gutter));
    }
  }

  .article-banner__text-container {
    background: $color-grey;
    border-bottom-right-radius: var(--round-corner-size);
    grid-column: 1 / -2;
    padding-bottom: calc(var(--component-margin) * 2);
    padding-top: calc(var(--component-margin) * 2);
    width: 100%;

    @include notebook {
      border-top-right-radius: var(--round-corner-size);
      grid-column: 5 / span 9;
    }

    @include desktop {
      grid-column: 5 / span 10;
    }
  }

  .article-banner__text {
    grid-column: 2 / span 12;
    margin-left: var(--single-grid-column-with-gutter);
    margin-right: var(--single-grid-column-with-gutter);
  }

  .article-banner__title {
    @include typography(heading-2);
  }

  .article-banner__introduction {
    @include typography(introduction, true);
  }

  .article-banner__link {
    margin-top: 1.5rem;

    @include notebook {
      margin-left: -40px;
    }
  }

  &.article-banner--white {
    .article-banner__text-container {
      background: $color-white;
    }
  }
}
