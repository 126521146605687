.article-nav-back-link {
  padding-top: calc(1.5 * var(--component-margin));

  .article-nav-back-link__grid {
    @include grid-container;
  }

  .article-nav-back-link__wrapper {
    grid-column: var(--layout-content-image);
  }

  .link {
    @include typography(caption);

    color: $color-purple-light;
  }

  .link__icon {
    height: var(--link-line-height);
    width: var(--link-line-height);
  }
}

.article-nav-back-link--image {
  position: absolute;
  width: 100%;
  z-index: 99;

  .link {
    background: rgba(255, 255, 255, .6);
    border-radius: 5px;
    padding: 15px;
  }
}

.article-nav-back-link--fixed {
  position: fixed;
  width: 100%;
  z-index: 99;

  .link {
    background: rgba(255, 255, 255, .9);
    border-radius: 5px;
    padding: 15px;
  }
}

.article-nav-back-link--compact {
  @include mobile-only {
    height: 135px;
    overflow: hidden;
    z-index: 10001;

    .link {
      background: rgba(255, 255, 255, .9);
      border-radius: 5px;
      padding: 15px;

      &__span {
        display: none;
      }

      .link__icon--before {
        margin-right: 0;
      }
    }
  }
}
