
* {
  border-width: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  margin-top: 0;
  //font-size: inherit;
  //line-height: inherit;

  ::before,
  ::after {
    box-sizing: border-box;
    //text-decoration: inherit;
    //vertical-align: inherit;
  }
}

:root {
  //overflow-y: auto; // All browsers without overlaying scrollbars - NOTE: Prevents navigation bar from collapsing on iOS
}

html {
  background-color: #fff;
  color: #000;
  //scroll-behavior: smooth; // NOTE: UIT! Want gruwlijk irritant als je naar een cached pagina terug tabbed, dan scrollt ie naar z'n oude plek
}

html:has(.navigation-bar__mobile.active) .vocalcom-nav {
  z-index: 999 !important;
}

[hidden] {
  display: none !important;
}

input[type='search'] {
  -webkit-appearance: textfield; // Safari 8+
  appearance: textfield;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none; // Safari 8+
  }
}

a {
  text-decoration: none;
  text-decoration-skip-ink: auto; // Pretty descenders! 😍

  p &,
  li &,
  label & {
    color: inherit;
    text-decoration: underline;
  }
}

button {
  background: transparent;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

svg {
  fill: transparent;
  height: 100%;
  stroke: currentColor;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

// specify inheritance of form elements
button,
input,
select,
textarea {
  border-radius: 0; // iOS
  color: inherit;
  font: inherit;
  min-height: 1em;
}

button,
select {
  &:not(:disabled) {
    cursor: pointer;
  }
}

select {
  -moz-appearance: none; // Firefox 36+
  -webkit-appearance: none; // Chrome 41+
  appearance: none;
}

option {
  background: #fff;
  color: #000;
}

textarea {
  max-height: 50vh;
  resize: vertical;
}

// specify text selection background color and omit drop shadow
::selection {
  background-color: #ede4e5; // required when declaring ::selection
  color: #000;
  text-shadow: none;
}

// hide content from screens but not screenreaders
@media screen {
  [hidden~='screen'] {
    display: inherit;
  }

  [hidden~='screen']:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

// Remove spinner arrows
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-inner-spin-button {
    display: none;
  }
}

figure,
picture {
  margin-left: 0;
  margin-right: 0;

  img,
  iframe,
  video,
  object {
    height: auto;
    width: 100%;
  }
}

fieldset,
legend {
  margin: 0;
  padding: 0;
}

@keyframes focusanimation {
  0% {
    background-color: rgba(#39a935, .5);
  }

  100% {
    background-color: rgba(#39a935, .001);
  }
}

button,
summary,
a,
figcaption[tabindex='0'] {
  &:focus {
    //animation: 1s focusanimation ease-out 1;
    //outline: 2px solid #000;
    //outline-offset: 2px;
    outline: 1px solid #000;
  }
}

@media (hover: hover) {
  html:not(.key-pressed) {
    button,
    summary,
    a,
    [tabindex='0'],
    select {
      &:focus {
        outline: none;
      }
    }
  }
}

summary::-webkit-details-marker {
  display: none; // Chrome
}

summary {
  list-style: none; // Firefox
}

em,
i {
  font-style: inherit;
}

strong,
b {
  font-weight: inherit;
}

p,
ul,
ol {
  em,
  i {
    font-style: normal;
  }

  strong,
  b {
    font-weight: bold;
  }
}

// WCAG max text length
h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  //max-width: 80ch;
}
