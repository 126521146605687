.dossier-text-picture {
  display: grid;
  padding: var(--component-margin) 0;
  position: relative;

  .dossier-text-picture__grid {
    @include mobile-only {
      @include grid-container;

      row-gap: calc(var(--component-margin) * 2);
    }

    @include notebook {
      column-gap: var(--grid-gutter);
      display: grid;
      grid-template-columns: 1fr 1fr;
      min-height: 100vh;
    }
  }

  .dossier-text-picture__text {
    @include mobile-only {
      grid-column: var(--layout-content-large);
    }

    @include notebook {
      margin: 100vh auto;
      padding: 0 calc(var(--single-grid-column-with-gutter) * 2) 0 var(--single-grid-column-with-gutter);
    }
  }

  .dossier-text-picture__title {
    @include typography(heading-2, false, true);
  }

  .dossier-text-picture__link {
    margin-top: 25px;
  }

  .dossier-text-picture__picture {
    @include mobile-only {
      grid-column: var(--layout-full);
    }

    @include notebook {
      height: 100vh;
      order: -1;
      position: sticky;
      top: 0;

      .picture {
        height: 100%;
        width: 100%;
      }

      .picture__image {
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }
}

.dossier-text-picture__no-picture {
  display: flex;

  @include notebook {
    min-height: auto;
    padding: calc(var(--component-margin) * 5) 0;
  }

  .article-content {
    margin: auto;
    padding: 0;
  }

  .article-content__richtext {
    @include notebook {
      grid-column: column 5 / span 6;
    }

    @include desktop {
      grid-column-start: column 6;
    }
  }

  .article-content__links {
    @include notebook {
      grid-column: column 4 / span 7;
    }

    @include notebook {
      grid-column-start: column 5;
    }
  }
}
