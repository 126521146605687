
.form-fieldset {
  padding-bottom: var(--component-margin);
  position: relative;

  // start border/<legend> nonsense
  &:not(:first-child) {
    .form-fieldset__legend {
      border-top: var(--component-margin) solid transparent; // due to border/<legend> nonsense
    }
  }
  // end border/<legend> nonsense

  > p {
    @include typography(caption, true, true);
  }

  .form-fieldset__legend {
    @include typography(heading-4, false, true);

    display: block;
    width: 100%;

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }
}
