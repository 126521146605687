.ticker-tape {
  background-color: #371548;
  color: $color-white;
  height: 44px;
  left: 0;
  padding: 7px 0;
  top: 60px;
  width: 100%;
  z-index: 99;

  .ticker-tape__wrapper {
    @include grid-container;

    &::after {
      content: '';
      height: 44px;
      position: absolute;
      right: 0;
      top: 0;

      @include mobile-only {
        border-image: linear-gradient(to left, #371548, rgba(55, 21, 72, 0)) 1 100%;
        border-right: 15px solid;
      }

      @include notebook {
        border-image: linear-gradient(to left, #371548, rgba(55, 21, 72, 0)) 1 100%;
        border-right: 45px solid;
      }
    }
  }

  .ticker-tape__content {
    font-weight: 500;
    grid-column: 1 / 13;
    position: relative;

    @include notebook {
      grid-column: 1 / 13;
    }

    @include desktop {
      grid-column: 1 / 15;
      right: -70px;
    }
  }

  .ticker-tape__text {
    position: relative;

    .ticker-tape__text-dot {
      position: relative;

      &::before {
        content: '• ';
        font-size: 22px;
        left: -25px;
        margin-left: 10px;
        margin-right: 5px;
        position: absolute;
        top: -1px;
      }
    }

    svg {
      path {
        fill: currentColor;
        stroke: none;
      }

      height: 20px;
      width: 20px;
    }
  }

  // REMOVE WHEN PO ARE OKE
  .ticker-tape__text-icon {
    margin-left: 3px;
    margin-right: 8px;
    position: relative;
    top: 4px;
  }

  .ticker-tape__text-link {
    color: $color-white;
    font-weight: 400;
    margin-right: 15px;
    padding-left: 15px;
    padding-right: 35px;
    text-decoration: underline;
  }

  .ticker-tape__right-column {
    grid-column: 15 / 17;

    @include notebook {
      grid-column: 14 / 17;
    }

    @include desktop {
      grid-column: 15 / 17;
    }
  }

  .ticker-tape__link {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    color: $color-white;
    float: right;
    font-weight: bold;
    position: relative;
    right: 40px;
    top: 1px;
    transition: background-size .2s linear;
    vertical-align: baseline;

    @include notebook {
      right: 60px;
    }

    &:hover {
      background-size: 100% 1px;
    }
  }

  .ticker-tape__icon {
    color: $color-white;
    cursor: pointer;
    float: right;
    position: absolute;
    right: 15px;
    top: 12px;
    width: 20px;
  }

  &.ticker-tape--full {
    .ticker-tape__content {
      grid-column: 1 / 15;
    }

    .ticker-tape__right-column {
      display: none;
    }
  }
}

.ticker-tape--closed {
  display: none;
}
