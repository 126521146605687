.simple-container {
  position: relative;

  &.simple-container--standalone {
    padding: var(--component-margin) 0;
  }

  .simple-container__grid {
    @include grid-container;
  }

  .simple-container__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.campaign-header + .simple-container,
.article-header + .simple-container {
  padding-bottom: 0;
  padding-top: 0;
}

.simple-container--layout-content {
  .simple-container__wrapper {
    grid-column: var(--layout-content);
  }
}

.simple-container--layout-content-large {
  .simple-container__wrapper {
    grid-column: var(--layout-content-large);
  }
}

.simple-container--layout-content-extralarge {
  .simple-container__wrapper {
    grid-column: var(--layout-content-extralarge);
  }
}

.simple-container--alignment-left {
  .simple-container__wrapper {
    align-items: flex-start;
  }
}

.simple-container--alignment-right {
  .simple-container__wrapper {
    align-items: flex-end;
  }
}
