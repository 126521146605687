.content-header2 {
  .content-header2__title {
    @include typography(heading-1);
  }

  .content-header2__subtitle {
    margin-top: 10px;

    @include typography(introduction);
  }
}
