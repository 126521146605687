@mixin simple-header--top-spacing {
  padding-top: 95px;

  @include mobile-only {
    padding-top: 65px;
  }
}

.simple-header {
  @include simple-header--top-spacing;

  .simple-header__grid {
    background: $color-white;
    row-gap: 25px;

    @include grid-container;

    @include notebook {
      row-gap: 40px;
    }
  }

  .simple-header__title {
    font-family: var(--heading-1-font-stack);
    font-size: var(--heading-1-font-size);
    font-style: var(--heading-1-font-style);
    font-variant: var(--heading-1-font-variant);
    font-weight: var(--heading-1-font-weight);
    grid-column: var(--layout-content-large);
    letter-spacing: var(--heading-1-letter-spacing);
    line-height: var(--heading-1-line-height);
    padding-top: 0;
    text-transform: var(--heading-1-text-transform);
    word-spacing: var(--heading-1-word-spacing);

    @include mobile-only {
      padding-bottom: 20px;
    }
  }

  .simple-header__introduction {
    grid-column: var(--layout-content-large);
  }
}

.simple-header--top-spacing {
  padding-top: 95px;

  @include mobile-only {
    padding-top: 65px;
  }
}
