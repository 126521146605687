
.dropdown-field {
  --dropdown-field-color-focus-hover: #{$color-purple-dark};

  position: relative;

  .dropdown-field__label {
    @include typography(heading-5, false, true, false);

    cursor: pointer;
    display: block;
    margin: 8px 0;
    user-select: none;
  }

  .dropdown-field__wrapper {
    display: block;
    max-width: 100%;
    position: relative;
  }

  .dropdown-field__input {
    @include typography(field-label);

    background-color: transparent;
    border: 1px solid $color-purple-lighter;
    border-radius: 2px;
    display: inline-block;
    flex: 1 1 100%;
    height: 60px;
    line-height: 30px;
    margin: 0;
    min-height: 60px;
    min-width: 64px;
    overflow: hidden;
    padding: 14px 48px 14px 15px;
    position: relative;
    text-overflow: ellipsis;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:hover:not(:disabled) {
      border-color: var(--dropdown-field-color-focus-hover);
    }

    &:focus {
      border-color: var(--dropdown-field-color-focus-hover);
      outline: none;
    }

    + .dropdown-field__button {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: inherit;
      }
    }
  }

  &.dropdown-field--has-value {
    .dropdown-field__input {
      font-weight: 600;
    }
  }

  .dropdown-field__icon {
    border: 16px solid transparent;
    border-left-width: 16px;
    border-right-width: 16px;
    fill: currentColor;
    font-size: 0;
    height: 60px;
    line-height: 60px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 54px;
    z-index: 1;
  }

  .dropdown-field__helptext {
    @include typography(field-helptext);

    margin-top: 2px;
  }

  &.dropdown-field--disabled {
    .dropdown-field__input,
    .dropdown-field__input:disabled {
      opacity: .4;
      pointer-events: none;
    }

    .dropdown-field__icon {
      opacity: .4;
    }

    .dropdown-field__custom {
      cursor: default;
    }
  }

  &.dropdown-field--error {
    .dropdown-field__error-icon {
      @include field-error-icon;
    }

    .dropdown-field__error {
      @include typography(field-helptext);

      margin-top: 2px;
    }

    .dropdown-field__input:not(:focus):not(:hover) {
      border-color: $color-error;
    }
  }
}

.dropdown-field--color-white {
  --dropdown-field-color-focus-hover: #{$color-white};
}

.dropdown-field--cluster {
  .dropdown-field__clusters {
    background: rgba($color-purple-lightest, .5);
    border-radius: 2px;
    color: $color-purple-light;
    margin-top: 5px;
    padding: 15px;
  }

  .dropdown-field__clusters-item {
    &.is-collapsed {
      .dropdown-field__clusters-list li:nth-child(n+4) {
        // display max 3 items
        display: none;
      }
    }
  }

  .dropdown-field__clusters-title {
    @include typography(heading-6);

    margin-bottom: 10px;
  }

  .dropdown-field__clusters-list {
    @include typography(caption);

    display: grid;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .dropdown-field__clusters-btn-more {
    @include typography(caption);
    @include style-fancy-link-underline;

    margin-top: 10px;
  }
}
