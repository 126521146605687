
// Layouts

:root {
  --layout-aside: column 1 / span 14;
  --layout-content-aside: column 1 / span 14;
  --layout-center: column 2 / span 12;
  --layout-content-image: column 2 / span 12;
  --layout-photo-carousel: column 2 / span 12;
  --layout-content-large: column 2 / span 12;
  --layout-content-extralarge: column 2 / span 12;
  --layout-content: column 2 / span 12;
  --layout-full: column 1 / span 14;
  --layout-grid-inline: column 2 / span 12;

  @include tablet {
    --layout-aside: column 2 / span 3;
    --layout-content-aside: column 5 / span 9;
  }

  @include notebook {
    --layout-aside: column 2 / span 2;
    --layout-content-aside: column 4 / span 10;
    --layout-center: column 2 / span 12;
    --layout-content-image: column 2 / span 12;
    --layout-photo-carousel: column 2 / span 11;
    --layout-content-large: column 3 / span 10;
    --layout-content-extralarge: column 2 / span 12;
    --layout-content: column 4 / span 8;
    --layout-full: column 1 / span 14;
    --layout-grid-inline: column 2 / span 12;
  }

  @include desktop {
    --layout-aside: column 2 / span 2;
    --layout-content-aside: column 4 / span 12;
    --layout-center: column 5 / span 8;
    --layout-content-image: column 3 / span 12;
    --layout-photo-carousel: column 3 / span 11;
    --layout-content-large: column 4 / span 10;
    --layout-content-extralarge: column 3 / span 12;
    --layout-content: column 5 / span 8;
    --layout-full: column 1 / span 16;
    --layout-grid-inline: column 2 / span 12;
  }
}
