.dossier-header {
  --scroll-length: 100px;
  --card-top: 140px;
  --card-bottom: 140px;
  --card-left: calc(2 * var(--single-grid-column-with-gutter));
  --card-right: calc(2 * var(--single-grid-column-with-gutter));
  --card-padding: var(--single-grid-column-with-gutter);

  background-color: $color-purple;
  position: relative;

  &.has-scrolled {
    .dossier-header__scroll-indicator {
      transform: translateY(100%);
    }

    .dossier-header__nav-item--previous {
      transform: translateY(-50%) translateX(-100%);
    }

    .dossier-header__nav-item--next {
      transform: translateY(-50%) translateX(100%);
    }
  }

  &.is-open-nav {
    .dossier-header__scroll-indicator {
      transform: translateY(100%);
    }
  }

  .dossier-header__wrapper {
    position: relative;

    @include mobile-only {
      padding: var(--component-margin) var(--single-grid-column-with-gutter) calc(var(--component-margin) * 2);
    }

    @include notebook {
      min-height: calc(100vh + var(--card-top) + var(--scroll-length));
    }
  }

  .dossier-header__background {
    @include mobile-only {
      display: none;
    }

    @include notebook {
      background-color: $color-grey;
      border-radius: 30px;
      bottom: var(--card-bottom);
      left: var(--card-left);
      position: fixed;
      right: var(--card-right);
      top: calc(var(--card-top) + var(--menu-height) - 60px);
      transition: transform $easing-change-slower;
    }
  }

  .dosser-header__card {
    @include mobile-only {
      background-color: $color-grey;
      border-radius: 30px;
      padding: var(--single-grid-column-with-gutter);
    }

    @include notebook {
      left: var(--card-left);
      padding: var(--card-padding);
      position: absolute;
      right: var(--card-right);
      top: calc(var(--card-top) - var(--menu-height));
      transition: transform $easing-change-slower;
    }
  }

  .dosser-header__title {
    @include typography(heading-1, false, true);

    @include notebook {
      font-size: #{get-fluid-value(50px, 75px)};
    }

    @include desktop {
      font-size: 75px;
    }
  }

  .dosser-header__intro {
    @include typography(introduction);
  }

  .dossier-header__nav-item {
    @include typography(heading-1);

    align-items: center;
    color: $color-white;
    column-gap: 75px;
    display: grid;
    padding: 100px 0;
    position: absolute;
    top: 50%;
    transition: transform $easing-change-slower;
    z-index: 2;

    @include notebook {
      padding-left: 25px;
      padding-right: 25px;
      position: fixed;
    }
  }

  .dossier-header__nav-icon {
    @include mobile-only {
      .dossier-header__nav-icon-large {
        display: none;
      }
    }

    @include notebook {
      .dossier-header__nav-icon-small {
        display: none;
      }
    }
  }

  .dossier-header__nav-item--previous {
    grid-template-columns: 380px 25px;
    left: 0;
    transform: translateY(-50%) translateX(-455px);
  }

  .dossier-header__nav-item--next {
    grid-template-columns: 25px 380px;
    right: 0;
    transform: translateY(-50%) translateX(455px);
  }

  &.dossier-header--nav-open-next {
    .dossier-header__background,
    .dosser-header__card {
      transform: translateX(-480px);
    }

    .dossier-header__nav-item--previous {
      transform: translateY(-50%) translateX(-480px);
    }

    .dossier-header__nav-item--next {
      transform: translateY(-50%) translateX(0);
    }
  }

  &.dossier-header--nav-open-previous {
    .dossier-header__background,
    .dosser-header__card {
      transform: translateX(480px);
    }

    .dossier-header__nav-item--previous {
      transform: translateY(-50%) translateX(0);
    }

    .dossier-header__nav-item--next {
      transform: translateY(-50%) translateX(480px);
    }
  }

  .dossier-header__scroll-indicator {
    @include mobile-only {
      display: none;
    }

    align-items: center;
    bottom: 0;
    color: $color-white;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    position: fixed;
    transition: transform $easing-change-slower;
    width: 100%;
  }

  .dossier-header__scroll-indicator-icon {
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }
}
