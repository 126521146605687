.list {
  display: grid;
  list-style: none;
  padding: 0;
  row-gap: 10px;
}

.list__item {
  align-items: baseline;
  display: grid;
  grid-template-columns: var(--paragraph-line-height) auto;

  svg {
    height: var(--paragraph-line-height);
    margin-left: -5px;
    width: var(--paragraph-line-height);
  }
}
