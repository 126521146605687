.content-with-sidebar {
  padding-bottom: var(--component-margin);
  padding-top: calc(var(--component-margin) * 1);

  @include notebook {
    padding-top: calc(var(--component-margin) * 1.5);
  }

  .content-with-sidebar__grid {
    @include grid-container;
  }

  .content-with-sidebar__wrapper {
    grid-column: var(--layout-content-large);
  }

  .button {
    margin-bottom: var(--component-margin);
  }

  .content-with-sidebar__column {
    grid-column: 1 / 8;
    margin-right: 20px;

    > *:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  .content-with-sidebar__sidebar {
    grid-column: 8 / 11;
    margin-bottom: 17px;
    margin-top: var(--component-margin);
    position: relative;

    @include notebook {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  &.compact {
    .content-with-sidebar__title {
      font-size: var(--heading-1-font-size);
    }

    .content-with-sidebar__column {
      grid-column: 1 / 6;

      > *:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .content-with-sidebar__sidebar {
      grid-column: 8 / 11;
    }
  }

  .content-with-sidebar__row {
    @include notebook {
      @include grid-container($columns: 'repeat(10, [column] minmax(0, 1fr))');
    }
  }

  .content-with-sidebar__row--full {
    .content-with-sidebar__column {
      grid-column: 1 / 15;
    }
  }
}

.content-with-sidebar--medium {
  .content-with-sidebar__column {
    grid-column: 1 / 7;
  }

  .content-with-sidebar__sidebar {
    grid-column: 7 / 11;
  }
}

.content-with-sidebar--simple {
  padding-bottom: var(--component-margin);
  padding-top: 50px;

  @include notebook {
    padding-top: 50px;
  }
}
