.form-membership {
  --form-membership-columns: 7.55fr 2.45fr;

  background-color: $color-white;
  border-bottom-left-radius: var(--round-corner-size);
  overflow-x: hidden;
  padding: 60px 0 var(--round-corner-size);

  @include notebook {
    min-height: calc(100vh - var(--menu-height));
    padding-top: 100px;
  }

  &[data-current-step='1'] {
    @include mobile-only {
      padding-top: 30px;
    }
  }

  &[data-current-step='2'] {
    @include mobile-only {
      .form-membership__title span:first-child {
        display: none;
      }
    }
  }

  &[data-current-step='3'] {
    @include mobile-only {
      .form-membership__title span.form-membership__title-inactive {
        display: none;
      }
    }

    @include notebook {
      .form-membership__form-btn-submit {
        text-align: right;
        width: 32.5%;

        .button {
          width: 100%;
        }
      }
    }

    .form-membership__form-btn-back {
      width: 100%;
    }
  }

  .form-membership__grid {
    @include grid-container;
  }

  .form-membership__grid,
  .form-membership__wrapper,
  .form,
  .form__wrapper,
  .form-membership__form-grid {
    height: 100%;
  }

  .form__wrapper {
    display: flex;
    flex-direction: column;
  }

  .form-membership__wrapper {
    grid-column: var(--layout-content-image);
  }

  .form-membership__usp-top {
    display: none;

    @include mobile-only {
      display: block;
      margin-bottom: 30px;
    }
  }

  .form-membership__aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .form-membership__usp-aside {
    @include mobile-only {
      display: none;
    }
  }

  .form-membership__usp-bottom {
    display: none;

    @include mobile-only {
      display: block;
      margin-top: 30px;
    }
  }

  .form-membership__title {
    @include typography(heading-2);

    display: flex;
    margin-bottom: 30px;

    @include mobile-only {
      width: 9999px;
    }

    > span {
      margin-right: 20px;
    }
  }

  .form-membership__introduction {
    @include typography(introduction);

    margin-bottom: 25px;
    margin-top: -5px;
    max-width: 700px;
  }

  .form-membership__title-inactive {
    color: $color-purple-lightest;
  }

  .form-membership__form-grid {
    display: grid;
    gap: 30px var(--grid-gutter);

    @include notebook {
      grid-template-columns: var(--form-membership-columns);
      grid-template-rows: 1fr auto;
    }
  }

  .form-membership__intro {
    @include typography(introduction);
  }

  .address-field {
    margin-top: 30px;
  }

  .form-membership__calculation {
    @include notebook {
      margin-top: 50px;
    }
  }

  .form-membership__form-btns {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @include notebook {
      column-gap: var(--grid-gutter);
      grid-column: span 2;
      grid-template-columns: var(--form-membership-columns);
    }
  }

  .form-membership__form-btn-submit {
    @include notebook {
      justify-self: end;
    }
  }

  .form-membership__try-the-union {
    @include typography(caption);

    background-color: $color-purple;
    border-radius: 2px;
    color: $color-white;
    padding: 15px;

    @include notebook {
      max-width: 66.66%;
    }
  }

  .form-membership__try-the-union-title {
    @include typography(heading-6, false, true);
  }

  .form-membership__try-the-union-btn {
    margin-top: 20px;
  }
}

.form-membership--grey {
  background-color: $color-grey;
}

.form-membership--purple {
  background-color: $color-purple;
  color: $color-white;

  .form-membership__title-inactive {
    color: rgba($color-purple-light2, .4);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px $color-purple inset;
    -webkit-text-fill-color: $color-white;
  }
}
