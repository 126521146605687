@mixin link--menu-bar {
  .link__menu-wrapper {
    align-items: center;
    color: $color-white;
    display: flex;
    height: 60px;
  }

  &.link--with-category {
    .link__link {
      margin-top: 0;
    }
  }
}

.link {
  @include typography(link);

  color: inherit;
  display: inline;
  position: relative;
  text-decoration: none;
  transition: color $easing-change;
  vertical-align: bottom;

  .link__span {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    transition: background-size .2s linear;
    vertical-align: baseline;
    white-space: nowrap;

    @include tablet {
      white-space: inherit;
    }
  }

  .link__icon {
    display: inline-block;
    height: calc(var(--link-line-height) * .85);
    position: relative;
    vertical-align: text-bottom;
    width: calc(var(--link-line-height) * .85);

    > svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .link__icon--before {
    margin-right: 6px;
    top: -1px;
  }

  .link__icon--after {
    margin-left: 6px;
  }

  &:hover,
  &:focus {
    .link__span {
      background-size: 100% 1px;
    }
  }
}

.link-caption {
  @include typography(caption);

  color: $color-purple-light;
}

.link--menu {
  .link__menu-wrapper {
    @include typography(link-menu);

    padding: 5px 13px;
  }
}

.link--cluster {
  @include notebook {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    margin-left: 10px;
    min-height: 38px;
    width: calc(100% - 10px);

    .link__icon--after {
      display: none;
    }

    .link__menu-wrapper {
      color: $color-white;
    }
  }
}

.link--big {
  @include typography(link-big);
}

.link--small {
  @include typography(link-small);
}

.link--menu-bar {
  @include link--menu-bar;
}

.link--on-purple {
  .link__menu-wrapper {
    color: $color-white;
  }
}

.link--reverse-underline {
  &:hover,
  &:focus {
    .link__span {
      background-size: 0 1px;
    }
  }

  .link__span {
    background-position: 100% 100%;
    background-size: 100% 1px;
  }
}

.link--with-category {
  @include tablet {
    left: -15px;
  }

  @include mobile-only {
    .link__category {
      display: none;
    }
  }

  @include notebook {
    left: -15px;
    padding-bottom: 12px;

    .link__menu-wrapper {
      align-items: flex-start;
      background: $color-white;
      border-radius: 0 0 11px;
      color: $color-purple-dark;
      display: flex;
      flex-direction: column;
      height: 48px;
      padding-top: 8px;
    }

    .link__category {
      color: $color-purple-light2;

      &.link__category--vakmensen {
        color: $color-vakmensen;
        line-height: 1.2;
      }
    }
  }
}

.link--tabs {
  display: inline-block;
  padding-left: 20px;
  width: 100%;

  .link__icon {
    height: 14px;
    top: -3px;
  }

  .link__icon--after {
    display: none;
  }

  .link__span {
    font-size: 14px;
  }

  &.link--active,
  &:active {
    .link__icon--before {
      display: none;
    }

    .link__icon--after {
      display: block;
      float: left;
      left: -6px;
      margin-right: 4px;
      top: 6px;
    }

    .link__span {
      background-size: 0 1px;
      font-weight: 500;
    }

    &::before {
      background-color: $color-purple-dark;
      bottom: -16px;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    @include tablet {
      &::before {
        background-color: $color-purple-dark;
        bottom: 0;
        content: '';
        height: 100%;
        left: -58px;
        position: absolute;
        width: 3px;
      }
    }
  }
}
