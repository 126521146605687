.copy-link {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @include tablet {
    flex-wrap: nowrap;
  }

  &__text-field {
    cursor: pointer;
    flex-basis: 100%;

    @include tablet {
      flex-basis: auto;
      flex-grow: 1;
    }

    .text-field__helptext {
      display: none;
      margin-top: 12px;
    }

    .text-field__input {
      font-weight: normal;
      padding-top: 6px;
    }
  }

  &__button {
    display: block;
    margin-top: 12px;
    max-height: 60px;
    width: auto;

    @include tablet {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}
