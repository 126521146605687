.gdpr-checkbox {
  .richtext {
    margin-bottom: 8px;
  }
}

.gdpr-checkbox--color-white {
  .richtext a,
  .richtext a:link,
  .richtext a:active,
  .richtext a:visited {
    color: inherit;
  }
}
