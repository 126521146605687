
.hero-header {
  background-color: transparent;
  color: transparent;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding-bottom: var(--component-margin);
  padding-top: calc(var(--component-margin) * 1.5);
  position: relative;

  .hero-header__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
  }

  .hero-header__grid {
    @include grid-container;

    align-items: center;
    flex: 0 1 100%;
  }

  .hero-header__title {
    @include typography(heading-1);

    max-width: 11em;
  }

  .hero-header__pre-title {
    @include typography(heading-4);

    max-width: 28em;
  }

  .hero-header__post-title {
    @include typography(heading-4);

    margin-top: calc(var(--paragraph-margin-top) / 2);
    max-width: 32em;
  }

  .hero-header__header {
    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .hero-header__introduction {
    max-width: 32em;
  }

  .hero-header__buttons {
    font-size: 0;

    .button {
      margin: 20px 20px 0 0;
    }
  }

  .hero-header__scroll-down {
    grid-column: 6;
  }

  @include notebook {
    .hero-header__grid {
      align-items: flex-end;
    }

    .hero-header__scroll-down {
      grid-column: 12;
    }
  }
}
