.cao-header {
  padding-bottom: var(--component-margin);
  padding-top: 45px;

  @include notebook {
    padding-top: 50px;
  }

  .cao-header__grid {
    @include grid-container;
  }

  .cao-header__wrapper {
    grid-column: var(--layout-content-large);
  }

  .cao-header__row {
    .button {
      max-width: 100%;
      width: 100%;

      @include tablet {
        width: auto;
      }
    }

    @include notebook {
      @include grid-container;

      .cao-header__date {
        grid-column: 1/11;
      }

      .cao-header__btn {
        grid-column: 11/15;
        justify-self: end;
        width: 100%;

        .button {
          width: 100%;
        }
      }

      .cao-header__in-progress {
        grid-column: 1/11;
        grid-row: 2;
      }
    }

    .cao-header__column {
      grid-column: 1/10;
    }

    .cao-header__quicklinks {
      grid-column: 11/15;
      margin-top: 30px;

      .article-quicklinks__title {
        @include typography(heading-5);

        border-bottom: 1px solid $color-purple-light;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .article-quicklinks__links {
        font-size: 16px;
        list-style: none;
        padding: 0;

        li {
          margin-top: 15px;

          .link {
            font-size: 16px;
          }
        }
      }
    }
  }

  .cao-header__row--full {
    .cao-header__column {
      grid-column: 1/15;
    }

    .cao-header__quicklinks {
      display: none;
    }
  }

  .cao-header__title {
    @include typography(heading-1);
  }

  .cao-header__date {
    @include typography(introduction);

    padding: 10px 0;
  }

  .cao-header__btn {
    margin-top: 20px;

    @include notebook {
      margin-top: 0;
    }
  }

  .cao-header__dropdown {
    margin-top: 10px;

    @include notebook {
      grid-column: 11/15;
      justify-self: end;
      width: 100%;
    }
  }

  .cao-header__in-progress {
    @include typography(tag);

    color: $color-purple-light;
    margin-bottom: 20px;

    @include notebook {
      margin-bottom: 0;
    }

    &::before {
      background-color: #28d739;
      border-radius: 50%;
      content: '';
      display: inline-block;
      filter: blur(1px);
      height: 9px;
      margin-right: 8px;
      position: relative;
      top: 1px;
      width: 9px;
    }
  }

  .cao-header__authors {
    display: grid;
    gap: calc(var(--grid-gutter) * 2) var(--grid-gutter);
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 50px;
  }

  .cao-header__introduction {
    margin-top: 30px;
  }

  .cao-header__link {
    display: block;
    padding: 10px;
    position: relative;
    top: 10px;

    @include notebook {
      display: inline;
      left: 20px;
      padding: unset;
      top: -12px;
    }
  }

  .modal-cao-downloads__outro {
    margin-bottom: var(--component-margin);
  }
}

.cao-header--simple {
  padding-bottom: var(--component-margin);
  padding-top: 50px;

  @include notebook {
    padding-top: 50px;
  }

  .cao-header__row .cao-header__quicklinks {
    margin-top: 30px;
  }
}

.cao-header--archived {
  padding-top: 100px;

  .cao-header__row .cao-header__column {
    grid-column: 1/14;
  }
}
