.dossier-statement-milestone {
  align-items: center;
  display: flex;
  padding: var(--component-margin) 0;
  position: relative;

  @include mobile-only {
    padding-top: calc(var(--component-margin) * 2);
  }

  @include notebook {
    min-height: 100vh;
  }

  .dossier-statement-milestone__grid {
    @include grid-container;
  }

  .dossier-statement-milestone__wrapper {
    column-gap: var(--single-grid-column-with-gutter);
    display: grid;
    grid-column: var(--layout-content-large);
    row-gap: calc(var(--component-margin) * 2);

    @include notebook {
      grid-template-columns: 1fr 1fr;
    }
  }

  .dossier-statement-milestone__statement {
    @include typography(heading-1);

    margin: 0;
  }

  .dossier-statement-milestone__milestone-title {
    @include typography(heading-3, false, true);
  }

  .dossier-statement-milestone__milestone-list {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 10px;
  }

  .dossier-statement-milestone__milestone-list-item {
    align-items: baseline;
    display: grid;
    grid-template-columns: var(--paragraph-line-height) auto;

    svg {
      height: var(--paragraph-line-height);
      margin-left: -5px;
      width: var(--paragraph-line-height);
    }
  }
}
