.choose-sector {
  .article-dropdown-menu {
    .article-dropdown-menu__grid {
      display: block;
      grid-template-columns: none;
    }

    .article-dropdown-menu__wrapper {
      grid-column: none;
      justify-items: start;
    }

    .article-dropdown-menu__dropdown {
      max-width: 260px;
    }

    button strong {
      color: $color-purple-dark;
      font-weight: bold;
    }
  }

  .dropdown-menu .dropdown-menu__btn:focus,
  .dropdown-menu .dropdown-menu__btn:hover,
  .dropdown-menu .dropdown-menu__btn:active {
    border-color: $color-grey-dark;
  }

  .sub-options {
    display: flex;
    flex-wrap: wrap;

    h4 {
      margin-bottom: 6px;
      width: 100%;
    }

    .sector-link {
      display: block;
      padding: 6px 0;
      width: 100%;

      @include tablet {
        width: 50%;
      }

      .link {
        color: $color-purple-dark;
        font-size: normal;
      }
    }
  }
}
