.section-divider {
  --section-background: #{$color-white};

  background-color: $color-purple;
  color: $color-white;
  overflow-x: hidden;
  position: relative;

  &::before,
  &::after {
    background-color: var(--section-background);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;
  }

  &::before {
    border-bottom-left-radius: var(--round-corner-size);

    @include desktop {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  &::after {
    border-top-right-radius: var(--round-corner-size);

    @include desktop {
      position: relative;
      right: var(--single-grid-column-with-gutter);
    }
  }

  .section-divider__content {
    padding: var(--component-margin) 0;
  }

  &.section-divider--hide-before {
    &::before {
      content: none;
    }
  }

  &.section-divider--hide-after {
    &::after {
      content: none;
    }
  }

  &.section-divider--hide-both {
    margin: var(--round-corner-size) 0;

    &::before,
    &::after {
      content: none;
    }
  }
}

.section-divider--stripe-left {
  &::before {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--round-corner-size);

    @include desktop {
      //left: var(--single-grid-column-with-gutter);
      left: 0;
      position: relative;
    }
  }

  &::after {
    border-top-left-radius: var(--round-corner-size);
    border-top-right-radius: 0;

    @include desktop {
      //left: var(--single-grid-column-with-gutter);
      left: 0;
      position: relative;
    }
  }
}

.section-divider--stripe-right {
  &::before {
    border-bottom-left-radius: var(--round-corner-size);
    border-bottom-right-radius: 0;

    @include desktop {
      position: relative;
      //right: var(--single-grid-column-with-gutter);
      right: 0;
    }
  }

  &::after {
    border-top-left-radius: 0;
    border-top-right-radius: var(--round-corner-size);

    @include desktop {
      position: relative;
      //right: var(--single-grid-column-with-gutter);
      right: 0;
    }
  }
}

.section-divider--no-top {
  &::before {
    content: none;
  }
}

.section-divider--full-width {
  @include desktop {
    &::before {
      margin-left: 0;
    }

    &::after {
      left: 0;
      right: 0;
    }
  }
}

.section-wrapper--section-background-grey {
  --section-background: #{$color-grey};
}
