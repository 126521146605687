
.text-field {
  --text-field-color-focus-hover: #{$color-purple-dark};

  position: relative;

  .text-field__label {
    @include typography(field-label, false, true, false);

    cursor: text;
    display: block;
    left: 16px;
    overflow: hidden;
    position: absolute;
    right: 5px;
    text-overflow: ellipsis;
    top: 18px;
    transform-origin: left;
    transition: all $easing-change;
    user-select: none;
    white-space: nowrap;

    .text-field__label-icon {
      visibility: hidden;
    }
  }

  .text-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;

    &.text-field__wrapper--max-count {
      .text-field__input {
        padding-right: 80px;
      }

      .text-field__label {
        width: 80%;
      }
    }
  }

  .text-field__input {
    @include typography(field-value);

    appearance: none;
    background-color: transparent;
    border: 1px solid $color-purple-lighter;
    border-radius: 2px;
    color: inherit;
    display: inline-block;
    flex: 1 1 100%;
    margin: 0;
    min-height: 60px;
    min-width: 64px;
    overflow: hidden;
    padding: 22px 15px 6px;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:hover:not(:disabled) {
      border-color: var(--text-field-color-focus-hover);
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }

    &:focus {
      border-color: var(--text-field-color-focus-hover);
      outline: none;
    }
  }

  .text-field__character-count {
    bottom: 5px;
    color: rgba($color-purple-dark, .3);
    font-size: 12px;
    position: absolute;
    right: 10px;
  }

  &:focus-within,
  &.text-field--has-value {
    .text-field__label {
      font-size: 12px;
      transform: translateY(-10px);
    }
  }

  .text-field__helptext {
    @include typography(field-helptext);

    margin-top: 2px;
  }

  .text-field__element-invisible {
    clip: rect(1px 1px 1px 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }

  .text-field__error {
    display: none;
  }

  &.text-field--error {
    .text-field__error-icon {
      @include field-error-icon;
    }

    .text-field__error {
      @include typography(field-helptext);

      display: block;
      margin-top: 2px;
    }

    .text-field__input:not(:focus):not(:hover) {
      border-color: $color-error;
    }

    &.text-field--has-value {
      .text-field__input:not(:focus):not(:hover) {
        border-color: $color-purple-lighter;
      }
    }

    &.text-field--has-changed {
      .text-field__error {
        display: none;
      }
    }
  }

  &.text-field--inline {
    display: block;
    margin-left: -12px;
    overflow: hidden;
    width: 100%;

    &.text-field--textarea {
      margin-top: 34px;
      overflow: visible;

      .text-field__label {
        transform: translateY(-44px);
      }

      .text-field__input {
        padding-top: 4px;
      }
    }

    .text-field__label {
      font-size: 14px;
      font-weight: bold;
      transform: translateY(-10px);

      .text-field__label-icon {
        float: right;
        height: 16px;
        margin: 3px 0 0 5px;
        position: absolute;
        visibility: visible;
        width: 16px;
      }
    }

    .text-field__input {
      border: 1px solid transparent;
      font-weight: normal;
      line-height: 24px;

      &:focus {
        border: 1px solid $color-purple-lighter;

        + .text-field__label {
          .text-field__label-icon {
            visibility: hidden;
          }
        }
      }

      &::placeholder {
        color: $color-purple-dark;
        line-height: 24px;
        position: relative;
      }
    }
  }

  &.text-field--small {
    &.text-field--textarea .text-field__input {
      min-height: auto;
    }
  }
}

.text-field--color-white {
  --text-field-color-focus-hover: #{$color-white};
}

.text-field--textarea {
  margin-top: 34px;
  overflow: visible;
  position: relative;

  .text-field__wrapper {
    display: grid;

    &.text-field__wrapper--max-count {
      .text-field__input {
        padding-right: 80px;
      }

      .text-field__label {
        width: 80%;
      }
    }

    // Auto-growing textarea taken from https://css-tricks.com/auto-growing-inputs-textareas/
    &::after {
      border: 1px solid;
      font: inherit;
      grid-area: 1 / 1 / 2 / 2;
      padding-top: 28px;
      visibility: hidden;
      white-space: pre-wrap;
    }
  }

  .text-field__label {
    transform: translateY(-44px);
    z-index: 1;
  }

  .text-field__input {
    grid-area: 1 / 1 / 2 / 2;
    min-height: 10em;
    padding-bottom: 25px;
    padding-top: 4px;
    resize: none;
    white-space: unset;
  }

  &:focus-within,
  &.text-field--has-value {
    .text-field__label {
      transform: translateY(-44px);
    }
  }
}

.text-field--search {
  .text-field__input {
    padding-left: 48px;
    padding-right: 64px;
  }

  .text-field__icon,
  .text-field__button-icon {
    border: 16px solid transparent;
    fill: currentColor;
    height: 64px;
    left: 8px;
    line-height: 64px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 0;
    width: 48px;
    z-index: 1;
  }

  .text-field__button {
    border-radius: 0;
    color: inherit;
    height: 64px;
    margin: 0;
    margin-left: -64px;
    padding: 16px;
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;

    .button__icon {
      height: 32px;
      width: 32px;
    }
  }
}
