.cao-timeline {
  $color-cao-timeline-purple-dark: #612e7a;
  $color-cao-timeline-purple-light: #a383b3;

  background-color: $color-purple;
  color: $color-white;
  position: relative;

  &::after {
    background-color: $color-grey;
    border-top-right-radius: var(--round-corner-size);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;

    @include desktop {
      position: relative;
      //right: var(--single-grid-column-with-gutter);
    }
  }

  .cao-timeline__grid {
    @include grid-container;
  }

  .cao-timeline__wrapper {
    grid-column: var(--layout-full);

    @include notebook {
      grid-column: var(--layout-content-large);
    }
  }

  .cao-timeline__container {
    --padding: 30px;

    padding-bottom: var(--padding);
    padding-top: calc(var(--round-corner-size) + var(--padding));
    position: relative;

    @include notebook {
      --padding: 45px;
    }

    &::before {
      background-color: $color-cao-timeline-purple-dark;
      border-bottom-left-radius: var(--round-corner-size);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      //
      //@include desktop {
      //  left: var(--single-grid-column-with-gutter);
      //}
    }

    &::after {
      background-color: $color-grey;
      border-bottom-left-radius: var(--round-corner-size);
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .article-idea-section {
    .article-idea-section__idea-section {
      grid-column: var(--layout-content-image);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--heading-3-margin-bottom);
    position: relative;

    .cao-timeline__title {
      @include typography(heading-3);
    }

    .cao-timeline__expander {
      height: 36px;
      transform: rotateX(180deg) translateY(-10px);
      transition: all .2s;
      width: 36px;

      &.is-closed {
        transform: rotateX(0deg) translateY(0px);
      }
    }
  }

  .cao-timeline__timeline {
    position: relative;

    @include notebook {
      &.has-overflow {
        &::before,
        &::after {
          bottom: 0;
          content: '';
          position: absolute;
          top: 0;
          width: 100px;
          z-index: 11;
        }

        &::before {
          background: linear-gradient(90deg, $color-cao-timeline-purple-dark 0%, rgba($color-cao-timeline-purple-dark, 0) 100%);
        }

        &::after {
          background: linear-gradient(270deg, $color-cao-timeline-purple-dark 0%, rgba($color-cao-timeline-purple-dark, 0) 100%);
          right: 0;
        }

        .cao-timeline__phases {
          &::before,
          &::after {
            content: '';
            display: block;
          }

          &::before {
            padding-left: 100px;
          }

          &::after {
            padding-right: 100px;
          }
        }
      }
    }
  }

  .cao-timeline__phases {
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    overflow-x: auto;
    padding-left: var(--single-grid-column-with-gutter);

    @include notebook {
      padding-left: 0;
    }

    &::after {
      content: '';
      display: block;
      //padding-right: var(--single-grid-column-with-gutter);

      @include notebook {
        content: none;
      }
    }
  }

  .cao-timeline__phase-item {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding: 0 5px;
    position: relative;

    &:not(:first-child):not(:last-child) {
      flex-basis: 100%;
    }

    &:nth-child(1):nth-last-child(2),
    &:nth-child(2):nth-last-child(1) {
      // for when there are only 2 phase items
      flex-grow: 1;
    }

    &::before {
      border-bottom: 1px dashed $color-white;
      content: '';
      left: 2px;
      position: absolute;
      right: 0;
      top: 10px;
    }

    &.is-active {
      .cao-timeline__phase-btn {
        background: $color-white;
        color: $color-purple-dark;
      }
    }

    &.is-available::after {
      border-bottom: 1px solid $color-white;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 10px;
      z-index: 2;
    }

    &.is-current-phase::after {
      right: 50%;
    }

    &:first-child {
      padding-left: 0;

      &::before,
      &::after {
        left: 50%;
      }
    }

    &:last-child {
      padding-right: 0;

      &::before,
      &::after {
        right: 50%;
      }
    }
  }

  .cao-timeline__phase-btn {
    @include typography(tag);

    border: 1px solid $color-white;
    border-radius: 666px;
    color: $color-white;
    padding: 6px 10px;
    position: relative;
    transition: color $easing-change, background-color $easing-change, border-color $easing-change;

    &:focus,
    &:hover {
      background: $color-white;
      color: $color-purple-dark;
    }

    &::after {
      background: $color-white;
      border: 2px solid $color-white;
      border-radius: 50%;
      bottom: 33px;
      box-sizing: content-box;
      content: '';
      height: 6px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 6px;
      z-index: 10;
    }

    &::before {
      border: 4px solid $color-cao-timeline-purple-dark;
      border-radius: 50%;
      bottom: 33px;
      box-sizing: content-box;
      content: '';
      height: 6px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 6px;
      z-index: 10;
    }

    &:disabled {
      background: none;
      border-color: $color-cao-timeline-purple-light;
      color: $color-cao-timeline-purple-light;

      &::after {
        background: transparent;
      }

      &::before {
        background: $color-cao-timeline-purple-dark;
      }
    }
  }

  .cao-timeline__content {
    // padding-bottom: var(--component-margin);
    // padding-top: var(--component-margin);

    h4 {
      margin-bottom: 10px;
    }
  }
}
