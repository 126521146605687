.card-grid-expandable {
  padding: var(--component-margin) 0;
  position: relative;

  &.is-collapsed {
    .card-grid-expandable__cards .card:nth-child(n+9) {
      // display max 8 items
      display: none;
    }
  }

  .card-grid-expandable__grid {
    @include grid-container;
  }

  .card-grid-expandable__wrapper {
    grid-column: var(--layout-content-large);
  }

  .card-grid-expandable__search {
    margin-bottom: 30px;

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }
  }

  .card-grid-expandable__cards {
    display: grid;
    gap: var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .card-grid-expandable__btn-wrapper {
    margin-top: 30px;
  }
}

.card-grid-expandable--threecolumns {
  .card-grid-expandable__wrapper {
    grid-column: var(--layout-content-image);
  }

  .card-grid-expandable__cards {
    grid-template-columns: repeat(1, 1fr);

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.is-collapsed {
    .card-grid-expandable__cards .card:nth-child(n+7) {
      // display max 8 items
      display: none;
    }
  }

  .card {
    height: 200px;
  }
}

.card-grid-expandable--fourcolumns {
  .card-grid-expandable__cards {
    grid-template-columns: repeat(1, 1fr);

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .card-grid-expandable__subtitle {
    font-size: 18px;
    margin-bottom: 15px;
  }

  &.is-collapsed {
    .card-grid-expandable__cards .card:nth-child(n+5) {
      // display max 4 items
      display: none;
    }
  }

  .card-grid-expandable__btn-wrapper {
    margin-top: 15px;

    .button-toggle {
      font-size: 16px;
    }
  }
}
