.autocomplete {
  --text-field-color-focus-hover: #{$color-purple-dark};

  .autocomplete__container {
    border: 1px solid $color-purple-lighter;
    border-radius: 3px;

    .items.ts-input {
      background: none;
      border-radius: 3px;
      max-height: 57px;
      padding: 14px 16px 16px;

      input {
        @include typography(field-label, false, true, false);

        line-height: 1.5;

        &::-webkit-input-placeholder { /* Edge */
          color: $color-purple-dark;
        }

        &:-ms-input-placeholder { /* Internet Explorer */
          color: $color-purple-dark;
        }

        &::placeholder {
          color: $color-purple-dark;
        }
      }
    }

    .ts-dropdown.single {
      border: 1px solid $color-purple-lighter;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: none;
      box-sizing: border-box;
    }
  }

  &__item {
    display: none;
  }

  &__item-text {
    font-weight: 600;
    line-height: 1.5;
    padding: 4px;
  }

  &__option {
    padding: 12px;

    @include typography(field-label, false, true, false);

    &:hover {
      background-color: $color-grey;

      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &:first-child {
      border-top: 1px solid $color-grey-lighter;
    }
  }

  &__option-text {
    line-height: 1.5;
    padding: 4px;
  }

  .ts-dropdown {
    background: $color-white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    left: -1px;
    margin-top: -3px;
    position: absolute;
    right: -1px;
    top: 100%;
    width: calc(100% + 2px);
    z-index: 10;

    .highlight {
      font-weight: 600;
    }
  }

  .ts-control.plugin-drag_drop.multi > .ts-input > div.ui-sortable-placeholder {
    box-shadow: inset 0 0 12px 4px #fff;
    visibility: visible;
  }

  .ts-control.plugin-drag_drop .ui-sortable-placeholder::after {
    content: '!';
    visibility: hidden;
  }

  .ts-control.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  }

  .plugin-checkbox_options .option input {
    margin-right: .5rem;
  }

  .plugin-clear_button .items {
    padding-right: calc(1em + (3 * 6px));
  }

  .plugin-clear_button .clear-button {
    cursor: pointer;
    margin-right: 0;
    opacity: 0;
    position: absolute;
    right: calc(8px - 6px);
    top: 8px;
    transition: opacity .5s;
  }

  .plugin-clear_button.single .clear-button {
    right: calc(8px - 6px + 2rem);
  }

  .plugin-clear_button .has-items.focus .clear-button,
  .plugin-clear_button:hover .has-items .clear-button {
    opacity: 1;
  }

  .ts-control .dropdown-header {
    padding: 10px 8px;
    position: relative;
  }

  .ts-control .dropdown-header-close {
    color: #303030;
    font-size: 20px;
    line-height: 20px;
    margin-top: -12px;
    opacity: .4;
    position: absolute;
    right: 8px;
    top: 50%;
  }

  .ts-control .dropdown-header-close:hover {
    color: $color-black;
  }

  .plugin-dropdown_input .ts-input.focus {
    box-shadow: none;
  }

  .plugin-dropdown_input .dropdown-input {
    background: transparent;
    box-shadow: none;
    display: block;
    padding: 8px;
    width: 100%;
  }

  .ts-control.plugin-input_autogrow .ts-input.has-items > input {
    min-width: 0;
  }

  .ts-control.plugin-input_autogrow .ts-input.focus.has-items > input {
    flex: none;
    min-width: 4px;
  }

  .ts-control.plugin-input_autogrow .ts-input.focus.has-items > input::-webkit-input-placeholder {
    color: transparent;
  }

  .ts-control.plugin-input_autogrow .ts-input.focus.has-items > input::-ms-input-placeholder {
    color: transparent;
  }

  .ts-control.plugin-input_autogrow .ts-input.focus.has-items > input::placeholder {
    color: transparent;
  }

  .ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
    display: flex;
  }

  .ts-dropdown.plugin-optgroup_columns .optgroup {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
  }

  .ts-dropdown.plugin-optgroup_columns .optgroup::before {
    display: none;
  }

  .ts-control.plugin-remove_button .item {
    align-items: center;
    display: inline-flex;
    padding-right: 0;
  }

  .ts-control.plugin-remove_button .item .remove {
    box-sizing: border-box;
    color: inherit;
    display: inline-block;
    margin-left: 6px;
    padding: 2px 6px;
    text-decoration: none;
    vertical-align: middle;
  }

  .ts-control.plugin-remove_button .remove-single {
    font-size: 23px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ts-control {
    position: relative;
  }

  .ts-dropdown,
  .ts-input,
  .ts-input input {
    font-size: 13px;
    line-height: 18px;
  }

  .ts-input,
  .ts-control.single .ts-input.input-active {
    cursor: text;
  }

  .ts-input {
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .ts-control.multi .ts-input.has-items {
    padding: calc(8px - 2px) 8px calc(8px - 2px - 3px);
  }

  .ts-input.full {
    background-color: #fff;
  }

  .ts-input.disabled,
  .ts-input.disabled * {
    cursor: default;
  }

  .ts-input.focus {
    box-shadow: none;
  }

  .ts-input > * {
    display: inline-block;
    vertical-align: baseline;
  }

  .ts-control.multi .ts-input > div {
    color: #303030;
    cursor: pointer;
    margin: 0 3px 3px 0;
    padding: 2px 6px;
  }

  .ts-input > input {
    background: none;
    box-shadow: none;
    display: inline-block;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0;
    max-height: none;
    max-width: 100%;
    min-height: 0;
    min-width: 7rem;
    padding: 0;
    text-indent: 0;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .ts-input > input::-ms-clear {
    display: none;
  }

  .ts-input > input:focus {
    outline: none;
  }

  .ts-input.has-items > input {
    margin: 0 4px;
  }

  .ts-input.dropdown-active::before {
    bottom: 0;
    content: ' ';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .ts-input.rtl {
    text-align: right;
  }

  .ts-input.rtl.single .ts-input::after {
    left: 15px;
    right: auto;
  }

  .ts-input.rtl .ts-input > input {
    margin: 0 4px 0 -2px;
  }

  // There is no situation where we want to hide the input at the moment
  //   and it clashes somehow with having initial items
  //.input-hidden .ts-input > input {
  //  left: -10000px;
  //  opacity: 0;
  //  position: absolute;
  //}

  .ts-dropdown [data-selectable] {
    cursor: pointer;
    overflow: hidden;
  }

  .ts-dropdown [data-disabled],
  .ts-dropdown [data-disabled] [data-selectable].option {
    cursor: inherit;
    opacity: .5;
  }

  .ts-dropdown [data-selectable].option {
    cursor: pointer;
    opacity: 1;
  }

  .ts-dropdown .optgroup-header {
    cursor: default;
  }

  .ts-dropdown .create:hover,
  .ts-dropdown .option:hover,
  .ts-dropdown .active {
    background-color: $color-grey;
  }

  .ts-dropdown .create:hover.create,
  .ts-dropdown .option:hover.create,
  .ts-dropdown .active.create {
    color: #495c68;
  }

  .ts-dropdown .create {
    color: rgba(48, 48, 48, .5);
  }

  .ts-dropdown .spinner {
    display: inline-block;
    height: 30px;
    margin: 5px 8px;
    width: 30px;
  }

  .ts-dropdown .spinner::after {
    animation: lds-dual-ring 1.2s linear infinite;
    border: 5px solid #d0d0d0;
    border-color: $color-grey transparent;
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 24px;
    margin: 3px;
    width: 24px;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ts-dropdown-content {
    border-radius: 3px;
    max-height: 145px;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    @include tablet {
      max-height: 200px;
    }
  }
}
