@import '../../definitions/variables';

$colors: (
  primary: $color-purple,
  secondary: $color-orange
);

@mixin modifiers($map, $attribute, $prefix: '-', $separator: '-', $base: 'base') {
  @each $key, $value in $map {
    &#{if($key != $base, #{$prefix}#{$key}, '')} {
      @if type-of($value) == 'map' {
        @include modifiers($value, $attribute, $separator);
      }
      @else {
        #{$attribute}: $value;

        @for $i from 1 through 9 {
          &--lighten-#{$i * 10} {
            #{$attribute}: lighten($value, $i * 10%);
          }
          &--darken-#{$i * 10} {
            #{$attribute}: darken($value, $i * 10%);
          }
        }
      }
    }
  }
}

.text {
  @include modifiers($colors, 'color', $separator: '--');
}

.background {
  @include modifiers($colors, 'background-color', $separator: '--');
}
