.cao-timeline-content {
  padding: var(--component-margin) 0;

  .cao-timeline-content__grid {
    @include grid-container;

    margin-bottom: var(--component-margin);
    row-gap: 40px;
  }

  .cao-timeline-content__content {
    grid-column: var(--layout-content-large);

    h2,
    h3 {
      @include typography(heading-3, false, true);
    }

    p,
    li {
      @include typography(introduction, true, true, true);
    }

    h2,
    h3,
    ul,
    ol {
      @include notebook {
        margin-left: var(--single-grid-column-with-gutter);
      }
    }
  }

  .cao-timeline-content__links {
    display: grid;
    gap: .5rem;
    margin-top: 1rem;
  }

  .idea-section-idea {
    .idea-section-idea__divider {
      border-color: $color-white;
    }
  }
}

.cao-timeline-content--single-card {
  .cao-timeline-content__content {
    grid-column: 2 / -2;

    @include notebook {
      grid-column: 3 / 10;
    }

    @include desktop {
      grid-column: 4 / 11;
    }
  }

  .cao-timeline-content__single-card {
    grid-column: 2 / -2;
    margin: 20px 0;

    @include notebook {
      grid-column: 3 / 6;
    }
  }

  .cao-timeline-content__poll {
    grid-column: 2 / -2;

    @include notebook {
      grid-column: 10 / 13;
    }

    .poll {
      margin: 20px 0;
      max-width: 100%;
    }
  }
}

.cao-timeline-content--poll {
  .cao-timeline-content__content {
    grid-column: 2 / -2;

    @include notebook {
      grid-column: 3 / 9;
    }

    @include desktop {
      grid-column: 4 / 11;
    }
  }

  .cao-timeline-content__poll {
    grid-column: 2 / -2;
    margin: 0 auto;
    width: 100%;

    .poll {
      margin: 0;
    }

    @include notebook {
      grid-column: 9 / 13;
    }

    @include desktop {
      grid-column: 11 / 14;
    }
  }
}
