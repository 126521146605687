.contact-block {
  background-color: $color-grey;
  margin-top: calc(var(--component-margin) * 1.6);

  .contact-block__grid {
    @include grid-container;
  }

  .contact-block__wrapper {
    grid-column: var(--layout-content-large);
    padding-bottom: calc(var(--component-margin) * 1.4);
    padding-top: calc(var(--component-margin) * 2.1);
  }

  .contact-block__title {
    @include typography(heading-3);

    margin-bottom: calc(var(--heading-3-margin-bottom) * .5);

    @include tablet {
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }
  }

  .contact-block__introduction {
    @include typography(paragraph);

    @include tablet {
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }
  }

  .contact-block__content {
    display: grid;
    grid-column: 2 / 14;
    margin-bottom: 45px;

    @include notebook {
      grid-column: 3 / 12;
    }

    @include desktop {
      grid-column: 4 / 12;
    }
  }

  .contact-block__contact-info {
    display: table-row;
    grid-column: 2 / 14;

    @include notebook {
      grid-column: 3 / 12;
    }

    @include desktop {
      grid-column: 4 / 12;
    }
  }

  .contact-block__contact-item {
    display: block;
    padding-bottom: 30px;
    padding-left: 35px;
    position: relative;

    @include tablet {
      display: table-cell;
      padding-bottom: 20px;
      padding-left: 28px;
      width: 370px;
    }

    .contact-block__icon {
      display: inline-block;
      height: 17px;
      left: -15px;
      line-height: 0;
      padding: 0 20px;
      position: absolute;
      top: 3px;
      width: auto;

      @include tablet {
        left: -30px;
      }

      svg {
        fill: $color-purple-dark;
        left: 8px;
        position: absolute;
        stroke: none;
      }
    }

    .mirrored-phone svg {
      transform: rotate(88deg);
    }

    .contact-block__list-label {
      font-weight: 600;
    }

    .contact-block__list-value {
      color: $color-purple-dark;
      text-decoration: underline;
    }
  }
}
