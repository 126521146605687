.search-field {
  .search-field__title {
    @include typography(heading-2);

    margin-bottom: 30px;
  }

  .search-field__row {
    display: flex;

    &:focus-within {
      .search-field__btn {
        display: flex;
      }
    }
  }

  .search-field__input {
    @include typography(introduction);

    background: transparent;
    border-bottom: 1px solid $color-purple-lightest;
    margin-bottom: 10px;
    padding-bottom: 5px;
    width: 100%;

    &::placeholder {
      color: inherit;
    }

    &:focus {
      border-bottom-color: inherit;
      color: $color-purple-dark;
      outline: 0;
    }
  }

  .search-field__btn {
    align-items: flex-end;
    cursor: pointer;
    display: none;
    margin-left: 10px;

    svg {
      height: 25px;
      width: 25px;
    }
  }
}
