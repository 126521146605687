
.disclosure {
  position: relative;

  .disclosure__title {
    @include typography(introduction);

    display: block;
    width: 100%;
  }

  .disclosure__title-button {
    appearance: none;
    background-color: transparent;
    color: inherit;
    display: flex;
    font: inherit;
    padding: calc(var(--component-margin) / 4) calc(var(--introduction-line-height) + 10px) calc(var(--component-margin) / 4) 0;
    text-align: left;
    transition: color $easing-change;
    width: 100%;

    &:hover {
      .disclosure__title-button-span {
        background-size: 100% 1px;
      }
    }
  }

  .disclosure__title-button-span {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    display: inline-block;
    transition: background-size .2s linear;
    vertical-align: baseline;
  }

  .disclosure__title-button-icon {
    display: inline-block;
    height: var(--introduction-line-height);
    transform: none;
    transform-origin: 50% 50%;
    vertical-align: top;
    width: var(--introduction-line-height);
  }

  .disclosure__content-container {
    &[aria-hidden='true'] {
      display: none;
    }
  }

  .disclosure__content {
    padding: calc(var(--component-margin) / 4) 0 calc(var(--component-margin) / 2);
  }

  &.disclosure--expanded {
    .disclosure__title-button-icon--expand {
      display: none;
    }
  }

  &:not(.disclosure--expanded) {
    .disclosure__title-button-icon--collapse {
      display: none;
    }
  }
}
