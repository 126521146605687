
:root {
  --heading-5-font-size: 16px;
  --heading-5-font-stack: #{$graphik-font-stack};
  --heading-5-font-style: normal;
  --heading-5-font-variant: normal;
  --heading-5-font-weight: #{get-graphik-weight(bold)};
  --heading-5-letter-spacing: -.25px;
  --heading-5-line-height: 1.25em;
  --heading-5-margin-bottom: 10px;
  --heading-5-margin-top: 30px;
  --heading-5-word-spacing: normal;

  @include notebook {
    --heading-5-font-size: 16px;
    --heading-5-margin-bottom: 15px;
    --heading-5-margin-top: 40px;
  }

  @include desktop {
    --heading-5-font-size: #{get-fluid-value(16px, 18px)};
    --heading-5-margin-top: 60px;
  }
}
