
:root {
  --button-font-size: 16px;
  --button-font-stack: #{$graphik-font-stack};
  --button-font-style: normal;
  --button-font-variant: normal;
  --button-font-weight: #{get-graphik-weight(bold)};
  --button-letter-spacing: -.25px;
  --button-line-height: 20px;
  --button-margin-bottom: 0;
  --button-margin-top: 0;
  --button-word-spacing: normal;
}
