.section-wrapper {
  background-color: $color-white;
  color: inherit;
  position: relative;

  .article-calendar__item {
    background-color: $color-grey;
  }

  .idea-section {
    .idea-section-header {
      background-color: rgba($color-purple-lightest, .2);
    }

    .idea-section-idea__divider {
      border-bottom-color: rgba($color-purple-dark, .2);
    }

    .idea-section-user-label {
      background-color: $color-grey-lighter;
    }
  }

  .contact-card {
    background-color: $color-white;
  }

  &.extra-spacing {
    margin-top: var(--component-margin);
    padding-top: var(--component-margin);
  }
}

.section-wrapper--background-grey {
  background-color: $color-grey;
  color: inherit;

  .campaign-header::before {
    background-color: $color-grey;
  }

  .article-calendar__item,
  .contact-card {
    background-color: $color-white;
  }

  .idea-section {
    .idea-section-header {
      background-color: rgba($color-purple-lightest, .2);
    }

    .idea-section-idea__divider {
      border-bottom-color: rgba($color-purple-dark, .2);
    }

    .idea-section-user-label {
      background-color: $color-white;
    }
  }
}

.section-wrapper--background-purple {
  background-color: $color-purple;
  color: $color-white;

  .card,
  .article-calendar__item,
  .contact-card {
    background-color: $color-white;
  }

  .article-calendar__title {
    color: $color-white;
  }
}

.section-wrapper--stripe-left {
  @include desktop {
    &::before {
      background-color: $color-purple;
      content: '';
      height: 100%;
      position: absolute;
      width: var(--single-grid-column-with-gutter);
      z-index: 100;  // overlap the carousels
    }
  }
}

.section-wrapper--stripe-right {
  @include desktop {
    &::before {
      background-color: $color-purple;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      width: var(--single-grid-column-with-gutter);
      z-index: 100;  // overlap the carousels
    }
  }
}

.section-wrapper--gutter-bottom {
  padding-bottom: 50px;
}
