.article-idea-section {
  padding: var(--component-margin) 0;
  position: relative;

  .article-idea-section__grid {
    @include grid-container;

    row-gap: 25px;

    @include notebook {
      row-gap: 40px;
    }
  }

  .article-idea-section__idea-section {
    grid-column: var(--layout-content-large);
  }
}
