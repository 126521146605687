.introduction {
  .introduction__title {
    line-height: 1;
    margin-bottom: 20px;
  }

  b {
    font-weight: 600;
  }

  .introduction__caption {
    @include typography(caption);

    margin-bottom: 20px;
  }

  .introduction__richtext:not(:last-child) {
    margin-bottom: 20px;
  }
}
