
.richtext {
  position: relative;

  a {
    @include style-fancy-link-underline;

    &,
    &:link,
    &:active,
    &:visited {
      color: $color-purple-dark;
    }

    &:visited {
      opacity: .33;
    }
  }
}

.richtext--regular {
  .comment-section-comment__intro {
    p {
      font-size: 18px;
    }
  }

  h1 {
    @include typography(heading-1, true, true);
  }

  h2 {
    @include typography(heading-2, true, true);
  }

  h3 {
    @include typography(heading-3, true, true);
  }

  h4 {
    @include typography(heading-4, true, true);
  }

  h5 {
    @include typography(heading-5, true, true);
  }

  h6 {
    @include typography(heading-6, true, true);
  }

  p,
  ul,
  ol {
    @include typography(paragraph, true, true, true);
  }

  p {
    @include enable-strong;
    @include enable-emphasized;

    font-size: 16px;
  }

  li {
    @include typography(paragraph, false, false);
    @include adaptive-margin(calc(var(--paragraph-margin-top) * .5), calc(var(--paragraph-margin-bottom) * .5));
  }

  ul {
    @include style-list-base;
    @include style-list-unordered;
  }

  ol {
    @include style-list-base;
    @include style-list-ordered;
  }

  strong {
    font-weight: 700;
  }

  table {
    border: 1px solid $color-purple-dark;

    td {
      background-color: $color-grey;
      border: 2px solid $color-white;
      padding: 20px;
    }
  }
}

.richtext--microcopy {
  p {
    @include typography(caption, true, true);
    @include enable-strong;
    @include enable-emphasized;
  }
}

.richtext--simple {
  p {
    @include typography(paragraph, true, true);
    @include enable-strong;
    @include enable-emphasized;
  }
}

.richtext--introduction {
  p {
    @include typography(introduction, true, true);
  }
}
