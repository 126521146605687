
.card-list {
  position: relative;
  width: 100%;

  .card-list__title {
    border-bottom: 1px solid #6d5777;
    color: $color-purple-dark;
    font: 700 18px / 24px $graphik-font-stack;
    letter-spacing: -.31px;
    margin-bottom: 26px;
    padding-bottom: 7px;

    @include notebook {
      font: 700 16px / 22.4px $graphik-font-stack;
      letter-spacing: 0;
      padding-bottom: 9px;
    }
  }

  .card-list__cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &:hover,
    &:focus {
      .card-list__content {
        background-color: $color-purple-dark;
      }
    }
  }

  .card-list__content {
    background-color: $color-grey-extralight;
    padding: 20px;
    position: relative;
    width: 100%;
  }

  .card-list__card + .card-list__card {
    border-top: 1px solid #d4cad780;
    margin-top: 20px;
    padding-top: 20px;
  }

  .card-list__pagination {
    text-align: center;
  }
}
