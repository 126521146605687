.u-display-only-mobile {
  display: block !important;

  @include tablet {
    display: none !important;
  }
}

.u-display-inline-only-mobile {
  display: inline-block !important;

  @include tablet {
    display: none !important;
  }
}

.u-display-block-max-tablet {
  display: block !important;

  @include tablet {
    display: none !important;
  }
}

.u-display-block-min-tablet {
  display: none !important;

  @include tablet {
    display: block !important;
  }
}

.u-display-block-max-notebook {
  display: block !important;

  @include notebook {
    display: none !important;
  }
}

.u-display-block-min-notebook {
  display: none !important;

  @include notebook {
    display: block !important;
  }
}

.u-display-flex-max-notebook {
  display: flex !important;

  @include notebook {
    display: none !important;
  }
}

.u-display-flex-min-notebook {
  display: none !important;

  @include notebook {
    display: flex !important;
  }
}
