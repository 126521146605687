.tag {
  @include typography(tag);

  background: $color-white;
  border: 1px solid $color-purple-dark;
  border-radius: 666px;
  display: inline-block;
  margin-right: 6px;
  padding: 6px 10px;
}
