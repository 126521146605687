
.article-two-columns {
  margin-bottom: 20px;
  padding: var(--component-margin) 0;
  position: relative;

  .article-two-columns__grid {
    @include grid-container;

    row-gap: 25px;

    @include notebook {
      row-gap: 40px;
    }
  }

  .article-two-columns__wrapper {
    grid-column: var(--layout-content-large);
  }

  .article-two-columns__heading {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(1, 1fr);

    @include notebook {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .article-two-columns__title {
    @include typography(heading-2, true, false);

    font-size: 30px;
    margin-bottom: 15px;

    @include notebook {
      font-size: 40px;
      margin-bottom: 30px;
    }

    @include desktop {
      font-size: 50px;
      margin-bottom: 40px;
    }
  }

  .article-two-columns__columns {
    display: grid;
    gap: 10;
    grid-template-columns: repeat(1, 1fr);

    @include notebook {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .article-two-columns__paragraph {
    margin-bottom: 0;

    @include notebook {
      margin-bottom: 15px;
    }
  }

  .article-two-columns__listitem {
    @include notebook {
      margin-left: 29%;
    }
  }

  .article-two-columns__links {
    margin-top: 30px;
  }

  .article-two-columns__links--mobile {
    display: grid;
    margin-bottom: 20px;
  }

  .article-two-columns__links--desktop {
    display: none;
  }

  @include notebook {
    .article-two-columns__links--mobile {
      display: none;
    }

    .article-two-columns__links--desktop {
      display: grid;
    }
  }

  .article-two-columns__subtitle {
    margin-bottom: 15px;
    margin-top: 20px;

    @include notebook {
      margin-top: 0;
    }
  }

  @include notebook {
    .article-two-columns__grid {
      grid-auto-flow: dense;
    }
  }
}
