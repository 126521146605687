.group-list {
  @include grid-container;

  &:last-child {
    margin-bottom: 20px;
  }

  .group-list__grid {
    grid-column: var(--layout-grid-inline);
    padding-top: calc(var(--component-margin) * 2);
  }

  .group-list__groups {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    list-style-type: none;
    padding-inline-start: 0;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .group-list__title {
    font-size: medium;
    margin-bottom: 12px;
  }

  .group-list__footer {
    margin-top: 10px;
  }

  .group {
    min-width: 0;

    .group-card {
      border: 1px solid $color-purple-light;
      border-radius: 10px;
      display: block;
      padding: 15px;
      position: relative;
      text-decoration: none;

      &--dashed {
        border-style: dashed;
      }

      .group-card__heading {
        font-size: 14px;
        height: 22px;
        line-height: 1.3em;
        margin-bottom: 0;
        max-width: 90%;
        min-height: 35px;
        overflow: hidden;
      }

      .group-card__attributes {
        height: 18px;
        position: absolute;
        right: 11px;
        top: 12px;
        width: 18px;
      }

      .group-card__status {
        color: $color-purple-light;
        font-size: 12px;
        height: 22px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .group-card__description {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: small;
        height: 44px;
        -webkit-line-clamp: 2;
        margin-bottom: 12px;
        overflow: hidden;
      }
    }
  }
}
