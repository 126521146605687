.comment-section {
  align-items: start;
  display: block;
  grid-auto-rows: min-content;
  grid-column: var(--layout-content-large);
  margin: 78px 0 0;

  @include tablet {
    @include grid-container;

    grid-column: 2 / 14;
  }

  .comment-section__title {
    h2 {
      @include typography(heading-3);

      flex-grow: 1;
    }

    margin-bottom: 18px;
    margin-left: 25px;

    @include tablet {
      display: flex;
      margin-bottom: 22px;
    }
  }

  .comment-section__intro-text,
  .comment-section__empty-text {
    @include typography(introduction);
  }

  .comment-section__empty-text {
    margin-top: 15px;
  }

  .comment-section__help-text {
    @include typography(paragraph);

    margin-top: 15px;
  }

  .comment-header {
    display: flex;

    @include animated-underline('.comment-header__text', '.comment-header__text');

    .comment-header__text {
      float: none;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      margin-right: 40px;
      word-break: break-word;
    }

    .comment-header__labels {
      padding: 2px 0;
    }

    .comment-header__function {
      font-size: 14px;
      line-height: 1.5;
    }

    .comment-header__date {
      font-size: 12px;
      line-height: 1.5;

      @include tablet {
        font-size: 14px;
      }
    }
  }

  &--dark {
    .comment-header {
      .comment-header__labels {
        .tag {
          background-color: $color-white;
          color: $color-purple-dark;
        }
      }
    }
  }

  .comment-section-comment__text {
    word-break: break-word;

    .link {
      font-size: inherit;
      line-height: inherit;
      text-decoration: underline;
    }
  }

  .comment-section-comment__poll {
    .poll {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .comment-section-comment__text,
  .comment-section-comment__poll,
  .comment-section-comment__actions {
    padding-left: 6px;

    @include tablet {
      padding-left: 86px;
    }
  }

  .comment-section-comment__form-reply {
    @include tablet {
      margin-top: 18px;
      padding-left: 65px;

      &.expanded {
        padding-left: 75px;
      }

      @include notebook {
        &.expanded {
          padding-left: 89px;
        }
      }

      .form__wrapper {
        display: flex;
        flex-wrap: wrap;

        .comment-header {
          width: auto;

          .comment-header__avatar {
            margin-right: 25px;
          }
        }

        .form-fieldset {
          flex-grow: 1;
        }
      }
    }
  }

  .comment-section-comment__form-reply-container {
    background-color: rgba($color-purple-dark, .15);
    padding: 18px;

    @include tablet {
      padding-top: 30px;

      .form__wrapper {
        display: flex;
        flex-wrap: wrap;

        .comment-header {
          width: auto;

          .comment-header__avatar {
            margin-left: 2px;
            margin-right: 29px;
          }
        }

        .form-fieldset {
          flex-grow: 1;
        }
      }

      .form--forminline .form__actions {
        margin-left: 0;
      }
    }
  }

  .text-field {
    &.text-field--inline {
      margin-left: 0;
    }
  }

  .comment-section-comment__button-like {
    .button-toggle__title {
      font-size: 12px;
    }
  }

  .comment-section-reply__like-count {
    font-size: 12px;
  }

  .comment-section-reply-button {
    .link__icon--before {
      margin-right: 0;
      top: 3px;
    }

    .link__span {
      font-size: 12px;
    }
  }

  .form--forminline {
    margin-bottom: 0;

    @include tablet {
      width: auto;
    }

    .form__actions {
      margin-left: calc(var(--grid-gutter) * -.5);
      margin-right: calc(var(--grid-gutter) * -.5);
      margin-top: 10px;
      position: relative;

      @include tablet {
        padding-left: 86px;
      }
    }

    .form__actions.form__actions--left {
      bottom: 0;
      left: 0;
    }
  }

  .comment-section__comments {
    .comment-header__avatar {
      height: 40px;
      min-width: 40px;
      width: 40px;

      @include tablet {
        height: 60px;
        margin-left: 6px;
        min-width: 60px;
        width: 60px;
      }
    }

    .comment-section-comment {
      //background-color: rgba($color-purple-dark, .15);
      padding: 18px;

      .comment-section-comment {
        background: none;

        @include tablet {
          padding-left: 40px;
        }

        &:first-child {
          padding-top: 25px;

          @include tablet {
            padding-top: 50px;

            .comment-section-comment__action-dropdown {
              top: 42px;
            }
          }
        }

        .comment-section-comment__action-dropdown {
          top: 17px;

          @include tablet {
            right: 74px;
            top: 0;
          }
        }
      }

      .comment-section-comment__form-container {
        display: grid;
        grid-column: 2 / 20;

        @include tablet {
          grid-column: 2 / 14;

          .form--forminline {
            .form__actions {
              margin-left: 0;
            }
          }
        }
      }
    }

    .comment-section-comment__action-dropdown {
      right: 20px;
      top: 10px;

      @include tablet {
        right: 92px;
      }
    }
  }

  .comment-header__avatar {
    float: none;
    margin-left: 6px;
  }

  .comment-header__meta {
    display: flex;
    flex-direction: column;
    float: none;
    margin-bottom: 10px;

    @include notebook {
      display: block;

      .comment-header__text {
        margin-right: 0;
      }

      .comment-header__username,
      .comment-header__labels {
        display: inline;
      }
    }
  }

  .comment-section-comment__replies {
    grid-column: 2/20;

    .comment-section-reply {
      margin-top: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .comment-section-comment {
      margin-bottom: 30px;
      padding: 0;
    }

    .comment-section-comment__action-dropdown {
      right: 0;
    }
  }

  .comment-section-load-more-button {
    margin-bottom: 25px;
  }

  .comment-section-comment {
    @include grid-container;

    margin-bottom: 2px;
    overflow: hidden;
    padding: 30px;
    position: relative;

    @include tablet {
      width: auto;
    }
  }

  .form-fieldset {
    padding-bottom: 0;
    width: 100%;

    @include tablet {
      width: auto;
    }
  }

  &.comment-section--light {
    color: $color-purple-dark;

    .comment-header__text {
      color: $color-purple-dark;
    }

    .comment-section-comment__form-reply-container {
      background-color: $color-grey-lighter;
    }

    .text-field.text-field--inline .text-field__input {
      border-color: $color-purple-lighter;

      &:focus {
        border-color: $color-purple-dark;
      }
    }

    .comment-section-comment__button-like {
      &.button-toggle--is-collapsed {
        svg path,
        svg polygon {
          fill: $color-purple-dark;
          stroke: $color-purple-dark;
        }
      }

      svg path,
      svg polygon {
        fill: transparent;
        stroke: $color-purple-dark;
      }
    }
  }

  &.comment-section--dark {
    color: $color-white;

    .comment-header__text {
      color: $color-white;
    }

    .comment-header__function,
    .comment-header__date,
    .comment-section-reply__like-count,
    .text-field__input::placeholder {
      color: rgba($color-white, .5);
    }

    .text-field.text-field--inline .text-field__input {
      border-color: $color-purple-lighter;

      &:focus {
        border-color: $color-white;
      }
    }

    .comment-section-comment__button-like {
      &.button-toggle--is-collapsed {
        svg path,
        svg polygon {
          fill: $color-white;
          stroke: $color-white;
        }
      }

      svg path,
      svg polygon {
        fill: transparent;
        stroke: $color-white;
      }
    }
  }

  &:not(.comment-section--is-empty) {
    .comment-section__empty-text {
      display: none;
    }
  }

  .comment-section__subtitle,
  .comment-section__title {
    grid-column: var(--layout-content-large);

    @include tablet {
      margin-left: 0;
    }
  }

  .comment-section__subtitle {
    grid-column: var(--layout-aside);

    @include typography(paragraph);

    @include tablet {
      margin-bottom: 22px;
    }
  }

  .comment-section-comment__intro,
  .comment-section-comment__form,
  .comment-header,
  .comment-section-comment__empty-text,
  .comment-section-comment__text,
  .comment-section-comment__poll,
  .comment-section-comment__actions {
    grid-column: 1 / 20;

    @include tablet {
      grid-column: 2 / 14;
      width: 100%;
    }
  }

  .comment-section-comment__intro {
    margin-bottom: 20px;
  }

  .comment-section__button-login {
    margin-top: 20px;
  }
}

.comment-section-comment-list__comments {
  padding: 0;
  position: relative;
}

.comment-section-comment::after {
  background: rgba(255, 255, 255, .2);
  color: $color-white;
  content: '';
  grid-column: 2/15;
  height: 1px;
}

.comment-section-comment__replies .comment-section-comment::after {
  display: none;
}

.comment-section__templates {
  display: none;
}

.comment-section-comment__form-reply-container {
  @include grid-container;

  grid-column: 2 / 14;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.comment-section-comment__form-reply {
  .comment-section-comment:not(.comment-section-comment--reply-mode) & {
    display: none;
  }
}

.comment-section__comments {
  grid-column: 2 / 14;
}

// Comment Header
.comment-header {
  overflow: hidden;
}

.comment-section__comments .comment-section-comment__form-reply .comment-header__avatar,
.comment-header__avatar {
  border-radius: 50%;
  display: block;
  float: left;
  height: 30px;
  margin-right: 15px;
  overflow: hidden;
  width: 30px;

  @include tablet {
    height: 60px;
    margin-right: 20px;
    width: 60px;
  }
}

.comment-header__labels {
  .comment-header__label {
    margin: 0 10px 0 0;
  }
}

.comment-header__meta {
  float: left;
  min-height: 30px;
  width: auto;

  @include tablet {
    min-height: auto;
  }
}

.comment-header__meta-details {
  float: left;
  width: 100%;
}

.comment-header__function,
.comment-header__date {
  color: rgba(55, 21, 72, .5);
  display: block;
  float: left;
  font-size: 16px;
  line-height: 24px;
}

.comment-header__function {
  &:not(.empty) {
    + .comment-header__date {
      @include tablet {
        margin-left: 10px;

        &::before {
          content: '•';
          left: -5px;
          position: relative;
        }
      }
    }
  }

  width: 100%;

  @include tablet {
    width: auto;
  }
}

.comment-header__tag {
  margin-left: 10px;
}

.comment-header__date {
  @include tablet {
    float: left;
  }
}

// Comment actions
.comment-section-comment__actions {
  display: block;
  list-style: none;
  overflow: hidden;
  padding: 0;
  width: 100%;

  @include tablet {
    padding-left: 40px;
  }

  li {
    float: left;
    margin-bottom: 10px;
    margin-right: 15px;
    width: 100%;

    @include tablet {
      margin-bottom: 0;
      width: auto;
    }
  }
}

.comment-section-like-button {
  display: inline;
}

.comment-section-reply-button {
  margin-left: 10px;
}

.comment-section-reply__like-count {
  color: rgba(55, 21, 72, .5);
  font-size: 16px;
  line-height: 24px;

  &:not(.empty) {
    &::before {
      content: '•';
      left: -5px;
      margin-left: 5px;
      position: relative;
    }
  }
}

// content
.comment-section-comment__button-like {
  .button-toggle .button-toggle__icon {
    width: 17px;
  }
}

.comment-section-comment__button-reply,
.comment-section-comment__button-like {
  font-size: 16px;
}

.comment-section-comment__empty-text,
.comment-section-comment__text,
.comment-section-comment__replies,
.comment-section-comment__actions {
  margin-left: 0;

  @include tablet {
    padding: 0;
  }
}

.comment-section-comment__empty-text,
.comment-section-comment__text,
.comment-section-comment__comment {
  margin-bottom: 15px;

  @include tablet {
    margin-bottom: 18px;
  }
}

.comment-section-comment__action-dropdown {
  position: absolute;
  right: 40px;
  top: 25px;
  width: 20px;
}

.comment-section-comment-list__load-more-area {
  margin-top: 10px;
  text-align: center;
}
