// Remove google stuff
#google_translate_element {
  display: none;
  position: absolute;
  right: 0px;
}

#google_translate_element.translate-show {
  display: block;
}

.goog-te-banner-frame {
  display: none;
  margin-top: -20px;
  position: absolute;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  position: relative;
  right: 260px;
  top: 260px;
}

html {
  &.translated-ltr,
  &.translated-rtl {
    .container--content {
      margin-top: -40px;
    }
  }
}

// Translation Menu styling
.language-switch {
  align-items: center;
  display: flex;
  position: relative;

  svg:first-child {
    height: 24px;
    stroke: transparent;
    width: 24px;
  }

  .language-switch__chevron {
    svg {
      fill: $color-purple-lighter;
      height: 12px;
      width: 12px;
    }
  }

  .language-switch__selected {
    @include typography(paragraph);

    color: $color-white;
    font-size: 14px;
    text-transform: uppercase;
  }

  > :not(:last-child) {
    padding-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    .language-switch__chevron svg {
      transform: rotate(180deg);
    }
  }

  .language-switch__menu {
    background-color: $color-purple;
    color: $color-white;
    list-style: none;
    padding-left: 0;
    position: absolute;
    top: 90%;
    z-index: 10;

    @include notebook {
      top: 35px;
    }

    .language-switch__menu-item {
      @include typography(heading-6);

      background-color: $color-purple;
      border-bottom: 1px solid $color-purple-light;
      font-size: 14px;
      font-weight: 400;
      margin: 0 10px;
      padding: 10px;

      &.hidden {
        display: none;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $color-purple-lighter;
      }
    }

    &.is-collapsed {
      display: none;
    }

    @include notebook {
      left: auto;
      right: 0;
    }
  }
}
