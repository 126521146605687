
.form {
  position: relative;
  width: 100%;

  .form__title {
    @include typography(heading-3, false, true);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .form__text {
    @include typography(paragraph, true, true);

    &.form__text--error {
      @include typography(caption);

      border: 1px solid;
      border-radius: 5px;
      color: $color-error;
      margin: 20px 0;
      padding: 20px;
    }
  }

  .form__microcopy {
    color: $color-purple-light;
    margin-top: 20px;
    max-width: 483px;
  }
}

.form--forminline {
  margin-bottom: 20px;

  @include notebook {
    margin-bottom: 0;
    width: 73%;
  }

  .form__actions {
    bottom: -12px;
    display: none;
    position: absolute;
    right: 10px;
    text-align: right;
    width: 100%;
    z-index: 999;

    @include notebook {
      bottom: -7px;
      right: 0;
    }

    @include desktop {
      bottom: 0px;
    }

    &.form__actions--left {
      bottom: -25px;
      float: left;
      left: -17px;
      right: auto;
      text-align: left;

      @include tablet {
        bottom: -15px;
        left: 72px;
      }

      .form__reset {
        font-weight: normal;
        padding: 8px 18px 10px;
      }
    }
  }
}
