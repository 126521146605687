.file-field {
  &.file-field--profile-picture {
    &.file-field--has-files {
      .file-field__button--clear {
        display: table;
      }
    }

    .file-field__previews {
      background-color: $color-purple;
      border-radius: 50%;
      height: 75px;
      padding: 0;
      width: 75px;

      @include tablet {
        height: 80px;
        width: 80px;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EProfile picture icon%3C/title%3E%3Cg id='profile-picture-icon' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Profile' transform='translate(2.000000, 1.000000)' stroke='%23fff'%3E%3Cpath d='M5.2315234,8.00050907 C8.13691353,8.01389336 10,8.30938199 10,11 L10,11 L10,12 L0,12 L0,11 C0,8.23857625 1.96243388,8 5,8 Z M5,0 C6.65685425,0 8,1.34314575 8,3 C8,4.65685425 6.65685425,6 5,6 C3.34314575,6 2,4.65685425 2,3 C2,1.34314575 3.34314575,0 5,0 Z' id='Profiel-(fill)'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40px;
        content: '';
        display: block;
        height: 75px;
        width: 75px;

        @include tablet {
          height: 80px;
          width: 80px;
        }
      }
    }

    .file-field__preview {
      list-style: none;
      position: relative;
      width: 75px;

      @include tablet {
        width: 80px;
      }
    }

    .file-field__preview-image {
      border-radius: 50%;
      height: 75px;
      width: 75px;

      @include tablet {
        height: 80px;
        width: 80px;
      }
    }

    .file-field__button {
      display: none;
    }

    .file-field__button--clear {
      background-color: $color-purple-lightest;
      border-radius: 50%;
      bottom: -8px;
      cursor: pointer;
      display: none;
      float: right;
      height: 30px;
      left: 0;
      margin: 0;
      min-width: 20px;
      padding: 0;
      position: absolute;
      width: 30px;
      z-index: 999;

      .button__icon {
        display: table-cell;
        margin: 0;
        vertical-align: middle;
        width: auto;

        svg {
          fill: $color-purple-dark;
          height: 16px;
        }
      }

      &:hover:not(.button--disabled):not(:disabled),
      &:focus:not(.button--disabled):not(:disabled) {
        background-color: $color-purple-lightest;
      }
    }

    .file-field__button--add {
      background-color: $color-purple-dark;
      border-radius: 50%;
      bottom: -8px;
      cursor: pointer;
      display: table;
      float: right;
      height: 30px;
      margin: 0;
      min-width: 20px;
      padding: 0;
      position: absolute;
      right: 0;
      width: 30px;
      z-index: 999;

      svg {
        height: 14px;
        position: relative;
        top: 4px;
      }
    }

    .file-field__input {
      cursor: pointer;
      height: 75px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 75px;

      @include tablet {
        height: 80px;
        width: 80px;
      }
    }

    .file-field__input-add {
      bottom: 0;
      height: 30px;
      opacity: 0;
      position: absolute;
      right: 0;
      width: 30px;
      z-index: 999;
    }

    .file-field__preview-filename,
    .file-field__preview-meta,
    .file-field__button-remove {
      display: none;
    }
  }
}
