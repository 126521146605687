
.form-row {
  @include adaptive-margin(var(--grid-gutter));

  margin-left: calc(var(--grid-gutter) * -.5);
  margin-right: calc(var(--grid-gutter) * -.5);

  > div {
    margin: 0 calc(var(--grid-gutter) / 2);

    &.text-field--textarea {
      margin-top: 34px;
    }
  }

  &.form-row--footer {
    @include adaptive-margin(calc(var(--component-margin) / 2), calc(var(--component-margin) / 2));

    margin-left: 0;
    margin-right: 0;

    .button + .button {
      margin-left: 10px;
    }
  }

  @include mobile-only {
    > div + div {
      margin-top: var(--grid-gutter);
    }

    &.form-row--footer {
      .button + .button {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  @include notebook {
    display: flex;
    justify-content: flex-end;

    > div {
      flex: 1 1 50%;
    }
  }
}
