.dropdown-menu {
  --dropdown-menu-btn-height: 60px;

  color: $color-purple-dark;
  position: relative;

  .dropdown-menu__icon {
    height: var(--dropdown-menu-btn-height);
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 2px;
    transform: rotateX(180deg);
    transition: transform $easing-change;
    width: 20px;
  }

  .dropdown-menu__btn {
    align-items: center;
    border: 1px solid rgba($color-purple-dark, .5);
    border-radius: 2px;
    display: flex;
    height: var(--dropdown-menu-btn-height);
    justify-content: flex-start;
    padding: 15px 50px 15px 15px;
    width: 100%;

    &:focus,
    &:hover,
    &:active {
      border-color: $color-purple-dark;
      outline: 0;
    }
  }

  .dropdown-menu__items {
    border-radius: 2px;
    display: grid;
    gap: 1px;
    list-style: none;
    max-height: 200px;
    overflow: auto;
    padding: 0;

    .dropdown-menu__item-icon {
      display: inline-block;
      float: right;
      height: 20px;
      margin-top: 5px;
      right: 20px;
    }

    .dropdown-menu__item-size {
      color: $color-purple-light;
      display: block;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .dropdown-menu__items a {
    background-color: $color-purple-lightest;
    display: block;
    padding: 12px 15px;
    text-decoration: none;

    &:focus,
    &:hover {
      background-color: #bfb5c4;
      font-weight: 600;
    }
  }

  &.is-collapsed {
    .dropdown-menu__icon {
      transform: rotateX(0deg);
    }

    .dropdown-menu__items {
      display: none;
    }
  }
}

.dropdown-menu--pill {
  --dropdown-menu-btn-height: 24px;

  color: $color-purple-dark;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: .07em;
  line-height: 14px;
  //margin-bottom: 5px;
  //margin-right: 10px;
  text-transform: uppercase;

  .dropdown-menu__btn {
    background-color: $color-grey;
    border: 0;
    border-radius: 30px;
    display: inline-block;
    height: inherit;
    letter-spacing: var(--tag-letter-spacing);
    padding: 5px 30px 5px 10px;
    text-transform: uppercase;
  }

  .dropdown-menu__items {
    background-color: $color-white;
    border-radius: 2%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .25);
    font-weight: 500;
    left: 0;
    max-height: 300px;
    max-width: 300px;
    min-width: 200px;
    overflow-x: hidden;
    padding: 10px 20px;
    position: absolute;
    top: 34px;
    width: max-content;
    z-index: 20;

    a {
      background-color: inherit;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      border: none;
      color: $color-purple-dark;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: var(--tag-letter-spacing);
      margin: 11px 2px;
      padding: 2px 0;
      position: relative;
      text-transform: none;
      transition: background-size .2s linear;
      vertical-align: baseline;

      &:hover {
        background-color: transparent;
        background-size: 100% 1px;
        font-weight: 400;
      }
    }

    .dropdown-menu__item--reset .dropdown-menu__link {
      font-size: 10px;
      font-weight: 600;
      letter-spacing: var(--tag-letter-spacing);
      margin: 10px 2px 6px;
      text-transform: uppercase;

      &:hover {
        font-weight: 600;
      }
    }
  }

  .dropdown-menu__icon {
    right: 10px;
    width: 16px;
  }

  &.is-collapsed {
    .dropdown-menu__icon {
      top: 1px;
    }
  }

  &:hover,
  &.selected {
    color: $color-white;

    .dropdown-menu__btn {
      background: $color-purple-dark;
    }
  }
}

.dropdown-menu--light {
  .dropdown-menu__items a {
    background-color: $color-purple-lightest;

    &:focus,
    &:hover {
      background-color: $color-purple-hover;
      font-weight: 600;
    }
  }
}

.dropdown-menu--cao {
  display: inline-block;
  padding-right: 25px;
  width: 100%;

  @include tablet {
    width: auto;
  }

  @include notebook {
    display: block;
    padding-right: unset;
  }

  .dropdown-menu__btn {
    border-color: $color-purple-dark;
    height: 50px;
    width: calc(100% + 25px);

    @include notebook {
      width: 100%;
    }
  }

  .dropdown-menu__icon {
    height: 50px;
  }

  .dropdown-menu__items {
    background-color: $color-white;
    position: absolute;
    z-index: 1;
  }

  .dropdown-menu__items a {
    background-color: $color-purple-lightest;

    &:focus,
    &:hover {
      background-color: $color-purple-hover;
      font-weight: 600;
    }
  }
}

.dropdown-menu--value-chosen {
  .dropdown-menu__btn {
    border-color: rgba($color-purple-dark, .7);
    font-weight: 600;
  }
}

.section-wrapper--background-grey .dropdown-menu {
  color: $color-purple-dark;

  &.dropdown-menu--pill {
    .dropdown-menu__btn {
      background-color: $color-white;

      &:hover,
      &:active,
      &:focus {
        background-color: $color-purple;
        color: $color-white;
      }
    }

    &.dropdown-menu--value-chosen {
      color: $color-white;

      .dropdown-menu__btn {
        background-color: $color-purple-dark;
      }
    }
  }
}

.section-wrapper--background-purple,
.section-divider {
  .dropdown-menu {
    color: $color-white;

    .dropdown-menu__btn {
      border-color: $color-purple-lighter;

      &:hover,
      &:active,
      &:focus {
        border-color: $color-white;
      }
    }

    .dropdown-menu__items a {
      background-color: rgba($color-white, .2);
      color: $color-white;

      &:focus,
      &:hover,
      &:active {
        background-color: rgba($color-white, .4);
      }
    }

    &.dropdown-menu--value-chosen {
      .dropdown-menu__btn {
        border-color: $color-purple-lighter;
      }
    }
  }
}

.dropdown-menu-simple {
  position: relative;

  .dropdown-menu-simple__items {
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    color: $color-purple-dark;
    display: inline-block;
    min-width: 230px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 32px;
    width: auto;

    @include tablet {
      min-width: 200px;
    }

    .dropdown-menu-simple__item {
      background-color: $color-white;
      list-style: none;
      padding: 10px;

      .dropdown-menu-simple__button {
        font-size: 14px;
        top: -2px;
      }
    }
  }

  .dropdown-menu-simple__icon {
    &.dropdown-menu-simple__icon--purple {
      svg {
        fill: $color-purple-light2;
      }
    }

    &.dropdown-menu-simple__icon--white {
      svg {
        fill: $color-white;
      }
    }
  }
}

.dropdown-menu-simple--inline {
  display: inline-block;
  margin-left: 10px;

  svg {
    fill: $color-white;
    height: 10px;
    stroke: none;
    width: 20px;
  }

  .dropdown-menu-simple__icon--white {
    svg {
      fill: $color-white;
    }
  }
}

.dropdown-menu-simple.is-collapsed {
  .dropdown-menu-simple__items {
    display: none;
  }
}
