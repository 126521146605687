
.article-photo-carousel {
  overflow-x: hidden;
  padding: var(--component-margin) 0;
  position: relative;

  .article-photo-carousel__grid {
    @include grid-container;
  }

  .article-photo-carousel__navigation {
    display: flex;
    grid-column: var(--layout-content);
    margin-left: -10px;
  }

  .article-photo-carousel__wrapper {
    grid-column: var(--layout-photo-carousel);
  }

  .article-photo-carousel__content {
    position: relative;
    width: 100%;

    .swiper-container {
      overflow: visible;
      position: relative;

      &::before {
        background: $color-white;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        width: 50vw;
        z-index: 10;
      }
    }

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
      padding: 0 10px 0 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: none;
    bottom: 0;
    // override default
    box-sizing: content-box;
    display: inline-block;
    height: var(--swiper-nav-icon-size);
    left: 0;
    padding: 10px;
    position: relative;
    right: 0;
    top: 0;
    width: var(--swiper-nav-icon-size);
  }

  .swiper-button-prev {
    padding-right: 0;
  }

  .swiper-button-next {
    padding-left: 0;
  }

  .swiper-button-disabled {
    opacity: .4;
    pointer-events: none;
  }
}
