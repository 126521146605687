.event-date {
  .event-date__container {
    display: inline-flex;
    flex-direction: column;
    min-width: 48px;
  }

  .event-date__day {
    font-size: 40px;
    font-weight: 200;
    margin-bottom: 3px;
    text-align: center;
  }

  .event-date__month-year {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
