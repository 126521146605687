.footer-bar {
  background-color: var(--footer-page-background);
  position: relative;

  .footer-bar__container {
    background-color: $color-purple-dark;
    border-top-left-radius: var(--round-corner-size);
    color: rgba($color-white, .5);
    padding: var(--component-margin) 0;
  }

  .footer-bar__grid {
    @include grid-container;

    row-gap: var(--component-margin);

    @include notebook {
      row-gap: 20px;
    }
  }

  .footer-bar__logo {
    color: $color-white;
    display: flex;
    grid-column: 2 / -2;

    .logo {
      height: 25px;
      margin-right: 8px;
      width: 60px;
    }

    @include notebook {
      grid-column: 2 / 4;
    }

    @include desktop {
      grid-column: 3 / 5;
    }
  }

  .footer-bar__logo-site {
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
  }

  .footer-bar__logo--vakmensen {
    .logo {
      width: 130px;
    }
  }

  .footer-bar__logo--probeerdebond {
    .logo {
      width: 130px;
    }
  }

  .footer-bar__links {
    column-gap: var(--grid-gutter);
    display: grid;
    grid-column: 2 / -2;
    row-gap: 40px;

    @include notebook {
      grid-column: 6 / 12;
      grid-row: 1 / 3;
      grid-template-columns: repeat(3, 1fr);
    }

    @include desktop {
      grid-column: 7 / 13;
    }
  }

  .footer-bar__list-links {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 5px;

    .link {
      @include typography(paragraphy);

      @include notebook {
        @include typography(caption);
      }

      &:focus,
      &:hover {
        color: $color-white;
      }
    }
  }

  .footer-bar__social {
    display: flex;
    grid-column: 2 / -2;
    justify-content: center;
    list-style: none;
    padding: 0;

    @include notebook {
      grid-column: 2 / 4;
      justify-content: flex-start;
      margin-left: -10px;
    }

    @include desktop {
      grid-column: 3 / 5;
    }

    .icon-button {
      &:focus,
      &:hover {
        color: $color-white;
      }
    }
  }

  .footer-bar__social-item {
    @include mobile-only {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .footer-bar__copyright {
    font-size: 10px;
    grid-column: 2 / -2;
    margin-top: 20px;

    @include mobile-only {
      text-align: center;
    }

    @include desktop {
      grid-column: 3 / 5;
    }
  }
}

.footer-bar__question {
  align-items: center;
  display: none;
  flex-direction: column;
  grid-column: 2 / -2;
  justify-self: center;

  @include mobile-only {
    margin-top: 20px;

    .button {
      min-width: 200px;
    }
  }

  @include notebook {
    grid-column: 12 / 14;
    grid-row: 1 / 3;
  }

  @include desktop {
    grid-column: 13 / 15;
  }
}

.footer-bar__question-info-text {
  font-size: 10px;
}

.footer-bar--padding-top {
  padding-top: var(--component-margin);
}

.footer-bar--page-background-purple {
  --footer-page-background: #{$color-purple};
}

.footer-bar--page-background-purple-dark {
  --footer-page-background: #{$color-purple-dark};
}

.footer-bar--page-background-grey {
  --footer-page-background: #{$color-grey};
}

.footer-bar--straight {
  .footer-bar__container {
    border-top-left-radius: 0;
    bottom: 0;
  }
}
