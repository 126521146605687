.group-access {
  margin-top: 78px;

  &__title {
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 12px;
    margin-top: 12px;
  }
}
