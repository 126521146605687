
:root {
  --link-small-font-size: 13px;
  --link-small-font-stack: #{$graphik-font-stack};
  --link-small-font-style: normal;
  --link-small-font-variant: normal;
  --link-small-font-weight: #{get-graphik-weight(semibold)};
  --link-small-letter-spacing: 0px;
  --link-small-line-height: 1.2em;
  --link-small-margin-bottom: 0;
  --link-small-margin-top: 0;
  --link-small-word-spacing: normal;
}
