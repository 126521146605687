
.article-content {
  padding: var(--component-margin) 0;
  position: relative;

  .article-content__grid {
    @include grid-container;

    row-gap: 25px;

    @include notebook {
      row-gap: 40px;
    }
  }

  .article-content__heading {
    @include typography(heading-1);

    grid-column: var(--layout-content-large);
  }

  .article-content__richtext {
    grid-column: var(--layout-content);
  }

  .article-content__links {
    display: grid;
    gap: 8px;
    grid-column: var(--layout-content-large);
  }
}

.article-content--extralarge {
  .article-content__heading {
    grid-column: var(--layout-content-extralarge);
  }

  .article-content__richtext {
    grid-column: var(--layout-content-extralarge);
  }

  .article-content__links {
    grid-column: var(--layout-content-extralarge);
  }
}

.article-content--container {
  align-items: start;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), [column] minmax(0, 1fr));
  max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
  padding-left: calc(var(--grid-gutter) + var(--grid-margin));
  padding-right: calc(var(--grid-gutter) + var(--grid-margin));

  .article-content__grid {
    display: block;
    grid-column: var(--layout-grid-inline);
    margin-bottom: calc(var(--component-margin) * 2);
  }

  &:first-child {
    padding-bottom: 0 !important;

    .article-content__grid {
      margin-bottom: calc(var(--component-margin));
    }
  }
}

.article-content--two-columns {
  .article-content__card {
    grid-column: 2 / 13;
  }

  .article-content__listitem {
    grid-column: 2 / 13;
  }

  .article-content__button {
    margin-top: 25px;
  }

  .article-content__button-text-below {
    margin-top: 10px;

    @include mobile-only {
      margin-bottom: 20px;
    }
  }

  .article-content__poll {
    grid-column: 2 / 14;

    .poll {
      margin: 0 auto;
    }
  }

  @include notebook {
    .article-content__grid {
      grid-auto-flow: dense;
    }

    .article-content__richtext {
      grid-column: 4 / 8;
    }

    .article-content__links {
      grid-column: 3 / 9;
    }

    .article-content__card {
      grid-column: 9 / 13;
    }

    .article-content__listitem {
      grid-column: 9 / 13;
    }

    .article-content__poll {
      grid-column: 9 / 13;
    }
  }

  @include desktop {
    .article-content__richtext {
      grid-column: 5 / 9;
    }

    .article-content__links {
      grid-column: 4 / 10;
    }

    .article-content__card {
      grid-column: 10 / 14;
    }

    .article-content__listitem {
      grid-column: 10 / 14;
    }

    .article-content__poll {
      grid-column: 10 / 14;
    }
  }
}

.article-content--wide {
  .article-content__richtext {
    grid-column: var(--layout-content-large);
  }
}

.article-content--extra-content {
  margin-bottom: 0;
  margin-top: -10px;
  padding-bottom: 0;
  padding-top: 0;

  @include tablet {
    margin-top: 0;
    padding-bottom: 5%;
  }

  .article-content__grid {
    align-items: start;
    display: grid;
    grid-gap: 0 var(--grid-gutter);
    grid-template-columns: repeat(var(--grid-columns), [column] minmax(0, 1fr));
    margin-top: 0;
    max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
    padding-left: calc(var(--grid-gutter) + var(--grid-margin));
    padding-right: calc(var(--grid-gutter) + var(--grid-margin));
  }

  .article-content__richtext {
    background-color: #fff;
    grid-auto-rows: min-content;
    grid-column: var(--layout-grid-inline);
    grid-column: var(--layout-content-aside);
    margin-bottom: 10px;
    padding: 10px 2rem;

    @include tablet {
      padding: 50px 5rem 10px;
    }
  }

  .article-content__links {
    background-color: #fff;
    grid-auto-rows: min-content;
    grid-column: var(--layout-grid-inline);
    grid-column: var(--layout-content-aside);
    margin-bottom: calc(var(--component-margin) * 2);
    margin-bottom: 0;
    margin-top: -10px;
    padding: 10px 2rem 20px;

    @include tablet {
      margin-bottom: 10px;
      padding: 10px 2rem 50px;
      padding-left: 5rem;
      padding-right: 5rem;
    }

    a {
      @include typography(paragraphy);
    }
  }
}
