.search-autocomplete {
  position: relative;

  template {
    display: none;
  }

  &.search-autocomplete--active {
    .search-autocomplete__item-container {
      opacity: 1;
    }
  }

  .search-autocomplete__input-container {
    border: 1px solid #9b8aa3;
    border-radius: 2px;
    height: 60px;
    position: relative;

    input {
      height: 100%;
      outline: none;
      padding: 15px 50px;
      width: 100%;
    }
  }

  .search-autocomplete__icon-search {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding-left: 20px;
    padding-right: 0;
    position: absolute;
    top: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  .search-autocomplete__submit {
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .search-autocomplete__item-container {
    box-shadow: 0 2px 10px 0 $color-black-opaque;
    left: 0;
    opacity: 0;
    padding-top: 60px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 100%;
    z-index: 5;
  }

  .search-autocomplete__item {
    background: $color-white;
    color: currentColor;
    display: block;
    font-family: $graphik-font-stack;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    letter-spacing: -.2666666805744171px; // Why is this so specific?
    line-height: 22.4px;
    padding: 15px 10px;
    pointer-events: all;
    text-transform: capitalize;

    strong {
      font-style: normal;
      font-weight: 600;
    }

    &:hover {
      background: $color-grey;
      color: currentColor;
    }
  }
}
