.breadcrumbs {
  .breadcrumbs__link {
    @include typography(caption);

    color: $color-purple-dark;

    &:last-child {
      .link__icon {
        display: none;
      }
    }
  }

  &__white {
    .breadcrumbs__link {
      color: $color-white;
    }
  }

  &__image {
    padding-top: calc(var(--component-margin) / 1.5);
    position: absolute;
    z-index: 2;

    .breadcrumbs__links {
      display: inline-block;
      padding: 7px 20px 15px;
    }

    .breadcrumbs__link {
      color: $color-white;
    }

    &::before {
      background: linear-gradient(to bottom, rgba(0, 0, 0, .6), rgba(0, 0, 0, 0));
      content: '';
      height: 200%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__purple {
    padding-top: calc(var(--component-margin) / 1.5);
    position: absolute;
    z-index: 2;

    .breadcrumbs__links {
      display: inline-block;
      padding: 7px 20px 15px;
    }

    .breadcrumbs__link {
      color: $color-white;
    }
  }
}
