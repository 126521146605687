
.logo {
  color: inherit;
  display: inline-block;
  line-height: 0;
  position: relative;
  transition: color $easing-change;
  user-select: none;
  vertical-align: top;

  .logo__svg {
    display: block;
    height: 100%;

    path {
      fill: currentColor;
      transition: fill $easing-change;
    }
  }
}

.logo--menu {
  align-items: center;
  background: $color-white;
  border-radius: 0 0 11px;
  color: $color-purple;
  display: flex;
  justify-content: center;

  &:focus,
  &:hover {
    svg {
      opacity: .7;
    }
  }

  .logo__svg {
    width: 62px;

    &::before {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSI2MiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDExNjMgNDg0IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pZCBtZWV0Ij48c2NyaXB0IHhtbG5zPSIiLz48c2NyaXB0IHhtbG5zPSIiLz4KICA8dGl0bGU+Q05WPC90aXRsZT4KCiAgPGcgZmlsbD0iIzY4MzI4MiI+CiAgICA8cGF0aCBkPSJNNzUyLjExIDM1NS42NGwyNjIuMS0zNTIgMTQ1LjE4IDc0LjA4LTM5MC40NCAzOTIuNkg2MDAuN2wuNDktMi41UzYzOSAyNzUuMjcgNjQ5LjEgMjIyLjY3YzQuODktMjUuMzYgMTAuMDUtNDcuNTUgOC40Ny02My40Mi0uNzQtNy40MS0yLjk2LTEzLjM4LTcuNjQtMTcuNDctNC43NS00LjE1LTExLjk1LTYuMzItMjIuMzUtNi4zMy0xNC4xOS0uMDItMzAuMDMgOC4yOC00Ni4wNSAyMy4zOS0yNC41NyAyMy4xOC00OS4zNiA2Mi4zNy02OC44OCAxMTMuMi0yMS45OSA1Ny4zNC00Ny45OCAxOTYuNTgtNDcuOTggMTk2LjU4bC0uMzIgMS43SDMyOC42N2w2My40NS0zMjJjLTM3LjUxLTkuMzMtNzAuMjctMTguMTUtMTExLjYyLTE3LjgzLTY0Ljg2IDAtMTQzLjIyIDUwLjEtMTQzLjIyIDE2Mi44IDAgMjcgOC40NyA1MC42NCAyNS44MyA2NS4yIDExLjUzIDkuNjggMjYuOTcgMTUuMzEgNDYuMzcgMTUuMzEgMzUuNzcgMCA1Ny4yOS02LjkgOTUuNi0yMy45N2wyLjg1IDIuNDQtMjkuOTggMTEyLjY2LTEuMDQuMzdjLTI4Ljg0IDEwLjI0LTY0LjQ4IDE4LjA0LTEwMC45NSAxOC4wNC01Ni43NCAwLTEwMC43Mi0yMC40OC0xMzAuNTQtNTQuNThDMTUuNjYgMzk0LjcgMCAzNDcuMDMgMCAyOTIuNDMgMCAxMzYuMjkgMTMyLjQ3IDMzLjYgMjgwLjY1IDMzLjZjNTUuNjMgMCAxMzEuMTkgMTQuMjEgMTc4LjA3IDExLjc1YTk1MjMuMSA5NTIzLjEgMCAwMDYwLjA0LTMuMjlsMS43NSAzLjM5Yy01My41NiA2Ni44LTg5LjIzIDEzNS4zNS0xMjAuMTQgMjM0LjI2IDM1Ljc5LTY1LjkxIDczLjQ5LTEyNS4wMiAxMTcuNDQtMTY4LjggNDguMy00OC4xMSAxMDQuMTYtNzcuNzQgMTczLjI3LTc3Ljc0IDM4LjA1IDAgNjYuOSAxMi4zMyA4NC40NyAzNC4xMSAxNy41NSAyMS43NyAyMy45IDUzLjA4IDE2LjY3IDkxLjNhNzExNS4xIDcxMTUuMSAwIDAxLTQwLjExIDE5Ny4wNnoiLz4KICA8L2c+Cjwvc3ZnPg==');
      float: left;
      height: 25px;
      position: relative;
      top: 7px;
      width: 62px;
    }
  }
}

.logo--probeerdebond {
  align-items: center;
  border-radius: 0 0 11px;
  color: $color-purple;
  display: flex;
  justify-content: center;

  &:focus,
  &:hover {
    svg {
      opacity: .7;
    }
  }

  .logo__svg {
    width: 62px;

    &::before {
      content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSI2MiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDExNjMgNDg0IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pZCBtZWV0Ij48c2NyaXB0IHhtbG5zPSIiLz48c2NyaXB0IHhtbG5zPSIiLz4KICA8dGl0bGU+Q05WPC90aXRsZT4KCiAgPGcgZmlsbD0iIzY4MzI4MiI+CiAgICA8cGF0aCBkPSJNNzUyLjExIDM1NS42NGwyNjIuMS0zNTIgMTQ1LjE4IDc0LjA4LTM5MC40NCAzOTIuNkg2MDAuN2wuNDktMi41UzYzOSAyNzUuMjcgNjQ5LjEgMjIyLjY3YzQuODktMjUuMzYgMTAuMDUtNDcuNTUgOC40Ny02My40Mi0uNzQtNy40MS0yLjk2LTEzLjM4LTcuNjQtMTcuNDctNC43NS00LjE1LTExLjk1LTYuMzItMjIuMzUtNi4zMy0xNC4xOS0uMDItMzAuMDMgOC4yOC00Ni4wNSAyMy4zOS0yNC41NyAyMy4xOC00OS4zNiA2Mi4zNy02OC44OCAxMTMuMi0yMS45OSA1Ny4zNC00Ny45OCAxOTYuNTgtNDcuOTggMTk2LjU4bC0uMzIgMS43SDMyOC42N2w2My40NS0zMjJjLTM3LjUxLTkuMzMtNzAuMjctMTguMTUtMTExLjYyLTE3LjgzLTY0Ljg2IDAtMTQzLjIyIDUwLjEtMTQzLjIyIDE2Mi44IDAgMjcgOC40NyA1MC42NCAyNS44MyA2NS4yIDExLjUzIDkuNjggMjYuOTcgMTUuMzEgNDYuMzcgMTUuMzEgMzUuNzcgMCA1Ny4yOS02LjkgOTUuNi0yMy45N2wyLjg1IDIuNDQtMjkuOTggMTEyLjY2LTEuMDQuMzdjLTI4Ljg0IDEwLjI0LTY0LjQ4IDE4LjA0LTEwMC45NSAxOC4wNC01Ni43NCAwLTEwMC43Mi0yMC40OC0xMzAuNTQtNTQuNThDMTUuNjYgMzk0LjcgMCAzNDcuMDMgMCAyOTIuNDMgMCAxMzYuMjkgMTMyLjQ3IDMzLjYgMjgwLjY1IDMzLjZjNTUuNjMgMCAxMzEuMTkgMTQuMjEgMTc4LjA3IDExLjc1YTk1MjMuMSA5NTIzLjEgMCAwMDYwLjA0LTMuMjlsMS43NSAzLjM5Yy01My41NiA2Ni44LTg5LjIzIDEzNS4zNS0xMjAuMTQgMjM0LjI2IDM1Ljc5LTY1LjkxIDczLjQ5LTEyNS4wMiAxMTcuNDQtMTY4LjggNDguMy00OC4xMSAxMDQuMTYtNzcuNzQgMTczLjI3LTc3Ljc0IDM4LjA1IDAgNjYuOSAxMi4zMyA4NC40NyAzNC4xMSAxNy41NSAyMS43NyAyMy45IDUzLjA4IDE2LjY3IDkxLjNhNzExNS4xIDcxMTUuMSAwIDAxLTQwLjExIDE5Ny4wNnoiLz4KICA8L2c+Cjwvc3ZnPg==');
      float: left;
      height: 25px;
      position: relative;
      top: 7px;
      width: 62px;
    }
  }

  .logo__probeerdebond {
    background-color: transparent;
    margin-top: 8px;
    max-width: 190px;
    width: 100%;

    @include mobile-only {
      max-width: 165px;
    }

    &::before {
      float: left;
      height: 25px;
      position: relative;
      top: 10px;
      width: 62px;
    }
  }
}

.logo--menu-specified {
  @include notebook {
    background: $color-white;
    border-radius: 0;
    padding-right: 15px;
  }
}
