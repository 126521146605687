
.card {
  --card-padding: 15px;

  background-color: $color-grey;
  border-radius: 10px;
  color: $color-purple-dark;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  position: relative;
  transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .section-wrapper--background-grey &,
  .section-wrapper--background-purple {
    background-color: $color-white;
  }

  &::before {
    content: '';
    padding-bottom: 58%;// <- waarom is dit?
  }

  &:hover,
  &:focus {
    .card__title span {
      background-size: 100% 1px;
    }
  }

  .card__image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -99;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 75%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 90;
    }

    .picture {
      height: 100%;
      width: 100%;
      z-index: -90;
    }
  }

  .card__content {
    padding: 20px;
    position: relative;
    z-index: 99;
  }

  .card__event-date,
  .card__tag {
    left: var(--card-padding);
    position: absolute;
    top: var(--card-padding);
  }

  .card__tag-container {
    display: block;
    min-width: 100%;
    padding: var(--card-padding);
    position: absolute;

    .card__tag {
      display: inline-block;
      left: 0;
      margin-bottom: 5px;
      margin-right: 5px;
      position: relative;
      top: 0;

      .tag {
        margin-right: 0;
      }
    }
  }

  .card__tag-info {
    position: absolute;
    right: var(--card-padding);
    top: var(--card-padding);

    .tag {
      font-weight: normal;
    }
  }

  .card__tag-info-icon {
    display: inline-block;
    height: 10px;
    width: 12px;

    svg {
      stroke: transparent;
    }
  }

  .card__tag-info-text {
    display: inline-block;
    margin-top: -6px;
    vertical-align: middle;
  }

  .card__date {
    @include typography(caption);

    color: $color-purple-light;
  }

  .card__title {
    @include typography(heading-4);

    word-break: break-word;

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }

  .card__excerpt {
    @include typography(paragraph);

    margin-top: 12px;
  }
}

.card--image {
  color: $color-white;

  .card__tag .tag {
    background-color: $color-white;
    border: 1px solid transparent;
    color: $color-purple-dark;
  }

  .card__tag-info .tag {
    background-color: $color-white;
    border: 1px solid transparent;
    color: $color-purple-dark;
    font-weight: normal;
  }

  .card__date {
    color: inherit;
  }
}

.card--tag {
  .card__content {
    margin-top: 40px;
  }
}

.card--event-date {
  .card__content {
    margin-top: 65px;
  }
}

.card--size-small {
  &::before {
    content: '';
    padding-bottom: 44%;
  }
}

.card--size-portrait {
  &::before {
    content: '';
    padding-bottom: 400px;
  }

  @include desktop {
    &::before {
      content: '';
      padding-bottom: 150%;
    }
  }
}

.card--alignment-bottom {
  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.card--title-small {
  .card__title {
    @include typography(heading-5);
  }
}

.card--plain-small {
  height: 200px;

  .card__title {
    @include typography(heading-5);
  }
}

.card--plain-medium {
  height: 140px;

  .card__title {
    font: 700 18px / 24px $graphik-font-stack;
    letter-spacing: -.31px;
  }
}

.card--plain {
  background-color: $color-purple;
  padding: 20px;
  position: relative;

  .card--plain__content {
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .card__excerpt {
    margin-bottom: 10px;
  }

  .card--plain__link {
    margin-top: 15px;
  }
}

.card--listitem {
  &::before {
    content: none;
    padding-bottom: 0;
  }

  background: none;
  border-radius: 0;

  .card__content {
    padding: 0;
  }

  .card__date {
    color: $color-purple-light;
    font: 400 14px / 22.4px $graphik-font-stack;
    letter-spacing: -.27px;
    margin-bottom: 6px;
  }

  .card__title {
    color: $color-purple-dark;
    font: 700 16px / 20px $graphik-font-stack;
    letter-spacing: 0;
  }

  .card__excerpt {
    font: 400 14px / 22.4px $graphik-font-stack;
    letter-spacing: -.27px;
  }
}
