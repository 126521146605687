:root {
  --tag-font-size: 10px;
  --tag-font-stack: #{$graphik-font-stack};
  --tag-font-style: normal;
  --tag-font-variant: normal;
  --tag-font-weight: #{get-graphik-weight(semibold)};
  --tag-letter-spacing: .3px;
  --tag-line-height: 1;
  --tag-margin-bottom: 1rem;
  --tag-margin-top: 1rem;
  --tag-word-spacing: normal;
  --tag-text-transform: uppercase;
}
