
:root {
  --heading-1-font-size: #{get-fluid-value(28px, 30px)};
  --heading-1-font-stack: #{$graphik-font-stack};
  --heading-1-font-style: normal;
  --heading-1-font-variant: normal;
  --heading-1-font-weight: #{get-graphik-weight(bold)};
  --heading-1-letter-spacing: -.5px;
  --heading-1-line-height: 1.15em;
  --heading-1-margin-bottom: 10px;
  --heading-1-margin-top: 30px;
  --heading-1-word-spacing: normal;

  @include notebook {
    --heading-1-font-size: #{get-fluid-value(30px, 40px)};
    --heading-1-letter-spacing: -1px;
    --heading-1-margin-bottom: 15px;
    --heading-1-margin-top: 40px;
  }

  @include desktop {
    --heading-1-font-size: #{get-fluid-value(40px, 55px)};
    --heading-1-letter-spacing: -1.13px;
    --heading-1-margin-bottom: 60px;
  }
}
