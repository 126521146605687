@mixin event-card--minimal {
  .event-card--minimal {
    .event-card__title {
      @include typography(heading-6);

      margin-bottom: .125rem;
    }

    .event-card__excerpt {
      @include typography(paragraph);

      margin-bottom: var(--grid-gutter);
    }

    margin-bottom: 1.5rem;
  }
}

@include event-card--minimal;
