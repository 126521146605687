.article-past-events {
  padding: var(--component-margin) 0;

  .article-past-events__grid {
    @include grid-container;
  }

  .article-past-events__wrapper {
    grid-column: var(--layout-content);
  }

  .article-past-events__title {
    @include typography(heading-3, false, true);
  }

  .article-past-events__introduction {
    @include typography(paragraph);

    margin-bottom: var(--component-margin);
  }
}
