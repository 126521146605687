.review-card {
  background: transparent;
  color: $color-purple-light;
  display: flex;
  gap: 20px;
  grid-template-columns: auto 1fr;
  padding: 30px;

  .review-card__image {
    border-radius: 50%;
    flex: 0 0 auto;
    height: 82px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
    width: 82px;

    @include notebook {
      grid-row: 1 / 3;
      height: 120px;
      width: 120px;
    }

    @include desktop {
      height: 150px;
      width: 150px;
    }
  }

  .review-card__name {
    @include typography(caption, false, false);

    color: $color-purple-dark;

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }

  .review-card__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .review-card__rating {
    height: 20px;
    margin-bottom: 15px;
    width: 100px;
  }

  .review-card__rating-inner {
    height: 20px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;

    svg {
      height: 20px;
      stroke: transparent;
      width: 100px;
    }
  }

  .review-card__text {
    @include typography(paragraph, false, false);

    color: $color-purple-dark;
    flex: 0 1 auto;
    padding-bottom: 15px;
  }
}
