.article-checkmark-list {
  padding: var(--component-margin) 0;
  position: relative;

  .article-checkmark-list__grid {
    @include grid-container;
  }

  .article-checkmark-list__wrapper {
    grid-column: var(--layout-content);
  }

  .article-checkmark-list__title {
    @include typography(heading-3, false, true);
  }

  .article-checkmark-list__items {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 10px;
  }

  .article-checkmark-list__item {
    align-items: baseline;
    display: grid;
    grid-template-columns: var(--paragraph-line-height) auto;

    svg {
      height: var(--paragraph-line-height);
      margin-left: -5px;
      width: var(--paragraph-line-height);
    }
  }
}
