@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin normal {
  @include mobile-only {
    background: $color-white;

    .menu-link:not(:last-child) {
      border: none;
    }

    .menu-panel__link-category {
      padding: 10px 0;

      .menu-panel__link-category-title {
        line-height: 38px;
      }
    }

    .menu-panel__link-category:nth-child(even) {
      background: $color-grey-lighter;
      padding: 35px 0;
    }

    .link {
      .link__menu-wrapper {
        color: currentColor;
      }
    }
  }
}

@mixin big-links {
  @include mobile-only {
    background: $color-purple;

    .link {
      display: flex;
      outline-offset: -2px;
      width: 100%;

      .link__link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .link__span {
        background: none;
        flex-grow: 1;
      }

      .link__menu-wrapper {
        align-items: center;
        color: $color-white;
        display: flex;
        height: 60px;
        width: 100%;
      }

      .link__icon--after {
        height: 20px;
        margin-right: 6px;
        width: 20px;
      }
    }
  }
}

.menu-panel {
  background: $color-white;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  height: fit-content;
  position: fixed;
  top: 55px;
  width: 100%;

  @include mobile-only {
    bottom: 0;
    height: unset;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .menu-panel__links {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;

    @include mobile-only {
      order: 2;
    }
  }

  .menu-panel__link-group {
    display: flex;
    flex-direction: column;

    .menu-panel__link-group__groups-cta {
      background-color: $color-purple-lightest2;
      color: $color-purple-light;
      font-size: 12px;
      margin: 35px 13px 0;
      padding: 15px;
      width: 250px;

      .groups-cta {
        background-color: $color-purple-lightest;
        color: $color-purple-darker;
        font-size: 13px;
        font-weight: 600;
        margin-top: 15px;
        padding: 10px 12px;

        .button__icon {
          padding-bottom: 0;
        }
      }

      @include tablet-only {
        width: calc(100% - 26px); // Subtract by twice the margin left
      }
    }
  }

  .menu-panel__cta {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    height: 100%;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;

    @include notebook {
      flex-direction: row;
      padding-left: 0;
      padding-right: 0;
    }

    .menu-panel__cta-image {
      height: fit-content;
      max-height: 450px;
      max-width: 250px;
      min-height: 450px;
      min-width: 250px;
      position: relative;

      .picture {
        max-height: 450px;
        max-width: 250px;
        min-height: 450px;
        min-width: 250px;
      }

      .picture__image {
        border-radius: 20px;
      }
    }

    .menu-panel__cta-image-overlay {
      background: rgba(0, 0, 0, .2);
      border-radius: 20px;
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 90;
    }

    .menu-panel__cta-text {
      color: $color-white;
      height: 100%;
      hyphens: auto;
      left: 0;
      padding: 20px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 100;

      @include typography(heading-3);

      @include desktop {
        font-size: 24px;
      }
    }
  }

  .menu-panel__static {
    display: flex;
    justify-content: space-between;

    @include mobile-only {
      order: 1;
    }

    .menu-panel-profile--container {
      display: none;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;

      @include notebook {
        display: block;
      }
    }

    .menu-panel-search {
      @include mobile-only {
        height: 32px;
        padding: 0;
        position: fixed;
        right: 54px;
        top: 14px;
        width: 32px;
      }
    }

    .menu-link {
      border-bottom: 0;
      margin-left: 20px;
    }
  }

  .menu-panel__call-to-action {
    align-items: center;
    display: flex;
    height: 60px;
    margin-right: 10px;
    padding-left: 13px;

    @include mobile-only {
      bottom: 0;
      left: 0;
      margin-right: 0;
      // position: absolute;
      order: 3;
      padding-right: 25px;
    }
  }

  .menu-panel__return {
    .menu-link {
      border-bottom: 0;
    }
  }

  .menu-panel__link-group-title {
    @include typography(introduction);

    font-weight: 700;
    padding: 20px 13px;
  }

  .menu-panel__link-category-title {
    @include typography(heading-3);

    @include notebook {
      @include typography(heading-4);
    }

    font-weight: 700;
    margin-bottom: 20px;
    padding: 0 25px;

    @include notebook {
      padding: 0 10px;
    }
  }

  @include notebook {
    background: $color-white;
    display: flex;
    flex-direction: column;
    height: auto;
    left: 0px;
    position: absolute;
    top: 60px;
    width: auto;

    .menu-panel__return {
      display: none;
    }

    .menu-panel__link-group-title {
      @include typography(heading-5);

      margin-bottom: 25px;
      padding: 0 10px;
    }
  }
}

.menu-panel--inactive {
  display: none;
}

.menu-panel--slide-down {
  animation: 250ms ease-out 0s 1 normal running slide-down;
}

.menu-panel--slide-up {
  animation: 250ms ease-out 0s 1 reverse running slide-down;
}

.menu-panel--menu-bar {
  @include big-links;

  @include notebook {
    background: transparent;
    display: flex;
    flex-direction: row;
    opacity: 1;
    pointer-events: all;
    position: static;
    width: 100%;

    > .menu-panel__links {
      flex-direction: row;
      flex-grow: 1;

      > .menu-panel__link-group {
        flex-direction: row;
      }
    }

    > .menu-panel__static {
      align-self: flex-end;
      padding-right: 80px;
    }
  }
}

.menu-panel.menu-panel-v2 {
  .link.menu-panel--logout,
  .language-switch {
    align-items: center;
    color: $color-white;
    height: 60px;
    padding: 0 25px;
  }

  .menu-link--active .menu-bar__profile-panel {
    display: none;
  }

  .menu-panel__link-group {
    padding-bottom: 15px;

    @include notebook {
      padding-bottom: 0;
    }
  }

  .menu-bar__profile-panel {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    padding-left: 25px;

    @include notebook {
      display: none;
    }
  }

  .menu-panel__secondary-links {
    background-color: $color-purple-dark;
    flex: 1 1 100%;
    padding-top: 15px;

    .link__link {
      font-size: 14px;
    }

    @include notebook {
      display: none;
      padding-top: 0;
    }
  }

  .menu-panel__call-to-action {
    padding: 0 25px;

    @include notebook {
      padding: 0;
    }

    .button {
      max-width: 100%;
      width: 100%;
    }
  }

  @include notebook {
    > .menu-panel__static {
      padding-right: 25px;
    }
  }
}

.menu-panel--cluster {
  @include big-links;

  .link__span {
    font-size: 16px;
    line-height: 38px;

    @include notebook {
      line-height: 28px;
    }
  }

  .link__menu-wrapper {
    line-height: 38px;

    @include notebook {
      padding: 5px 10px;
    }
  }

  background: $color-purple;
  border-radius: 0 0 11px 11px;

  @include mobile-only {
    .menu-panel__return {
      margin-bottom: 20px;
    }

    .link__icon--before {
      height: 20px;
      margin-right: 12px;
      width: 20px;
    }
  }

  @include notebook {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .15);
    min-height: 400px;
    width: 216px + 30px;  // 30px cheating for border roundness of next panel

    > .menu-panel__links {
      height: 100%;
      padding: 15px 0;
      position: relative;
    }

    .menu-panel__links {
      height: 100%;
    }

    .menu-panel__link-group {
      display: flex;
      flex-flow: column wrap;
      height: 90%;
      width: 100%;
    }

    .menu-panel__link-group-title {
      font-size: 18px;
      line-height: 33px;
      margin-bottom: 0;

      &:nth-of-type(1) ~ .menu-panel__link-group-title {
        margin-top: 16px;
      }
    }

    .link__menu-wrapper {
      @include typography(paragraph);
    }

    .link--link {
      &:hover {
        .link__span {
          text-decoration: underline;
        }
      }

      .link__span {
        display: inline-block;
      }
    }

    .link__span {
      line-height: initial;
    }

    .link__icon--after {
      top: 4px;
    }
  }
}

.menu-panel--sector {
  @include normal;

  .menu-panel__link-category:last-child {
    background-color: $color-grey;
  }

  .menu-panel__link-category:first-child {
    background-color: $color-white;
  }

  .menu-panel__link-group-title {
    padding: 20px 25px 0;
  }

  @include notebook {
    border-radius: 0 0 11px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .15);
    height: 100%;
    left: 16px; // stick to parent
    min-height: fit-content;
    min-width: 350px;
    overflow: auto;
    position: absolute;
    top: 0;
    transform: translateX(230px);

    > .menu-panel__links {
      display: flex;
      flex-direction: row;

      > .menu-panel__link-category {
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 330px;
        min-width: 330px;
        padding: 30px 30px 20px 45px;
      }

      > .menu-panel__link-category:first-child {
        max-width: 550px;
        min-width: 550px;
      }

      .menu-panel__link-group-title {
        padding: 0 10px 10px;
      }

      .link__span {
        line-height: initial;
      }
    }
  }
}

.menu-panel--popup,
.menu-panel--advice {
  @include normal;

  .menu-panel__link-category:last-child {
    background-color: $color-grey;
  }

  .menu-panel__link-category:first-child {
    background-color: $color-white;
  }

  @include notebook {
    .link__menu-wrapper {
      padding: 5px 10px;
    }
  }

  .menu-panel__link-group-title {
    padding: 20px 13px 2px;
  }

  @include notebook {
    border-radius: 0 0 11px 11px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

    > .menu-panel__links {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;

      .menu-panel__link-group {
        display: flex;
        flex-flow: column nowrap;
        height: 90%;
        width: 100%;
      }

      .menu-panel__link-group-title {
        line-height: 33px;
        margin-bottom: 0;
        padding: 0 10px;

        &:nth-of-type(1) ~ .menu-panel__link-group-title {
          margin-top: 16px;
        }
      }

      > .menu-panel__link-category {
        flex-grow: 1;

        &:first-child {
          border-radius: 0 0 0 11px;
          max-width: 550px;
          min-width: 550px;
          padding: 30px 20px 20px;
        }

        &:last-child {
          background: $color-grey-lighter;
          border-radius: 0 0 11px;
          max-width: 550px;
          min-width: 550px;
          padding: 30px 20px 20px;
        }

        .link__span {
          line-height: initial;
        }

        .link__icon--after {
          top: 4px;
        }
      }
    }
  }
}

.menu-panel-search,
.menu-panel-profile {
  align-items: center;
  background-color: $color-purple;
  display: block;
  gap: 2px;
  padding: $default-spacer;

  @include notebook {
    display: flex;
    height: 60px;
    margin-bottom: 0;
  }

  .menu-panel-search__icon,
  .menu-panel-profile__icon {
    background: rgba(255, 255, 255, .15);
    border-radius: 50%;
    display: block;
    height: 32px;
    text-align: center;
    width: 32px;

    @include mobile-only {
      background: none;
      display: inline-block;
      height: auto;
    }

    &:hover {
      background: rgba(255, 255, 255, .3);

      @include mobile-only {
        background: rgba(255, 255, 255, 0);
      }
    }

    svg {
      width: 12px;
    }
  }

  .menu-panel-search__icon {
    svg {
      width: .9rem;

      @include mobile-only {
        width: 1rem;
      }
    }
  }

  .menu-panel-profile__icon {
    svg {
      fill: none;

      path,
      circle {
        stroke: $color-white;
        stroke-dasharray: 0;
        stroke-linecap: butt;
        stroke-width: 1;
      }
    }
  }

  .menu-panel-profile__name {
    color: $color-white;
    display: block;
    float: left;
    font-weight: bold;
    margin-left: 10px;
    width: 75%;

    @include notebook {
      display: none;
    }
  }

  .menu-panel-profile__link {
    color: $color-white;
    display: none;
    float: left;
    margin-left: 10px;
    width: 75%;

    @include mobile-only {
      display: block;
    }

    svg {
      position: relative;
      top: 5px;
      width: 20px;
    }
  }
}

.menu-bar--logged-in {
  .menu-bar__profile-panel {
    @include mobile-only {
      padding: 18px 0 20px 75px;
    }
  }

  .menu-panel-login-dropdown {
    @include mobile-only {
      height: calc(100vh - 56px);
      top: -84px;
    }
  }

  .menu-panel-login-dropdown__list-item--menu-bar--logged-in {
    display: block;
  }

  .menu-panel-login-dropdown__list-item--menu-bar--logged-out {
    display: none;
  }
}

.menu-bar--logged-out {
  .menu-panel-login-dropdown {
    .menu-panel-login-dropdown__top {
      display: none;
    }
  }

  .menu-panel-login-dropdown::before {
    right: 85px;
  }

  .menu-panel-login-dropdown__list-item--menu-bar--logged-in {
    display: none;
  }

  .menu-panel-login-dropdown__list-item--menu-bar--logged-out {
    display: block;
  }

  .menu-panel-profile {
    @include mobile-only {
      padding: 1.3rem .9rem 1.2rem;
    }

    .menu-panel-profile__icon {
      background: none;
    }

    .menu-panel-profile__title {
      display: inline-block;
      padding-right: 5px;
    }
  }
}

.menu-panel-profile--container {
  position: relative;

  .menu-panel-profile__title {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    color: $color-white;
    font-family: var(--link-menu-font-stack);
    // font-weight: var(--link-menu-font-weight); // This is already overwritten after the font shorthand
    font-size: var(--link-menu-font-size);
    font-style: var(--link-menu-font-style);
    font-variant: var(--link-menu-font-variant);
    font-weight: bold;
    letter-spacing: var(--link-letter-spacing);
    line-height: var(--link-menu-line-height);
    position: relative;
    text-decoration: none;
    text-transform: var(--link-text-transform);
    transition: color .15s cubic-bezier(.26, .88, .57, .9);
    transition: background-size .2s linear;
    vertical-align: bottom;
    vertical-align: baseline;
    word-spacing: var(--link-word-spacing);

    @include mobile-only {
      display: inline-block;
      font-weight: 400;
      width: auto;
    }
  }
}

.menu-bar__profile-button:hover .menu-panel-profile__title {
  background-size: 100% 1px;
}

.menu-panel-login-dropdown {
  background: $color-white;
  border-radius: 0 0 10px 10px;
  border-radius: 0 0 11px 11px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 0;
  position: absolute;
  right: 0;
  top: 60px;
  transition: all .2s linear;
  visibility: visible;
  width: 335px;

  @include mobile-only {
    border-radius: 0;
    box-shadow: none;
  }

  &.is-collapsed {
    opacity: 0;
    visibility: hidden;

    @include mobile-only {
      height: auto;
    }
  }

  @include mobile-only {
    border-radius: 0;
    height: calc(100vh - 55px);
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }

  @include notebook {
    opacity: 1;
  }

  &::before {
    border-color: transparent transparent $color-white transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    position: absolute;
    right: 73px;
    top: -10px;

    @include mobile-only {
      display: none;
    }
  }

  .menu-panel-login-dropdown__mobile {
    display: none;

    .link__link {
      color: $color-purple-darker;
    }

    .menu-panel-login-dropdown__close {
      border-radius: 0;
      font-size: 16px;
      font-weight: bold;
      margin-left: -10px;
      padding: 0 0 0 8px;

      svg {
        transform: scale(1.4);
      }
    }

    .menu-panel-profile__icon {
      display: block;
      float: left;
      height: 15px;
      margin-right: 15px;
      width: 15px;
    }

    @include mobile-only {
      background: $color-white;
      color: $color-purple-darker;
      display: block;
      font-size: 16px;
      font-weight: bold;
      padding: 20px;
      width: 100%;
    }
  }

  .menu-panel-login-dropdown__top {
    border-bottom: 1px solid $color-purple-lightest;
    padding: 18px 20px;
  }

  .menu-panel-login-dropdown__name {
    font-weight: bold;
  }

  .menu-panel-login-dropdown__list {
    padding: 0;
  }

  .menu-panel-login-dropdown__list-item {
    border-bottom: 1px solid $color-purple-lightest;
    list-style: none;
  }

  @include notebook {
    .menu-panel-login-dropdown__list-item:last-child {
      border-bottom: 0px;
    }
  }

  .menu-panel-login-dropdown__link {
    display: block;
    padding: 20px;
    text-decoration: none;
  }

  .menu-panel-login-dropdown__icon {
    display: block;
    float: left;
    height: 15px;
    width: 15px;
  }

  .menu-panel-login-dropdown__title {
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 15px;
    text-decoration: none;
  }

  .menu-panel-login-dropdown__text {
    margin-left: 30px;
    text-decoration: none;
  }
}

.menu-link--regular-link-hide {
  overflow: hidden;
  position: absolute;
  width: 1px;
}
