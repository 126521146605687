@mixin animated-underline($trigger-element, $underlined-element) {
  #{$trigger-element}:hover {
    @if $trigger-element == $underlined-element {
      background-size: 100% 1px;
    } @else {
      #{$underlined-element} {
        background-size: 100% 1px;
      }
    }
  }

  #{$underlined-element} {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    display: inline-block;
    transition: background-size .2s linear;
    vertical-align: baseline;
  }
}
