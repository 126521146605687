.members {
  min-width: 150px;

  &.readonly {
    .members-info__member {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(80%) contrast(80%) brightness(120%);
    }

    .members-info__member-count {
      border-bottom: none;
      color: $color-purple-light;
      display: inline-block;
      font-size: 12px;
      height: 26px;
      height: 30px;
      margin-left: 12px;
    }
  }

  &.purple {
    .members--info__list {
      .members-info__member {
        .members--info__image {
          border-color: $color-purple;

          &--unknown {
            border-color: $color-white;
          }
        }
      }
    }
  }

  .members--info__list {
    display: inline-block;
    height: 30px;
    list-style-type: none;
    margin-right: 20px;
    padding-inline-start: 0;

    .members-info__member {
      display: inline-block;
      width: 20px;

      .members--info__image {
        border: 2px solid $color-white;
        border-radius: 100%;
        height: 30px;
        max-width: none;
        width: 30px;
      }
    }
  }

  .members-info__member-count {
    border-bottom: 1px solid $color-white;
    display: inline-block;
    font-size: 12px;
    line-height: 23px;
    vertical-align: top;

    @include notebook {
      border-bottom: none;
    }

    .link {
      font-size: 16px;
    }
  }
}
