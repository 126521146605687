
// Base colors
$color-black: #000;
$color-white: #fff;
$color-white-opaque: rgba(255, 255, 255, .3);
$color-black-opaque: rgba(0, 0, 0, .25);

// Theme colors
$color-purple: #683282;
$color-orange: #ffbe46;
$color-vakmensen: #5275ad;

// Modified theme colors
$color-purple-dark: #371548;
$color-purple-darker: #301a44;
$color-purple-light: #6d5777;
$color-purple-light2: #b398c0; // TODO improve var names
$color-purple-lighter: #9b8aa3;
$color-purple-lightest: #d4cad7;
$color-purple-lightest2: #e6dfe7;
$color-purple-hover: #c8bdcd;
$color-orange-dark: #ca922b;
$color-orange-hover: #e3a32a;

// Greyscale colors
$color-grey: #f3edf2;  // Called light-purple in visual design

// Old greyscale colors
$color-grey-extradark: #3c3c3c;
$color-grey-dark: #696969;
$color-grey-medium: #969696;
$color-grey-light: #c3c3c3;
$color-grey-lighter: #f3edf2;
$color-grey-extralight: #fafafa;

// Functional colors
$color-error: #f00;
$color-error-opacity: rgb(255, 0, 0, .1);
$color-green: #017a11;
$color-neon-green: #6cd75a;

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-menu-overlay: 2048;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
$navigation-height: 60px;
$navigation-secondary-links-height: 36px;

@include mobile {
  :root {
    // None yet
    //--bezier-fancy: $bezier-fancy;
    --menu-height: 60px;
    --component-margin: 25px;
    --round-corner-size: calc(var(--component-margin) * 1.5);
    --swiper-nav-icon-size: calc(var(--link-line-height) * 1.175);
    --single-grid-column: calc((100vw - calc(var(--grid-gutter) * (var(--grid-columns) - 1))) / var(--grid-columns));
    --single-grid-column-with-gutter: calc(var(--single-grid-column) + var(--grid-gutter));
    --footer-page-background: #{$color-white};
  }
}

@include notebook {
  :root {
    --menu-height: 96px;
    --component-margin: 30px;
  }
}

@include desktop {
  :root {
    --component-margin: 30px;
  }
}

//Variabele margins between items

$default-spacer: 0 .4rem;
