
.article-usps {
  padding: var(--component-margin);
  padding-top: 45px;
  position: relative;

  @include tablet {
    padding-top: 65px;
  }

  .article-usps__grid {
    row-gap: 25px;

    @include grid-container;

    @include notebook {
      row-gap: 40px;
    }
  }

  .article-usps__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column: 2 / 14;
    justify-content: center;

    @include tablet {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include desktop {
      grid-column: 2 / 16;
    }
  }

  .article-usps__main-title {
    display: flex;
    flex: 1 1 33.33%;
    flex-direction: column;
    margin-bottom: 40px;
    text-align: left;
    transform: translateX(-21px);
    width: 100%;

    @include tablet {
      flex: 1 1 50%;
      transform: translateX(0);
    }

    @include notebook {
      flex: 1 1 33.33%;

      &:nth-child(4n),
      &:first-child {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .article-usps__usp {
    align-items: center;
    display: flex;
    flex: 1 1 33.33%;
    flex-direction: column;
    margin-bottom: 30px;

    @include tablet {
      flex: 1 1 50%;
    }

    @include notebook {
      flex: 1 1 33.33%;

      &:nth-child(4n),
      &:first-child {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .article-usps__icon {
    align-self: center;
    background-color: transparent;
    font-size: 30px;
    height: 30px;
    text-align: center;
    width: 30px;

    svg {
      fill: currentColor;
    }

    @include tablet {
      max-width: 315px;
    }
  }

  .article-usps__title {
    @include typography(heading-4, true, false);

    margin-bottom: 12px;
    margin-top: 0;
    text-align: center;

    @include tablet {
      max-width: 315px;
    }
  }

  .article-usps__text {
    text-align: center;

    @include tablet {
      max-width: 315px;
    }
  }

  .article-usps__link {
    padding-top: 10px;

    .link__span {
      @include style-fancy-link-underline;

      color: #371548;
    }
  }

  .article-usps__title:not(:first-child) {
    margin-top: 14px;
  }
}
