
.profile-settings {
  grid-column: var(--layout-grid-inline);
  margin-bottom: calc(var(--component-margin) * 2);

  .article-content {
    display: block;
  }
  //
  //.article-header {
  //  padding-top: calc(var(--component-margin));
  //
  //  .article-header__intro-meta-wrapper {
  //    padding-top: calc(var(--component-margin) / 2);
  //  }
  //
  //  .article-header__grid {
  //    display: block;
  //  }
  //}
}
