.cookiebot-declaration {
  padding-bottom: var(--component-margin);
  padding-top: var(--component-margin);

  .cookiebot-declaration__grid {
    @include grid-container;
  }

  .cookiebot-declaration__content {
    grid-column: var(--layout-content);
  }
}
