.login-details {
  display: grid;
  grid-column: var(--layout-grid-inline);
  position: relative;

  .login-details__title {
    grid-column: 1/2;
    margin-bottom: 20px;

    @include tablet {
      grid-column: 1/3;
    }
  }

  .login-details__detail {
    margin-bottom: 30px;

    @include tablet {
      margin: 0;
    }
  }

  .login-details__label {
    font-weight: #{get-graphik-weight(bold)};
  }

  .login-details__input {
    margin-bottom: 15px;
  }

  .login-details__reset-profile {
    display: block;
    left: 0;
    margin-top: 30px;
    padding-top: 25px;
    position: relative;
    transform: translateX(0px);
    width: auto;
    z-index: 1;

    @include tablet {
      transform: translateX(-10%);
    }

    @include notebook {
      transform: translateX(-12%);
    }

    @include desktop {
      transform: translateX(-13%);
    }

    &::after {
      background-color: $color-grey;
      bottom: -30px;
      content: '';
      display: block;
      height: 179%;
      left: -12%;
      position: absolute;
      width: 121%;
      z-index: -1;

      @include tablet {
        bottom: -40px;
        left: -7%;
        width: 166%;
      }

      @include notebook {
        left: -7%;
        width: 188%;
      }

      @include desktop {
        left: -6%;
        width: 218%;
      }
    }

    .login-details__reset-profile-button {
      color: $color-error;
      text-decoration: underline;
    }
  }
}
