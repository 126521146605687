.content-header {
  margin-bottom: 40px;
  padding-top: 45px;

  @include notebook {
    padding-top: 50px;
  }

  .content-header__grid {
    @include grid-container;
  }

  .content-header__wrapper {
    grid-column: var(--layout-content-large);
  }

  .content-header__row {
    .button {
      max-width: 100%;
      width: 100%;

      @include tablet {
        width: auto;
      }
    }

    @include notebook {
      @include grid-container;

      .content-header__date {
        grid-column: 1/11;
      }

      .content-header__btn {
        grid-column: 11/15;
        justify-self: end;
        width: 100%;

        .button {
          width: 100%;
        }
      }
    }

    .content-header__column {
      grid-column: 1/10;
    }

    .content-header__quicklinks {
      grid-column: 11/15;
      margin-top: 10px;

      .article-quicklinks__title {
        @include typography(heading-5);

        border-bottom: 1px solid $color-purple-light;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .article-quicklinks__links {
        font-size: 16px;
        list-style: none;
        padding: 0;

        li {
          margin-top: 15px;

          .link {
            font-size: 16px;
          }
        }
      }
    }
  }

  .content-header__row--full {
    .content-header__column {
      grid-column: 1/15;
    }

    .content-header__quicklinks {
      display: none;
    }
  }

  .content-header__title {
    @include typography(heading-1);
  }

  .content-header__subtitle {
    margin-top: 10px;

    @include typography(introduction);
  }

  .content-header__btn {
    margin-bottom: 20px;

    @include notebook {
      margin-top: 0;
    }
  }

  .content-header__dropdown {
    margin-top: 10px;

    @include notebook {
      grid-column: 11/15;
      justify-self: end;
      width: 100%;
    }
  }

  .content-header__link {
    display: block;
    padding: 10px;
    position: relative;
    top: 10px;

    @include notebook {
      display: inline;
      left: 20px;
      padding: unset;
      top: -12px;
    }
  }

  .modal-content-downloads__outro {
    margin-bottom: var(--component-margin);
  }
}

.content-header--simple {
  padding-bottom: var(--component-margin);
  padding-top: 50px;

  @include notebook {
    padding-top: 50px;
  }

  .content-header__row .content-header__quicklinks {
    margin-top: 30px;
  }
}
