.article-dropdown-menu {
  padding: var(--component-margin) 0;

  .article-dropdown-menu__grid {
    @include grid-container;
  }

  .article-dropdown-menu__wrapper {
    display: grid;
    grid-column: var(--layout-content-large);
    justify-items: center;
  }

  .article-dropdown-menu__title {
    @include typography(heading-3);

    margin-bottom: 30px;
    text-align: center;
  }

  .article-dropdown-menu__dropdown {
    max-width: 310px;
    width: 100%;
  }
}

.article-dropdown-menu--link-list {
  background-color: $color-error;
}
