
.option-field {
  position: relative;

  .option-field__label {
    @include typography(heading-5, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .option-field__wrapper {
    display: grid;
    font-size: 0;
    justify-items: start;
    margin-bottom: 6px;
    max-width: 100%;
    position: relative;
  }

  .option-field__item {
    @include typography(paragraph, false, true);

    &:focus-within {
      outline: 1px solid;
      outline-offset: 2px;
    }
  }

  .option-field__input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:disabled {
      + .option-field__input-label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + .option-field__input-label {
        .option-field__icon-foreground {
          opacity: 1;
        }
      }
    }
  }

  .option-field__input-label {
    @include typography(paragraph);

    cursor: pointer;
    display: block;
    margin: 0;
    min-height: var(--paragraph-line-height);
    padding: 0 0 0 32px;
    position: relative;
    text-transform: none;
    user-select: none;
    width: 100%;

    a {
      @include style-fancy-link-underline;
    }
  }

  .option-field__input-label-span {
    display: inline-block;
    vertical-align: baseline;
  }

  .option-field__input-label-subtitle-span {
    @include typography(caption);

    color: $color-purple-light;
    display: block;
  }

  .option-field__icon {
    color: $color-grey-extradark;
    display: block;
    height: 20px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: .1em;
    transition: opacity $easing-change-faster, background-color $easing-change-faster, color $easing-change-faster;
    width: 20px;
  }

  .option-field__icon-background {
    opacity: 1;
  }

  .option-field__icon-foreground {
    opacity: 0;
  }

  .option-field__helptext {
    @include typography(field-helptext);

    margin-top: 2px;
  }

  &.option-field--error {
    .option-field__error-icon {
      @include field-error-icon;
    }

    .option-field__error {
      @include typography(field-helptext);

      margin-top: 2px;
    }

    &:not(:focus-within) {
      .option-field__wrapper {
        outline: 1px solid $color-error;
        outline-offset: 2px;
      }
    }
  }

  &.option-field--inline {
    .option-field__wrapper {
      display: block;
    }

    .option-field__item {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 20px;
      width: auto;
    }
  }

  &.option-field--radio {
    .option-field__icon-foreground {
      background: $color-purple-dark;
      border-radius: 50%;
      height: 14px;
      margin: 3px;
      width: 14px;
    }

    .option-field__icon-background {
      border: 1px solid $color-purple-lighter;
      border-radius: 50%;
      overflow: hidden;
    }

    &.option-field.option-field--radio.option-field--error {
      .option-field__wrapper {
        outline: 0;
      }

      .option-field__icon-background {
        background: $color-error-opacity;
        border: 1px solid $color-error;
      }

      .option-field__input:checked {
        + .option-field__input-label {
          .option-field__icon-background {
            background: $color-grey-lighter;
            border-color: $color-purple-dark;
          }
        }
      }
    }
  }

  &.option-field--checkbox {
    .option-field__input:checked {
      + .option-field__input-label {
        .option-field__icon-background {
          background: $color-purple-dark;
          border-color: $color-purple-dark;
        }
      }
    }

    .option-field__icon-background {
      border: 1px solid $color-purple-lighter;
      border-radius: 2px;
    }

    .option-field__icon-foreground {
      color: $color-white;
    }

    &.option-field.option-field--checkbox.option-field--error {
      .option-field__wrapper {
        outline: 0;
      }

      .option-field__icon-background {
        background: $color-error-opacity;
        border: 1px solid $color-error;
      }

      .option-field__input:checked {
        + .option-field__input-label {
          .option-field__icon-background {
            background: $color-purple-dark;
            border-color: $color-purple-dark;
          }
        }
      }
    }
  }
}
