.card-grid-featured {
  padding: 50px 0 30px;
  position: relative;

  &.is-collapsed {
    .card-grid-featured__cards .card {
      @include mobile-only {
        &:nth-child(n+4) {
          // display max 5 items
          display: none;
        }
      }

      &:nth-child(n+7) {
        // display max 5 items
        display: none;
      }
    }
  }

  .card-grid-featured__grid {
    @include grid-container;
  }

  .card-grid-featured__wrapper {
    grid-column: var(--layout-content-image);
  }

  .card-grid-featured__title {
    @include typography(heading-2);

    grid-column: 2 / 14;

    @include notebook {
      grid-column: 4 / 14;
    }

    @include desktop {
      grid-column: 5 / 14;
    }
  }

  .card-grid-featured__text {
    font-size: 20px;
    grid-column: 2 / 14;
    line-height: 28px;
    margin-bottom: 40px;
    margin-top: 20px;

    @include notebook {
      grid-column: 3 / 14;
    }

    @include desktop {
      grid-column: 4 / 14;
    }
  }

  .card-grid-featured__cards {
    display: grid;
    grid-gap: var(--grid-spacing) var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    @include notebook {
      grid-template-columns: repeat(4, 1fr);
    }

    .card-grid-featured__card {
      height: 400px;
    }

    .card-grid-featured__card-featured {
      height: 400px;

      @include notebook {
        grid-column: 1 / 4;
      }
    }
  }

  .card-grid-featured__btn-wrapper {
    margin-top: 15px;
  }

  .card-grid-featured__btn {
    line-height: 28px;
  }
}
