.hero-featured {
  overflow-x: hidden;
  position: relative;

  .hero-featured__container {
    @include grid-container;

    color: $color-white;
    height: 40vh;
    position: relative;
    row-gap: 25px;

    &:hover {
      .link__span {
        background-size: 100% 1px;
      }
    }

    &::after {
      background: $color-grey;
      border-top-left-radius: var(--round-corner-size);
      bottom: 0;
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      width: 100%;
    }

    @include notebook {
      height: 460px;
    }

    @include desktop {
      height: 745px;

      &::after {
        right: var(--single-grid-column-with-gutter);
      }
    }

    .hero-featured__content {
      @include notebook {
        bottom: 60px;
      }
    }
  }

  .hero-featured__image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 60%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .picture {
      height: 100%;
      width: 100%;
    }
  }

  .hero-featured__content {
    bottom: 60px;
    display: flex;
    flex-direction: column;
    grid-column: 2 / 14;
    justify-content: flex-end;
    padding: 30px 0;
    position: relative;

    @include notebook {
      bottom: 0;
      grid-column: 2 / 8;
      padding: 30px 0;
    }

    @include desktop {
      grid-column: 3 / 10;
      padding: 0 30px 60px 0;
    }
  }

  .hero-featured__date {
    margin-bottom: 15px;
  }

  .hero-featured__title {
    @include typography(heading-1, false, false);

    margin-bottom: 20px;

    @include notebook {
      margin-bottom: 30px;
    }
  }

  .hero-featured__sidebar {
    background-color: transparent;

    @include notebook {
      position: absolute;
      right: 0;
      top: 0;
      width: 560px;
    }

    .hero-featured__title {
      @include typography(heading-4, false, true);
    }

    .hero-featured__content {
      background-color: $color-purple-dark;
      color: $color-white;
      height: 150px;
      padding: 0 30px 30px;

      &:hover {
        .link__span {
          background-size: 100% 1px;
        }
      }

      .link {
        &:hover {
          .link__span {
            background-size: 0 1px;
          }
        }
      }

      @include notebook {
        height: 150px;
        padding: 40px 30px 40px 40px;
      }

      @include desktop {
        height: fit-content;
        padding: 60px 30px 60px 60px;
      }

      @include mobile-only {
        @include grid-container;

        .hero-featured__content--mobile {
          align-self: flex-start;
          grid-column: 2 / 14;
          position: relative;
          top: 33px;
        }
      }

      &:first-child {
        border-radius: 30px 0 0;

        @include notebook {
          border-radius: 0;
        }
      }

      &:last-child {
        border-radius: 0 0 0 30px;
      }

      &:not(:last-child) {
        &::after {
          border-bottom: 1px solid rgba(255, 255, 255, .2);
          bottom: -30px;
          content: '';
          grid-column: 2 / 16;
          position: relative;
          z-index: 99;

          @include notebook {
            bottom: -40px;
            width: 110%;
          }

          @include desktop {
            bottom: -60px;
          }
        }
      }
    }
  }

  &--background-purple {
    .hero-featured__container {
      &::after {
        background: $color-purple;
      }
    }
  }

  &--stripe-left-big {
    .hero-featured__container {
      &::after {
        @include desktop {
          left: var(--single-grid-column-with-gutter);
        }
      }
    }
  }
}
