.choose-cao {
  @include tablet {
    min-height: 257px;
  }

  .autocomplete {
    margin-top: 30px;

    &__container {
      .ts-dropdown {
        border: none;
        left: 0;
        margin-top: -3px;
        position: relative;
        right: 0;
        top: 100%;
        width: 100%;
      }
    }
  }
}
