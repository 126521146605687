
:root {
  --introduction-font-size: 18px;
  --introduction-font-stack: #{$graphik-font-stack};
  --introduction-font-style: normal;
  --introduction-font-variant: normal;
  --introduction-font-weight: #{get-graphik-weight(regular)};
  --introduction-letter-spacing: -.2px;
  --introduction-line-height: 1.3em;
  --introduction-margin-bottom: 1rem;
  --introduction-margin-top: 1rem;
  --introduction-word-spacing: normal;

  @include notebook {
    --introduction-font-size: #{get-fluid-value(18px, 20px)};
    --introduction-line-height: 1.3em;
    --introduction-letter-spacing: -.2px;
  }

  @include desktop {
    --introduction-font-size: #{get-fluid-value(20px, 22px)};
    --introduction-line-height: 1.3em;
    --introduction-letter-spacing: -.3px;
  }
}
