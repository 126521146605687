.address-field {
  .address-field__title {
    @include typography(heading-5, false, true);
  }

  .address-field__result-container {
    flex-shrink: .6125;
    max-height: 60px;
  }

  .address-field__result-wrapper {
    height: 100%;
  }

  .address-field__result {
    @include typography(caption);

    align-items: flex-start;
    background-color: $color-white;
    border-radius: 2px;
    color: $color-purple;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 12px 15px;
  }

  .address-field__result-address {
    margin-right: 10px;
  }

  .address-field_btn-change {
    @include style-fancy-link-underline;
  }

  .address-field__city-street-container {
    margin-top: var(--grid-gutter);
  }

  .address-field__no-results {
    margin-top: 25px;
  }

  .address-field__btn-dutch,
  .address-field__btn-abroad {
    @include typography(paragraph);

    margin-top: var(--grid-gutter);
  }
}
