.filter-zipcode {
  position: relative;

  .filter-zipcode__grid {
    @include grid-container;
  }

  .filter-zipcode__wrapper {
    grid-column: var(--layout-content);
  }

  .filter-zipcode__container {
    background-color: $color-purple;
    border-radius: 15px;
    color: $color-white;
    padding: 30px 20px;
  }

  .filter-zipcode__title {
    @include typography(heading-2, false, true);

    padding-bottom: .5rem;
    text-align: center;
  }

  .filter-zipcode__search-input {
    .form-row {
      display: flex;
      gap: 1rem;
      justify-content: center;
    }
  }

  .filter-zipcode__button {
    height: 3.8rem;

    .button__icon {
      flex: unset;
      height: 1.8rem;
      margin: 0;
      width: 1.8rem;
    }
  }

  .filter-zipcode__result {
    display: none;

    .link__icon {
      > svg {
        display: block;
        fill: currentColor;
        height: 100%;
        width: 100%;
      }
    }
  }

  .filter-zipcode__result-consultant {
    background-color: $color-white;
    border-radius: 15px;
    color: $color-purple-dark;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 30px;
    padding: 30px 15px;
  }

  .filter-zipcode__result-title {
    @include typography(heading-3, false, true);
  }

  .filter-zipcode__result-email,
  .filter-zipcode__result-phone {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }

  .filter-zipcode__result-phone {
    .link__icon {
      transform: rotate(90deg);
    }
  }

  .filter-zipcode__error {
    display: none;
  }

  .show--error {
    background-color: $color-white;
    border-radius: 15px;
    color: $color-purple-dark;
    display: block;
    margin-top: 30px;
    padding: 30px 20px;
  }

  @include tablet {
    .filter-zipcode__wrapper {
      grid-column: 4 / 12;
    }
  }

  @include notebook {
    .filter-zipcode__wrapper {
      grid-column: 5 / 11;
    }

    .filter-zipcode__result-consultant {
      padding: 30px 20px;
    }
  }
}
