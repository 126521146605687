
.button {
  @include typography(button);

  @mixin primary() {
    background-color: $color-orange;
    box-shadow: inset 0px -1px 0px $color-orange-dark;
    color: $color-purple-dark;

    &:hover:not(.button--disabled):not(:disabled),
    &:focus:not(.button--disabled):not(:disabled),
    &.button--selected {
      background-color: $color-orange-hover;
    }
  }

  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  min-width: 60px;
  overflow: hidden;
  padding: 15px 22px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  .button__span {
    display: block;
    flex: 1 1 100%;
    height: 20px;
    //overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    [data-modal-close] & {
      pointer-events: none;  /* weird bug */
    }
  }

  .button__icon {
    display: block;
    flex: 0 0 var(--button-line-height);
    height: var(--button-line-height);
    padding: .1em .1em .2em;
    width: var(--button-line-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .button__icon--before {
    margin-left: -.5em;
    margin-right: .5em;
  }

  .button__icon--after {
    margin-left: .5em;
    margin-right: -.5em;
  }

  &.button--primary {
    @include primary;
  }

  &.button--primary-full-width {
    @include primary;

    width: 100%;
  }
}

.button:disabled,
.button--disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.button--primary-compact {
  background-color: $color-orange;
  border-radius: 6px;
  box-shadow: inset 0px -1px 0px $color-orange-dark;
  color: $color-purple-dark;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 18px 10px;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-orange-hover;
  }

  .button__icon {
    margin-left: .75em;
    transform: scale(1.35);
  }
}

.button--secondary {
  background-color: $color-grey-light;
  border-color: $color-grey-light;
  color: $color-grey-extradark;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-grey-extradark;
    border-color: $color-grey-extradark;
    color: $color-grey-extralight;
  }
}

.button--tertiary {
  background-color: $color-purple-lightest;
  color: $color-purple-dark;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-purple-hover;
  }

  .button__span {
    font-weight: 500;
  }
}

.button--tertiary-compact {
  background-color: $color-purple-lightest;
  border-radius: 6px;
  color: $color-purple-dark;
  font-family: var(--button-font-stack);
  font-feature-settings: normal;
  font-size: 11px;
  font-style: var(--button-font-style);
  font-variant: var(--button-font-variant);
  font-weight: 500;
  letter-spacing: var(--button-letter-spacing);
  // font-weight: var(--button-font-weight);m// These were set in the shorthand, but not used
  // font-size: var(--button-font-size); // These were set in the shorthand, but not used
  line-height: var(--button-line-height);
  padding: 8px 18px 10px;
  text-transform: var(--button-text-transform);
  word-spacing: var(--button-word-spacing);

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-purple-hover;
  }

  .button__span {
    margin-left: .75em;
    transform: scale(1.35);
  }

  .button__icon--after {
    margin-left: 2em;
    margin-right: -.5em;
    margin-top: 3px;
  }
}

.button--undesired-choice {
  background: transparent;
  color: $color-purple-dark;

  @include typography(link);
}

.button--primary-on-image {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-purple-dark;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-purple-dark;
    border-color: $color-purple-dark;
    color: $color-grey-extradark;
  }
}

.button--secondary-on-image {
  background-color: $color-purple-dark;
  border-color: $color-purple-dark;
  color: $color-white;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-purple-dark;
    border-color: $color-purple-dark;
    color: $color-purple-dark;
  }
}

.button--link {
  color: $color-purple-dark;
  font-weight: 400;

  .button__span {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 1px;
    transition: background-size .2s linear;
    vertical-align: baseline;
  }

  &:hover,
  &:focus {
    .button__span {
      background-size: 100% 1px;
    }
  }
}

.button--icon {
  border-radius: 5px;
  height: 30px;
  margin: 0;
  min-width: auto;
  padding: 0;
  width: 30px;

  .button__icon {
    left: 5px;
    padding: 0;
    position: relative;
    top: 5px;
  }
}

.button--icon-save {
  background-color: $color-orange;
  box-shadow: inset 0px -1px 0px $color-orange-dark;
  color: $color-purple-dark;

  &:hover,
  &:focus {
    background-color: $color-orange-hover;
  }
}

.button--icon-cancel {
  background-color: $color-purple-lightest;
  box-shadow: inset 0px -1px 0px #bcabc1;

  &:hover,
  &:focus {
    background-color: #bcabc1;
    box-shadow: inset 0px -1px 0px $color-purple-light;
  }
}

.button--loadmore {
  display: inline-block;
  padding: 10px 13px;

  .button__span {
    float: left;
    font-weight: 400;
    text-decoration: underline;
    width: auto;
  }

  .button__icon {
    float: left;
  }
}

.button--pill {
  background-color: #f3edf2;
  border: 0;
  border-radius: 30px;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  font: normal 600 10px/14px $graphik-font-stack;
  height: inherit;
  letter-spacing: .07em;
  padding: 5px 10px;
  text-transform: uppercase;

  .button__span {
    height: auto;
  }

  &.button--selected {
    background-color: $color-purple-dark;
    color: $color-white;
  }
}

.button--with-loader {
  .button__icon--before {
    display: none;
    left: -7px;
    margin: 0;
    position: relative;
    top: 2px;
  }
}
