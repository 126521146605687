.sidebar {
  background-color: $color-white;
  grid-auto-rows: min-content;
  grid-column: var(--layout-aside);
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;

  &::after {
    border-image: linear-gradient(to right, rgba(255, 255, 255, .2), rgba(255, 255, 255, .9)) 1 100%;
    border-right: 40px solid;
    content: '';
    height: 55px;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include tablet {
    overflow: hidden;

    &::after {
      content: none;
    }
  }

  .sidebar__list {
    border-bottom: 1px solid $color-purple-lightest;
    display: grid;
    grid-auto-columns: calc(50% - var(--gutter) * 2);
    grid-auto-flow: column;
    grid-column: 1 / -1;
    grid-gap: calc(var(--gutter) / 2);
    margin: 0 15px;
    padding: 8px 0;
    scroll-snap-type: x proximity;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include tablet {
      border: none;
      display: block;
      margin: 0 28px;
      padding: 15px 0 20px 30px;
    }

    &:not(:first-child) {
      border-top: 1px solid $color-grey;
    }
  }

  &.dense {
    .sidebar__list {
      @include tablet {
        padding: 5px 0 10px 31px;
      }
    }
  }

  .sidebar__list-item {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
    scroll-snap-align: center;

    .link {
      padding-left: 0;
    }

    .link--tabs {
      position: relative;

      .link__icon--after,
      .link__icon--before {
        left: 0;
        position: absolute;
        top: 8px;

        @include tablet {
          left: -28px;
          position: absolute;
          top: 7px;
        }
      }

      .link__span {
        margin-left: 28px;
        margin-right: 10px;
        width: auto;

        @include tablet {
          margin-left: inherit;
          margin-right: inherit;
        }
      }
    }

    .link--active .link__icon--after {
      left: inherit;
      top: 6px;

      @include tablet {
        left: -35px;
      }
    }

    @include tablet {
      float: none;
      width: 100%;
    }
  }
}
