
.card-carousel {
  background-color: transparent;
  overflow-x: hidden;
  padding: var(--component-margin) 0;
  position: relative;

  .card-carousel__grid {
    @include grid-container;
  }

  .card-carousel__wrapper {
    grid-column: 2 / 14;
    position: relative;

    @include notebook {
      grid-column: 3 / 13;
    }

    @include desktop {
      grid-column: 3 / 13;
    }
  }

  .card-carousel__title {
    @include typography(heading-3, false, true);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .card-carousel__categories {
    margin-bottom: 20px;

    @include notebook {
      margin-bottom: 40px;
      margin-left: var(--single-grid-column-with-gutter);
    }

    .card-carousel__category {
      background: $color-white;
      border-radius: 30px;
      color: $color-purple-dark;
      cursor: pointer;
      display: inline-block;
      font-size: 10px !important;
      font-weight: 500;
      letter-spacing: .07em;
      line-height: 14px;
      margin-bottom: 5px;
      margin-right: 10px;
      padding: 3px 10px;
      text-transform: uppercase;

      &:hover,
      &.selected {
        background: $color-purple-dark;
        color: $color-white;
      }
    }
  }

  .card-carousel__cards {
    .swiper-container {
      clip-path: inset(-100vw -100vw -100vw 0); // trick to have overflow only on the right side
      overflow: visible;
      position: relative;
    }

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .card-carousel__link {
    float: right;
    height: var(--swiper-nav-icon-size);
    padding: 10px 10px 14px;
  }

  &.card-carousel__has-link .card-carousel__cards {
    margin-bottom: var(--component-margin);

    @include tablet {
      margin-bottom: 0;
    }
  }

  &.card-carousel--white {
    color: $color-white;

    .card-carousel__title {
      color: $color-white;
    }

    .button.swiper-button-prev,
    .button.swiper-button-next {
      color: $color-white;
    }
  }
}

.card-carousel--compact {
  .card-carousel__wrapper {
    grid-column: 2 / 14;

    @include notebook {
      grid-column: 2 / 11;
    }

    @include desktop {
      grid-column: 3 / 13;
    }
  }

  .card {
    @include notebook {
      height: 200px;
    }
  }
}

.card-carousel__navigation {
  margin-left: -10px;
  margin-top: -14px;

  @include notebook {
    margin-left: calc(var(--single-grid-column-with-gutter) - 10px);
  }
}

.swiper-button-prev,
.swiper-button-next {
  // override default
  background: none;
  box-sizing: content-box;
  display: inline-block;
  height: var(--swiper-nav-icon-size);
  padding: 10px 10px 14px;
  width: var(--swiper-nav-icon-size);
}

.swiper-button-prev {
  padding-right: 0;
}

.swiper-button-next {
  padding-left: 0;
}

.swiper-button-disabled {
  opacity: .4;
  pointer-events: none;
}

@mixin card-carousel-swiper {
  //@include idangerous-swiper-base;
  //@include idangerous-swiper-arrows;
  //@include idangerous-swiper-pagination;

  //@include mobile {
  .swiper-container {
    //padding-bottom: 40px;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;

    .card {
      height: 100%;
    }
  }

  .swiper-pagination-bullet {
    background-color: $color-white;
    height: 10px;
    margin-right: 10px;
    opacity: 1;
    transition: background-color $easing-change;
    width: 10px;

    &:hover,
    &:focus {
      background-color: $color-grey-dark;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $color-green-light;

    &:hover,
    &:focus {
      background-color: $color-green-dark;
    }
  }
  //}

  @include notebook {
    .swiper-pagination {
      display: none;
    }
  }
}

html {
  scroll-behavior: auto;
}
