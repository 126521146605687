.article-full-width {
  overflow-x: hidden;
  position: relative;

  .article-full-width__container {
    @include grid-container;

    color: $color-white;
    height: 75vh;
    position: relative;
    row-gap: 25px;

    &::before {
      background: $color-grey;
      border-bottom-left-radius: var(--round-corner-size);
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 99;
    }

    &::after {
      background: $color-grey;
      border-top-right-radius: var(--round-corner-size);
      bottom: 0;
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      width: 100%;
    }

    @include desktop {
      &::after {
        right: var(--single-grid-column-with-gutter);
      }
    }
  }

  .article-full-width__image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 60%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .picture {
      height: 100%;
      width: 100%;
    }
  }

  .article-full-width__content {
    bottom: 60px;
    display: flex;
    flex-direction: column;
    grid-column: 2 / 14;
    justify-content: flex-end;
    padding: 16px 0 30px;
    position: relative;

    @include notebook {
      grid-column: 2 / 11;
      padding: 30px 0;
    }

    @include desktop {
      grid-column: 3 / 10;
    }
  }

  .article-full-width__paragraph {
    margin-bottom: 15px;
  }

  .article-full-width__title {
    @include typography(heading-3, false, true);
  }
}
