.detail-information {
  background: $color-grey;
  padding: 20px;
  padding-bottom: var(--component-margin);

  .detail-information__links {
    padding: 0;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

.detail-information-data {
  list-style: none;
  padding: 4px 0;

  @mixin section() {
    display: table-cell;
    flex: 3;
    height: auto;
    min-height: 20px;
    position: relative;
  }

  @include typography(paragraph);

  .detail-information-data__icon {
    @include section;

    float: left;
    height: auto;
    transform: translateY(-2px);
    width: 16px;
  }

  .detail-information-data__title {
    @include section;

    font-weight: 600;
    padding: 0 5px 0 10px;
  }

  .detail-information-data__text {
    @include section;
  }

  svg {
    transform: translateY(4px);
  }
}
