.menu-bar {
  height: var(--menu-height);
  position: relative;
  z-index: 1000; // note that popups are contained within

  .menu-bar__grid {
    @include grid-container;

    background-color: $color-purple;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .25);
    left: 0;
    max-width: none;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .menu-bar__wrapper {
    display: flex;
    flex-direction: row;
    grid-column: column 1 / span 14;
  }

  .menu-bar__logo {
    background-color: $color-white;
    border-bottom-right-radius: 11px;
    display: flex;
    margin-bottom: 12px;
    padding: 0 13px;
  }

  .menu-bar-site-sector {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.4em;
    margin: 8px 13px 5px;

    @include notebook {
      display: none;
    }

    .menu-bar__selected-sector {
      letter-spacing: var(--link-menu-letter-spacing);
      line-height: 1.4em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: var(--link-menu-text-transform);
      white-space: nowrap;
      width: 85px;
      word-spacing: var(--link-menu-word-spacing);

      @include tablet {
        width: 100%;
      }
    }
  }

  .menu-bar__mijn-cnv-link-mobile {
    .link {
      .link__icon {
        height: 20px;
        left: -1px;
        margin-right: 7px;
        top: 1px;
        transform: scaleX(-1);
        width: 14px;
      }
    }
  }

  .menu-bar__site {
    color: $color-purple-light2;
    letter-spacing: .1px;
  }

  .menu-bar__site--vakmensen {
    color: $color-vakmensen;
    line-height: 1.2em;
  }

  .menu-bar__opener {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .link {
      outline-offset: -2px;
    }
  }

  .menu-bar__menu-opener {
    .link__menu-wrapper {
      font-size: 13px;
      font-weight: 600;
    }

    .link__icon > svg {
      height: 20px;
      transform: translate(-3px, -3px);
      width: 20px;
    }
  }

  .menu-bar__login-link {
    @include below-notebook {
      display: none;
    }
  }

  .menu-bar__login-link-mobile {
    @include notebook {
      display: none;
    }
  }

  .menu-bar__logout-url {
    @include notebook {
      display: none;
    }
  }

  .menu-bar__notifications {
    @include below-notebook {
      display: none;
    }
  }

  .menu-bar__notifications-mobile {
    @include notebook {
      display: none;
    }
  }

  .menu-bar__profile-panel {
    @include notebook {
      display: none;
    }
  }

  .menu-bar__menu-closer {
    margin-right: 8px;

    .link__span {
      display: none;
    }

    .link__icon {
      height: 26px;
      width: 26px;
    }
  }

  &:not(.menu-bar--active) {
    .menu-bar__menu-closer {
      display: none;
    }
  }

  @include notebook {
    .menu-bar__wrapper {
      grid-column: column 1 / span 14;
    }

    .menu-bar__closer,
    .menu-bar__opener {
      display: none;
    }
  }

  @include desktop {
    .menu-bar__wrapper {
      grid-column: column 1 / span 16;
    }
  }

  &:not(.menu-bar--logged-in) {
    .menu-bar__notifications {
      display: none;
    }

    .menu-bar__notifications-mobile {
      display: none;
    }

    .menu-bar__profile-panel {
      display: none;
    }

    .menu-bar__logout-url {
      display: none;
    }
  }

  &:not(.menu-bar--logged-out) {
    .menu-bar__login-link {
      display: none;
    }

    .menu-bar__login-link-mobile {
      display: none;
    }
  }
}

.menu-bar__profile-button {
  cursor: pointer;
}

.menu-bar--logged-in .menu-bar__profile-button {
  @include below-notebook {
    background: none;
    display: block;
    height: auto;
    padding-left: 21px;
    width: 100%;
    z-index: 99999;
  }

  .menu-panel-profile__icon {
    @include below-notebook {
      background: rgba(255, 255, 255, .15);
      border-radius: 50%;
      display: block;
      height: 46px;
      left: 21px;
      padding: 0;
      text-align: center;
      width: 46px;

      &:hover {
        background: rgba(255, 255, 255, .3);
      }
    }
  }
}

.menu-bar--active {
  .menu-bar__grid {
    @include mobile-only {
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  .menu-bar__menu-opener {
    display: none;
  }
}

.menu-bar--specified {
  .menu-bar__logo {
    padding-right: 0;
  }
}

.menu-bar--simple {
  &:not(.menu-bar--no-label) .menu-bar__logo {
    padding-right: 0;
  }

  .menu-panel {
    .menu-panel__static {
      padding-right: 20px;
    }

    .menu-panel__links .menu-link {
      .link__link {
        display: none;
      }
    }

    .menu-panel__static .menu-link {
      display: none;
    }

    .translation-menu {
      display: none;
    }
  }
}

.menu-bar--no-label {
  .menu-bar__site {
    display: none;
  }

  .menu-bar__logo {
    .menu-bar-site-sector {
      display: none;
    }
  }

  .menu-panel {
    .menu-panel__links .menu-link {
      display: none;
    }

    .menu-bar__site {
      display: none;
    }
  }
}

.menu-bar--campaign-nolabel {
  .menu-bar__site {
    display: none;
  }

  .menu-bar__logo {
    .menu-bar-site-sector {
      display: none;
    }
  }

  .menu-panel {
    .menu-panel__static {
      padding-right: 20px;
    }

    .menu-panel__links .menu-link {
      display: none;
    }

    .menu-panel__static .menu-link {
      display: none;
    }

    .translation-menu {
      display: none;
    }

    .menu-bar__site {
      display: none;
    }
  }
}

.menu-bar--probeerdebond {
  .menu-bar__site {
    display: none;
  }

  .menu-bar__opener {
    display: none;
  }

  .menu-bar__logo {
    background-color: transparent;

    .menu-bar-site-sector {
      display: none;
    }
  }

  .menu-panel {
    .menu-panel__static {
      padding-right: 20px;
    }

    .menu-panel__links .menu-link {
      display: none;
    }

    .menu-panel__static .menu-link {
      display: none;
    }

    .translation-menu {
      display: none;
    }

    .menu-bar__site {
      display: none;
    }
  }
}

.menu-bar:not(.menu-bar--raffle-enabled) {
  .menu-bar__search-button {
    display: none;
  }
}

.navigation-bar {
  height: $navigation-height;
  position: relative;
  z-index: 1000; // note that popups are contained within

  &.navigation-bar--clean {
    .navigation-bar__language-switch {
      display: none;
    }
  }

  .navigation-bar__grid {
    @include grid-container;

    background-color: $color-purple;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .25);
    left: 0;
    max-width: none;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .navigation-bar__wrapper {
    display: flex;
    flex-direction: row;
    grid-column: column 1 / span 16;
    justify-content: space-between;

    &:first-child {
      align-items: center;
      background-color: $color-purple-dark;
      display: none;
      height: fit-content;
      justify-content: flex-end;

      @include notebook {
        display: flex;
      }
    }
  }

  .navigation-bar__logo {
    background-color: $color-white;
    border-bottom-right-radius: 11px;
    display: flex;
    margin-bottom: 12px;
    padding: 0 13px;
    z-index: 1001;
  }

  .navigation-bar__secondary-links {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: $navigation-secondary-links-height;

    .link--menu-bar .link__menu-wrapper {
      font-weight: 400;
      height: fit-content;
    }
  }

  .navigation-bar__primary-links {
    display: flex;
    flex-flow: row nowrap;
    height: 60px;

    > div:not(.navigation-bar__logo) {
      display: none;

      @include notebook {
        display: flex;
      }
    }

    .link {
      width: 100%;

      .link__icon--after {
        display: none;
      }
    }

    .menu-panel--cluster .link .link__icon--after {
      display: inline-block;
      top: 0;
    }
  }

  .navigation-bar__buttons {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    padding-right: 10px;

    .navigation-bar__hamburger-button {
      .link .link__icon--after {
        margin-left: 0;
      }

      @include notebook {
        display: none;
      }
    }

    .button--primary-compact {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .menu-panel-notification {
      display: none;
      padding: 0;

      @include notebook {
        display: block;
      }
    }

    .navigation-bar__search {
      background: rgba(255, 255, 255, .15);
      border-radius: 50%;
      color: #fff;
      display: flex;
      display: block;
      height: 32px;
      text-align: center;
      width: 32px;

      .link {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;

        .link__icon--after {
          margin-left: 0;
        }

        .link__icon {
          height: calc(var(--link-line-height) * .65);
          width: calc(var(--link-line-height) * .65);
        }
      }
    }
  }

  .navigation-bar__close-button {
    color: $color-white;
    z-index: 1001;

    @include notebook {
      display: none;
    }
  }

  .navigation-bar__hamburger-button.hide,
  .navigation-bar__close-button.hide {
    display: none;
  }

  .navigation-bar__profile {
    color: $color-white;
    display: none;
    font-size: 13px;
    position: relative;

    @include notebook {
      display: flex;
    }

    &.active {
      display: flex;
    }

    .navigation-bar__profile-container {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      gap: 10px;
      position: relative;
    }

    svg {
      height: 50%;
      width: 50%;

      g {
        stroke: $color-white;
      }
    }

    .navigation-bar__profile-name {
      font-size: 14px;
    }

    .navigation-bar__profile-icon {
      align-items: center;
      border: 1px solid $color-white;
      border-radius: 50%;
      color: $color-white;
      content: '';
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;

      &::before {
        border-color: transparent transparent $color-white transparent;
        border-style: solid;
        border-width: 5px;
        bottom: -14px;
        content: '';
        opacity: 0;
        position: absolute;
      }
    }

    &:has(.navigation-bar__profile-popup.active) .navigation-bar__profile-icon::before {
      opacity: 1;
    }

    .navigation-bar__profile-popup {
      background-color: $color-white;
      border-radius: 0 0 11px 11px;
      color: $color-purple-dark;
      height: fit-content;
      max-width: 400px;
      min-width: 200px;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 45px;
      transition: all .2s linear;
      visibility: hidden;
      width: 30vw;

      &.active {
        opacity: 1;
        visibility: visible;
      }

      svg g {
        stroke: $color-purple;
      }

      .navigation-bar__profile-name {
        padding: 25px;
      }
    }

    .navigation-bar__profile-dropdown {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding: 0;

      .bold {
        font-size: 16px;
      }

      li {
        border-top: 1px solid $color-purple-lightest;
        list-style-type: none;

        a {
          display: flex;
          flex-flow: row nowrap;
          gap: 15px;
          padding: 20px 25px;
          text-decoration: none;

          div {
            display: block;
          }
        }

        &:last-child a {
          padding-bottom: 25px;
        }
      }

      svg {
        height: 15px;
        margin-top: 5px;
        width: 20px;
      }
    }
  }

  .navigation-bar__overlay {
    background-color: $color-purple;
    display: none;
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 1000;

    &.active {
      display: block;

      @include notebook {
        display: none;
      }
    }

    &:has(.menu-panel:not(.menu-panel--inactive)) {
      overflow-y: hidden;
    }

    .menu-link .link__menu-wrapper {
      padding: 0;
    }

    .navigation-bar__profile {
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;

      svg {
        height: 32px;
        width: 32px;

        g {
          stroke: $color-white;
        }
      }

      .navigation-bar__profile-name {
        display: flex;
        flex-direction: column;
        font-size: 16px;

        .navigation-bar__profile-fullname {
          font-weight: bolder;
        }

        .navigation-bar__profile-link {
          align-items: center;
          color: $color-white;
          cursor: pointer;
          display: flex;
          font-weight: 400;
        }
      }

      .navigation-bar__profile-icon svg {
        height: 50%;
        width: 50%;
      }
    }

    .navigation-bar__profile-overlay {
      background-color: $color-white;
      bottom: 0;
      display: none;
      font-size: 14px;
      left: 0;
      overflow-x: hidden;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 60px;
      position: absolute;
      right: 0;
      top: 0;

      .bold {
        font-size: 16px;
      }

      &.active {
        display: block;
      }

      .navigation-bar__profile-name {
        padding: 20px 0;

        .navigation-bar__profile-fullname {
          font-weight: bolder;
        }
      }

      .navigation-bar__back-button {
        padding-left: .5rem;
        padding-top: 0;
      }

      .navigation-bar__profile-dropdown {
        display: flex;
        flex-direction: column;
        padding: 0;

        li {
          border-bottom: 1px solid $color-purple-lightest;
          list-style-type: none;
        }

        a {
          display: flex;
          flex-flow: row nowrap;
          gap: 15px;
          padding: 20px 0;
          text-decoration: none;
        }

        .navigation-bar__profile-item-text {
          display: flex;
          flex-flow: column nowrap;
        }

        svg {
          height: 15px;
          margin-top: 5px;
          width: 20px;
        }
      }
    }

    .navigation-bar__links-overlay {
      display: none;
      flex-direction: column;
      height: fit-content;
      min-height: calc(115vh + 120px);

      &.active {
        display: flex;
      }

      .menu-panel__link-group {
        .menu-link--return {
          margin-left: 0;
          padding-left: 0;
        }

        .menu-link__link {
          margin-bottom: 5px;
          margin-left: 10px;
          margin-right: -10px;
          margin-top: 5px;
        }
      }

      .menu-panel__return .menu-link__link,
      .menu-panel__link-category .menu-panel__link-group .menu-link__link {
        margin-left: 0;
        margin-right: 0;
      }

      .navigation-bar__sector-item {
        .menu-panel {
          overflow-x: hidden;
          top: 60px;
        }

        .menu-link .link__menu-wrapper {
          padding-left: 25px;
          padding-right: 25px;
        }

        .menu-panel--cluster .menu-panel__return {
          margin-bottom: 0;
        }

        .link__link {
          display: flex;
          font-size: 16px;
          justify-content: space-between;
          width: 100%;
        }

        .link__icon {
          display: inline-block;
        }
      }

      .navigation-bar__primary-links {
        flex-direction: column;
        height: fit-content;
        padding-bottom: 15px;
        padding-left: 25px;
        padding-right: 25px;

        &:has(.menu-link--popup-menu.menu-link--active) {
          padding-left: 0;
          padding-right: 0;
        }

        .link__link {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .link__span {
          font-size: 16px;
        }

        .link__icon {
          display: inline-block;
        }

        .menu-panel__return {
          padding: 0 25px;

          .link__link {
            height: 60px;
            justify-content: flex-start;
          }
        }

        > div.menu-link {
          display: block;
        }
      }

      .navigation-bar__secondary-links {
        align-items: flex-start;
        background-color: $color-purple-dark;
        color: $color-white;
        flex: 1 1 100%;
        flex-direction: column;
        height: calc(100% + 120px);
        padding: 15px 25px 90px;

        .menu-link {
          padding: 15px 0;

          .link__span {
            font-size: 14px;
          }
        }

        .navigation-bar__logout-button.hide {
          display: none;
        }
      }
    }

    .navigation-bar__call-to-action {
      align-items: center;
      background-color: $color-purple;
      bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      gap: 10px;
      height: 70px;
      left: 0;
      padding: 10px;
      position: fixed;
      right: 0;

      > * {
        flex: 1 1 50%;
      }

      .button {
        align-items: center;
        display: flex;
        font-size: 16px;
        font-weight: bolder;
        height: 50px;
        justify-content: center;
      }

      .navigation-bar__login-button {
        align-items: center;
        color: $color-white;
        display: flex;
        justify-content: center;

        &.hide {
          display: none;
        }

        svg g {
          stroke: $color-white;
        }
      }
    }
  }

  &.logged-in {
    .navigation-bar__profile-icon {
      border-color: $color-neon-green;
    }
  }

  .menu-panel-profile {
    display: none;
  }

  .menu-panel__link-group {
    .menu-link {
      .menu-link__link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-right: 10px;

        @include notebook {
          margin-bottom: 5px;
          margin-right: 0;
          margin-top: 5px;
          width: calc(100% - 10px);
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $color-white-opaque;
      }
    }
  }

  .menu-panel .menu-link .menu-panel .menu-link__link {
    display: inline;
  }

  .menu-panel--sector > .menu-panel__links {
    @include notebook {
      overflow: hidden;
    }

    > .menu-panel__link-category:first-child {
      @include notebook {
        max-width: 60vw;
        min-width: 60vw;
      }
    }
  }

  .menu-panel--cluster {
    border-radius: 0 0 0 11px;
  }

  .menu-link--sector-item {
    position: relative;
  }

  .menu-link--sector-item.menu-link--active > .link > .link__menu-wrapper,
  .menu-link--popup-menu.menu-link--active > .link > .link__menu-wrapper {
    opacity: .5;
  }

  .menu-panel--popup {
    background-color: $color-white;
    border-radius: 0 0 11px 11px;
    width: 100%;

    @include notebook {
      left: 88px;
      max-width: 80vw;
      min-width: calc(246px + 60vw);
      top: 96px;
      width: auto;
    }

    > .menu-panel__links > .menu-panel__link-category:last-child {
      background-color: inherit;
      border-radius: 11px;
      min-width: 100%;
    }

    .menu-panel__link-category-title {
      margin: 0;
    }

    .menu-panel__cta .menu-panel__link-group {
      padding-top: 10px;
    }
  }

  .bold {
    font-weight: bolder;
  }
}
