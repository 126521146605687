
.article-form {
  padding: var(--component-margin) 0;
  position: relative;

  .article-form__grid {
    @include grid-container;
  }

  .article-form__wrapper {
    grid-column: var(--layout-content-large);
  }
}

.article-form__message {
  display: grid;
  grid-column: span 5;
  margin: auto;
  width: 85%;

  p {
    font-size: 14px;
  }

  @include notebook {
    width: 72%;
  }

  @include desktop {
    width: 62%;
  }
}
