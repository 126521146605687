.settings-group {
  margin-bottom: 10px;

  .settings-group__grid {
    @include grid-container;
  }

  .settings-group__setting {
    border: 1px solid $color-purple-lightest;
    border-radius: 2px;
    grid-column: var(--layout-grid-inline);
  }

  .settings-group__settings-list {
    margin: 0;
    padding: 0;
  }

  .settings-group__settings-footer {
    padding: 10px;
  }

  .form-fieldset {
    padding-bottom: 1.25rem;

    &__legend,
    &__description {
      font-size: 14px;

      @include tablet {
        font-size: 16px;
      }
    }

    &__description {
      line-height: 1.5;
    }

    .form-fieldset__description {
      flex-grow: 1;
    }

    .form-fieldset__legend {
      @include notebook {
        margin-bottom: 0;
        margin-left: 0;
      }

      :not(:last-child) {
        @include notebook {
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }
  }

  .form-row {
    border-bottom: 1px solid $color-purple-lightest;

    &:last-child {
      border: none;
    }
  }

  &.settings-group--single {
    padding: 1.875rem 1.875rem 0;

    .settings-group__setting {
      padding: 20px 20px 0;
    }

    .form-fieldset {
      display: flex;
      flex-wrap: wrap;

      @include tablet {
        flex-wrap: nowrap;
      }
    }

    .profile-setting {
      margin-left: auto;

      @include tablet {
        margin-left: 0;
      }
    }
  }

  &.settings-group--multi {
    .form-fieldset {
      &__legend,
      &__description {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
      }

      &__legend {
        padding-top: 1.875rem;
      }

      &__description {
        padding-bottom: .75rem;
      }
    }

    .form-row {
      margin: 0 1.875rem;
      padding: .625rem 0;
    }
  }
}
