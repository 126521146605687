.hero-highlight {
  overflow-x: hidden;
  position: relative;

  .hero-highlight__container {
    display: flex;
    flex-direction: column;

    &::after {
      background: $color-grey;
      border-top-left-radius: var(--round-corner-size);
      bottom: 0;
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .hero-highlight__item {
    color: $color-white;
    display: flex;
    height: 66.66vh;
    position: relative;

    &:focus,
    &:hover {
      color: $color-white;

      .hero-highlight__title span {
        background-size: 100% 1px;
      }
    }

    &:last-child {
      .hero-highlight__content {
        margin-bottom: var(--round-corner-size);
      }
    }

    @include notebook {
      height: 50vh;

      &:first-child {
        .hero-highlight__content {
          margin-left: var(--single-grid-column-with-gutter);
        }
      }
    }
  }

  .hero-highlight__image {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, .8) 0%, rgba(0, 0, 0, 0) 60%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .picture {
      height: 100%;
      width: 100%;
    }
  }

  .hero-highlight__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 16px 16px 30px;
    position: relative;

    @include notebook {
      padding: 30px;
    }
  }

  .hero-highlight__tag {
    margin-bottom: 10px;

    .tag {
      background-color: $color-white;
      color: $color-purple-dark;
    }
  }

  .hero-highlight__title {
    @include typography(heading-3);

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }

  .hero-highlight__title-simple {
    @include typography(heading-1);
  }

  .hero-highlight__date {
    margin-bottom: 10px;
  }

  &--background-purple {
    .hero-highlight__container {
      &::after {
        background: $color-purple;
      }
    }
  }

  &--stripe-left-big {
    .hero-highlight__container {
      &::after {
        @include desktop {
          left: var(--single-grid-column-with-gutter);
        }
      }
    }
  }
}

.hero-highlight--two-items {
  .hero-highlight__item {
    flex-basis: 50%;
    height: 50vh;
  }

  @include notebook {
    .hero-highlight__container {
      flex-direction: row;
    }

    .hero-highlight__item:first-child .hero-highlight__content {
      padding-left: 0;
    }

    .hero-highlight__content {
      margin-bottom: var(--round-corner-size);
    }
  }
}

.hero-highlight--simple {
  .hero-highlight__container::after {
    display: none;
  }

  .hero-highlight__item:last-child {
    .hero-highlight__content {
      margin-bottom: calc(var(--round-corner-size) / 2);
    }
  }
}
