
@mixin fix-antialiasing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Chrome scroll lag @ retina fix-like
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
}
