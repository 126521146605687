
:root {
  --paragraph-font-size: 16px;
  --paragraph-font-stack: #{$graphik-font-stack};
  --paragraph-font-style: normal;
  --paragraph-font-variant: normal;
  --paragraph-font-weight: #{get-graphik-weight(regular)};
  --paragraph-letter-spacing: -.3px;
  --paragraph-line-height: 1.4em;
  --paragraph-margin-bottom: 1rem;
  --paragraph-margin-top: 1rem;
  --paragraph-word-spacing: normal;

  @include desktop {
    --paragraph-font-size: #{get-fluid-value(16px, 18px)};
  }
}
