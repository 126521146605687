.article-table {
  padding-bottom: 20px;

  // use standard for component?
  padding-top: 20px;

  .article-table__grid {
    @include grid-container;
  }

  .article-table__wrapper {
    grid-column: var(--layout-content-large);
  }

  .article-table--title {
    @include typography(heading-3);
  }

  .article-table__table {
    caption-side: bottom;
    max-width: 100%;
    overflow: auto;
    padding-top: 10px // i know
  }

  .article-table__cell {
    background-color: $color-grey;
    padding: 10px;
  }

  .article-table__heading {
    // nothing for now
  }

  .article-table__content {
    // nothing for now
  }

  .article-table__left {
    text-align: left;
  }

  .article-table__right {
    text-align: right;
  }

  .article-table__center {
    text-align: center;
  }

  .article-table__caption {
    @include typography(caption, true, false, false);

    color: $color-purple-light;
    margin-left: 2px; // heh
    text-align: left;
  }
}
