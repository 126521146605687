
:root {
  --link-big-font-size: 18px;
  --link-big-font-stack: #{$graphik-font-stack};
  --link-big-font-style: normal;
  --link-big-font-variant: normal;
  --link-big-font-weight: #{get-graphik-weight(regular)};
  --link-big-letter-spacing: -.27px;
  --link-big-line-height: 26px;
  --link-big-margin-bottom: 0;
  --link-big-margin-top: 0;
  --link-big-word-spacing: normal;
}
