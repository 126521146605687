
.article-dual-content {
  padding: var(--component-margin) 0;
  position: relative;

  .article-dual-content__grid {
    @include grid-container;

    row-gap: 25px;

    @include notebook {
      row-gap: 40px;
    }
  }

  .article-dual-content__content-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    grid-column: var(--layout-content-large);

    @include notebook {
      flex-direction: row;
    }
  }

  .article-dual-content__content-item {
    flex: 1;
  }

  .article-dual-content__heading {
    @include typography(heading-2);

    margin-bottom: 1rem;
  }

  .article-dual-content__links {
    display: grid;
    gap: 8px;
    grid-column: var(--layout-content-large);
  }
}
