.campaign-header {
  background-color: $color-purple-dark;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;

  @include tablet {
    padding-top: 0;
  }

  &::before {
    background: $color-white;
    border-top-left-radius: var(--round-corner-size);
    bottom: -1px;
    content: '';
    height: 100px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .campaign-header__grid {
    @include grid-container;
  }

  .campaign-header__wrapper-info {
    display: flex;
    flex-direction: column;
    grid-column: column 2/span 12;
    justify-content: center;
    margin-bottom: var(--component-margin);

    @include tablet {
      grid-column: column 2 / span 7;
      justify-content: start;
      margin-bottom: 0;
      padding: 50px 0 100px;
    }

    @include notebook {
      grid-column: column 2 / span 6;
      justify-content: center;
      margin-top: -100px;
      padding: 0;
    }

    .campaign-header__subtitle {
      color: $color-white;
      margin-bottom: var(--component-margin);

      @include typography(introduction, true);
    }

    .campaign-header__button {
      margin-right: var(--component-margin);
      position: relative;
    }

    .campaign-header__links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include tablet {
        // align-items: center;
        flex-direction: row;
      }
    }

    .campaign-header__microcopy {
      color: $color-white;
      display: block;
      flex: 1 0 100%;
      margin-top: calc(var(--component-margin) / 3);
      max-width: 100%;
      order: 1;

      @include typography(caption);

      @include tablet {
        max-width: 200px;
        order: initial;
      }
    }

    .campaign-header__title {
      color: $color-white;

      @include typography(heading-1);
    }

    .campaign-header__link {
      color: $color-white;
      margin-right: var(--component-margin);
      margin-top: 10px;
      order: 2;
      padding: 15px 0;

      @include tablet {
        margin-top: 0px;
        order: initial;
      }
    }

    .campagne-header__link-anchor {
      align-items: top;
      margin-top: 10px;
      order: 3;
      padding: 15px 0;
      vertical-align: top; /* here */

      @include tablet {
        margin-top: 0px;
        order: initial;
      }

      .link__icon {
        height: calc(var(--link-line-height) * .6);
        margin-bottom: 3px;
        width: calc(var(--link-line-height) * .6);
      }
    }
  }

  .campaign-header__wrapper-visual {
    border-bottom-left-radius: var(--round-corner-size);
    border-top-left-radius: var(--round-corner-size);
    grid-column: column 2/span 13;
    overflow: hidden;

    @include tablet {
      border-top-left-radius: 0;
      grid-column: column 9/span 6;
    }

    @include desktop {
      grid-column: column 9/span 8;
    }

    .campaign-header__image {
      aspect-ratio: 5 / 4;
      background-color: $color-purple-dark;
      position: relative;
      width: 100%;
    }

    .campaign-header__video {
      aspect-ratio: 5 / 4;
      background-color: $color-purple-dark;
      position: relative;
      width: 100%;

      .video-embed {
        aspect-ratio: 5 / 4;

        .video-embed__container {
          aspect-ratio: 5 / 4;
          padding-top: initial;
        }

        .video-embed__title {
          display: none;
        }

        .picture {
          aspect-ratio: 5 / 4;
        }
      }
    }
  }
}

.campaign-header--staking {
  background-color: $color-purple;
  padding-bottom: 0;
  padding-top: 0;

  &::before {
    display: none;
  }

  .campaign-header__wrapper-info {
    grid-column: column 2/span 12;
    margin-bottom: 60px;
    margin-top: 60px;
    order: 2;
    padding: 0;

    @include notebook {
      grid-column: column 3/span 10;
    }

    @include desktop {
      grid-column: column 4/span 10;
    }

    .campaign-header__subject {
      color: $color-white;
      margin-bottom: 5px;

      @include typography(introduction, true);
    }

    .campaign-header__subtitle:not(:first-child) {
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }

  .campaign-header__wrapper-visual {
    border-radius: 0;
    grid-column: column 1/span 14;
    padding-bottom: 0;

    @include desktop {
      grid-column: column 1/span 16;
    }

    .campaign-header__image {
      aspect-ratio: initial;
      height: 340px;
    }

    .campaign-header__video {
      aspect-ratio: initial;
      height: 340px;

      .video-embed {
        aspect-ratio: initial;
        height: 340px;

        .video-embed__container {
          aspect-ratio: initial;
          height: 340px;
        }

        .picture {
          aspect-ratio: initial;
          height: 340px;
        }
      }
    }
  }
}

.campaign-header--lightBrandColor {
  background-color: $color-purple;
}
