.tabbed-content {
  background-color: transparent;
  overflow-x: hidden;
  padding: var(--component-margin) 0;
  position: relative;

  .tabbed-content__grid {
    @include grid-container;
  }

  .tabbed-content__wrapper {
    grid-column: 2 / 14;
    position: relative;

    @include notebook {
      grid-column: 2 / 14;
    }

    @include desktop {
      grid-column: 2 / 16;
    }
  }

  .tabbed-content__navigation {
    grid-column: 2 / 14;
    margin-bottom: 10px;
  }

  .tabbed-content__title {
    @include typography(heading-3, false, true);
  }
}

.tabbed-content-tab-button {
  border: 1px solid $color-purple-dark;
  border-radius: 10px;
  color: $color-purple-dark;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  padding: 4px 15px 6px;

  @include typography(heading-4, false, true);

  @include notebook {
    margin-bottom: 15px;
    margin-right: 20px;
    padding: 8px 20px 10px;
  }

  &:hover {
    background-color: $color-grey-lighter;
    border: 1px solid transparent;
  }

  &.is-active {
    background-color: $color-purple-dark;
    border: 1px solid transparent;
    color: $color-white;
  }
}

.tabbed-content-tab-content {
  display: none;

  &.is-active {
    display: block;
  }
}

.tabbed-content--medium {
  .tabbed-content__wrapper {
    grid-column: var(--layout-content-large);
  }

  .card-grid {
    padding: 0;
  }

  .tabbed-content__title {
    font-family: var(--heading-2-font-stack);
    font-feature-settings: normal;
    font-size: var(--heading-2-font-size);
    font-style: var(--heading-2-font-style);
    font-variant: var(--heading-2-font-variant);
    font-weight: var(--heading-2-font-weight);
    grid-column: 2 / 16;
    letter-spacing: var(--heading-2-letter-spacing);
    line-height: var(--heading-2-line-height);
    margin-bottom: 30px;
    text-transform: var(--heading-2-text-transform);
    word-spacing: var(--heading-2-word-spacing);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
      margin-right: var(--single-grid-column-with-gutter);
    }
  }

  .tabbed-content__subtitle {
    font-family: var(--heading-4-font-stack);
    font-feature-settings: normal;
    font-size: var(--heading-4-font-size);
    font-style: var(--heading-4-font-style);
    font-variant: var(--heading-4-font-variant);
    font-weight: var(--heading-4-font-weight);
    line-height: var(--heading-4-line-height);
    margin-bottom: 15px;
  }

  .tabbed-content-tab-button {
    font-family: var(--heading-5-font-stack);
    font-size: var(--heading-5-font-size);
    font-style: var(--heading-5-font-style);
    font-variant: var(--heading-5-font-variant);
    font-weight: var(--heading-5-font-weight);
    line-height: var(--heading-5-line-height);

    @include notebook {
      margin-bottom: 5px;
      margin-right: 7px;
      padding: 7px 20px 8px;
    }
  }
}

.section-wrapper--background-grey {
  .tabbed-content-tab-button:hover {
    background-color: $color-purple-dark;
    color: $color-white;
  }

  .tabbed-content-tab-button.is-active:hover {
    background-color: $color-purple-dark;
    color: $color-white;
  }
}

.section-wrapper--background-white {
  .tabbed-content-tab-button:hover {
    background-color: $color-purple-dark;
    color: $color-white;
  }

  .tabbed-content-tab-button.is-active:hover {
    background-color: $color-purple-dark;
    color: $color-white;
  }
}
