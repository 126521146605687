.member-list {
  max-width: 100%;

  @include tablet {
    max-width: 500px;
  }

  &__list {
    margin-top: 50px;
  }

  &__title {
    margin-bottom: 12px;

    @include tablet {
      font-size: medium;
    }
  }

  &__list-items {
    list-style-type: none;
    padding-inline-start: 0;
  }

  &__list-item {
    display: flex;
    margin: 12px 0;
    min-width: 240px;
  }

  &__details {
    flex-grow: 1;
  }

  &__avatar {
    border-radius: 100%;
    height: 40px;
    margin-right: 12px;
    width: 40px;

    @include tablet {
      height: 60px;
      width: 60px;
    }
  }

  &__name,
  &__role {
    @include tablet {
      font-size: medium;
    }
  }

  &__name {
    @include tablet {
      margin-top: 5px;
    }
  }

  &__role {
    line-height: 1;

    @include tablet {
      line-height: 1.5;
    }
  }
}

.modal {
  &.modal-member-list {
    .modal__container {
      width: 100%;

      @include tablet {
        width: auto;
      }
    }

    .modal__content {
      margin-top: -20px;
    }
  }
}
