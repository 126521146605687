.group-overview {
  .header-container-grid {
    @include grid-container;

    .header-container {
      display: flex;
      flex-wrap: wrap;
      grid-column: var(--layout-grid-inline);

      @include tablet {
        flex-wrap: nowrap;
      }

      .article-header {
        display: block;
        flex-basis: 100%;

        @include tablet {
          flex-basis: auto;
          flex-grow: 0;
        }

        .article-header__grid {
          display: block;
        }

        .article-header__title {
          font-size: x-large;
        }

        .article-header__intro-meta-wrapper {
          padding-bottom: 0;
          padding-top: calc(var(--component-margin) / 2);

          @include tablet {
            padding-bottom: var(--component-margin);
          }
        }

        .article-header__introduction {
          font-size: medium;
          line-height: 1.5;
        }
      }

      .button-container {
        width: 100%;

        @include tablet {
          display: inline-block;
          margin: 0;
          padding-left: calc(var(--component-margin) * 2);
          padding-top: calc(var(--component-margin) * 3.5);
          width: auto;
        }
      }
    }
  }
}
