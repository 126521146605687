
.button-list {
  position: relative;

  .button-list__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .button-list__buttons {
    margin-bottom: -10px;

    + .button-list__buttons {
      margin-top: 20px;
    }
  }
}

.button-list--horizontal {
  .button-list__buttons {
    display: flex;
    flex-flow: row wrap;
  }

  .button,
  .icon-button,
  .share-button {
    flex-basis: auto;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.button-list--vertical {
  .button-list__buttons {
    display: flex;
    flex-direction: column;
  }

  .button,
  .icon-button,
  .share-button {
    flex-basis: 100%;

    &:not(:last-child) {
      margin-bottom: var(--paragraph-margin-bottom);
    }
  }
}

.button-list.campaign {
  // @include grid-container;

  // .button-list__wrapper {
  //   display: flex;
  //   grid-column: 2 / 14;
  //   justify-content: right;
  // }

  .button-list__title {
    @include typography(caption);

    margin-bottom: 0;
    margin-right: 15px;
  }

  .button-list__buttons {
    margin: 2px 0 0;
  }

  .share-button {
    background: none;
    border-radius: 0;
    color: $color-purple;
    height: 16px;
    margin-bottom: 0;
    padding: 0;
    width: 16px;

    &:last-child {
      margin-right: 0;
    }

    .share-button__icon {
      height: 100%;
      padding: 0;
      width: 100%;
    }
  }
}

.button-list--share-buttons {
  display: flex;
  flex-direction: row;

  .button-list__title {
    @include typography(caption);

    margin-bottom: 0;
    margin-right: 15px;
  }

  .button-list__buttons {
    margin: 2px 0 0;
  }

  .share-button {
    background: none;
    border-radius: 0;
    color: $color-purple;
    height: 16px;
    margin-bottom: 0;
    padding: 0;
    width: 16px;

    &:last-child {
      margin-right: 0;
    }

    .share-button__icon {
      height: 100%;
      padding: 0;
      width: 100%;
    }
  }
}
