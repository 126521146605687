
.share-button {
  @include typography(button);

  background-color: $color-grey-light;
  border: 0;
  border-radius: 20px;
  color: $color-grey-extradark;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 40px;

  &:hover,
  &:focus {
    background-color: $color-grey-extradark;
    color: $color-grey-extralight;
  }

  .share-button__icon {
    display: block;
    height: var(--button-line-height);
    padding: .1em .1em .2em;
    width: var(--button-line-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
