.profile-setting {
  display: flex;

  @include tablet {
    .profile-setting__label {
      flex-grow: 1;
    }
  }

  &.large {
    padding: 6px 12px;
  }

  &.regular {
    flex-wrap: wrap;

    .profile-setting__label {
      flex-basis: 100%;
      margin-top: 2px;
    }

    .toggle {
      padding: 0 12px;
    }

    @include tablet {
      flex-wrap: nowrap;
    }
  }

  &__label {
    font-size: 14px;

    @include tablet {
      font-size: 16px;
    }
  }

  .toggle {
    margin-left: auto;
    margin-top: 12px;

    @include tablet {
      margin-left: 0;
      margin-top: 0;
    }
  }
}
