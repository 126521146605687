.mpForm {
  .mpFormTable {
    border: none;
    list-style: none;
    width: 100%;

    li::before {
      content: none;
      display: none;
      margin: 0;
    }

    li {
      margin: 0;
      padding-left: 0;
    }

    table {
      border: none;
    }

    td {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .mpLabelRow {
      display: block;
      max-width: 100%;
      position: relative;
    }

    .mpFormLabel {
      @include typography(heading-5, false, true, false);

      display: block;
      margin: 8px 0;
      user-select: none;
    }

    .mpFormField {
      align-items: center;
      display: flex;
      gap: 5px;
      position: relative;
      width: 100%;

      &:not(:has(input)) {
        .labelHidden {
          min-height: 60px;
          min-width: 64px;
          padding-top: 5px;
        }
      }

      input:not([type='radio'], [type='checkbox']),
      textarea,
      select {
        @include typography(field-value);

        appearance: auto;
        background-color: transparent;
        border: 1px solid $color-purple-lighter;
        border-radius: 2px;
        color: inherit;
        display: inline-block;
        flex: 1 1 100%;
        margin: 0;
        min-height: 60px;
        min-width: 64px;
        overflow: hidden;
        padding: 10px 15px;
        position: relative;
        transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
        vertical-align: bottom;
        white-space: nowrap;
        width: 100%;

        &:hover:not(:disabled) {
          border-color: var(--text-field-color-focus-hover);
        }

        &:disabled {
          opacity: .4;
          pointer-events: none;
        }

        &:focus {
          border-color: var(--text-field-color-focus-hover);
          outline: none;
        }
      }

      textarea {
        resize: none;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      input[type='radio'],
      input[type='checkbox'] {
        @include typography(paragraph);

        border: 1px solid $color-purple-lighter;
        border-radius: 50%;
        color: $color-grey-extradark;
        cursor: pointer;
        display: block;
        height: 20px;
        width: 20px;
      }

      &:has(input[type='radio'], input[type='checkbox']) {
        li {
          display: flex;
          gap: 5px;
        }

        .mpMultipleLabel {
          @include typography(paragraph);
        }
      }

      .sublabel {
        display: none;
      }
    }
  }

  .mpTwoColumnLayout td:has(table.mpQuestionTable),
  ul.mpTwoColumnLayout {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

    @include notebook {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  table.mpQuestionTable:has(.mpSpacerCell) {
    border-spacing: 0;
    height: 1px;

    hr {
      background-color: $color-purple-dark;
      height: 100%;
    }
  }

  .mpErrorSummary {
    @include typography(caption);

    border: 1px solid;
    border-radius: 5px;
    color: $color-error;
    margin: 20px 0;
    padding: 20px;

    ul li {
      @include typography(caption);

      list-style: none;
      margin: 0;
      padding: 0;
    }

    li::before {
      content: '\26A0';
      margin-left: 0;
    }
  }

  .inlineValidated {
    input {
      border-color: $color-error;
    }

    .mpErrorRow {
      @include typography(caption);

      color: $color-error;
      margin-top: 5px;

      label::before {
        content: '\26A0';
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .submitButton {
    @include typography(button);

    background-color: $color-orange;
    border: 0;
    border-radius: 10px;
    box-shadow: inset 0px -1px 0px $color-orange-dark;
    color: $color-purple-dark;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    max-width: min(100%, 520px);
    min-width: 60px;
    overflow: hidden;
    padding: 15px 22px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    transition: color $easing-change, background-color $easing-change, border-color $easing-change;
    user-select: none;
    vertical-align: bottom;
    white-space: nowrap;
    width: auto;

    &:hover:not(.button--disabled):not(:disabled),
    &:focus:not(.button--disabled):not(:disabled),
    &.button--selected {
      background-color: $color-orange-hover;
    }
  }
}

#ui-datepicker-div {
  select,
  select option {
    color: $color-purple-dark;
  }
}
