.card-highlight {
  padding: var(--component-margin) 0;
  position: relative;

  .card-highlight__grid {
    @include grid-container;
  }

  .card-highlight__wrapper {
    grid-column: var(--layout-content);
  }

  .card-highlight__title {
    @include typography(heading-3);

    margin-bottom: 25px;
  }

  .card-highlight__cards {
    display: grid;
    gap: var(--grid-gutter);

    @include notebook {
      grid-template-columns: 1fr 1fr;
    }
  }
}
