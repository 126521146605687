.contact-card {
  background: $color-white;
  color: $color-purple-light;
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
  padding: 30px;

  .contact-card__image {
    border-radius: 50%;
    height: 82px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
    width: 82px;

    @include notebook {
      grid-row: 1 / 3;
      height: 120px;
      width: 120px;
    }

    @include desktop {
      height: 150px;
      width: 150px;
    }
  }

  .contact-card__link {
    color: inherit;
    display: block;

    &:focus,
    &:hover {
      .contact-card__name span {
        background-size: 100% 1px;
      }
    }
  }

  .contact-card__name {
    @include typography(introduction);

    color: $color-purple-dark;

    span {
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      transition: background-size .2s linear;
      vertical-align: baseline;
    }
  }

  .contact-card__title,
  .contact-card__contact-info {
    @include typography(caption);
  }

  .contact-card__title {
    &[href] {
      color: $color-purple-light;
      transition: color .25s ease-out;

      &:focus,
      &:hover {
        color: $color-purple-dark;
      }
    }
  }

  .contact-card__link-icon {
    display: inline-block;
    height: calc(var(--link-line-height) * .95);
    margin-left: .375rem;
    position: relative;
    top: -1px;
    vertical-align: text-bottom;
    width: calc(var(--link-line-height) * .95);

    > svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .contact-card__contact-info {
    column-gap: var(--grid-gutter);
    display: grid;
    grid-column: 1 / -1;
    row-gap: 15px;

    @include notebook {
      align-self: end;
      grid-column: 2;
      grid-template-columns: 1fr 1fr;
    }
  }

  .contact-card__list-label {
    font-weight: 600;
  }

  .contact-card__list-value {
    color: currentColor;
    margin: 0;

    @include style-fancy-link-underline;
  }
}

.contact-card-rating {
  background: transparent;
  display: flex;

  .contact-card__image {
    flex: 0 0 auto;
  }

  .contact-card__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .contact-card__rating {
    height: 20px;
    margin-bottom: 15px;
    width: 100px;
  }

  .contact-card__rating-inner {
    height: 20px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;

    svg {
      height: 20px;
      stroke: transparent;
      width: 100px;
    }
  }

  .contact-card__testimonial {
    @include typography(caption, false, false);

    color: $color-purple-dark;
    flex: 0 1 auto;
    padding-bottom: 15px;
  }

  .contact-card__name {
    @include typography(caption, false, false);

    color: inherit;
  }
}
