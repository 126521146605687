.profile-details {
  grid-column: var(--layout-grid-inline);

  .login-details__public-profile {
    display: block;
    font-size: var(--paragraph-font-size);
    margin-bottom: 30px;
    text-align: left;

    @include mobile {
      transform: translate(0px, 0px);
    }

    @include tablet {
      text-align: right;
      transform: translate(23px, -5px);
    }

    @include notebook {
      text-align: right;
      transform: translate(25px, -18px);
    }

    @include desktop {
      text-align: right;
      transform: translate(20%, -18px);
    }
  }

  &--public {
    .profile-details__content {
      padding-top: 12px;
    }
  }

  .profile-details__header {
    display: block;
    margin-bottom: 36px;
    min-height: 60px;

    @include tablet {
      margin-bottom: 55px;
    }

    .profile-details__image {
      border-radius: 50%;
      display: inline-block;
      float: left;
      height: 75px;
      margin-right: 10px;
      position: relative;
      transform: translateZ(0);
      width: 75px;

      @include tablet {
        height: 80px;
        margin-right: 20px;
        width: 80px;
      }
    }

    .profile-details__content {
      display: inline-block;
      width: 68%;

      @include notebook {
        width: 75%;
      }
    }

    .profile-details__email,
    .profile-details__info {
      float: left;
      width: 100%;
    }

    .profile-details__name {
      @include typography(heading-2);

      display: inline-block;
      margin-bottom: 10px;
      width: 100%;

      @include notebook {
        margin: 0;
        width: auto;
      }
    }

    .profile-details__date {
      font-size: 14px;
      line-height: 1.5;
    }

    .profile-details__function {
      font-size: 14px;
      line-height: 1.5;
    }

    .profile-details__label {
      left: 0;
      margin-bottom: 6px;
      margin-right: 10px;

      @include notebook {
        left: 12px;
        margin-bottom: 0;
        position: relative;
        top: -5px;
      }
    }

    .profile-details__link {
      color: #371548;
      display: block;
      font-size: 14px;
      text-decoration: none;

      .information__icon {
        display: inline-block;
        transform: translateY(5px);

        svg {
          stroke: inherit;
        }
      }

      .profile-details__link-content {
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: 0 1px;
        transition: background-size .2s linear;
      }

      &:hover {
        .profile-details__link-content {
          background-size: 100% 1px;
        }
      }
    }
  }

  .profile-details__email,
  .profile-details__info {
    p {
      margin-bottom: 25px;

      @include tablet {
        margin-bottom: 62px;
      }
    }
  }

  .profile-details__first-column {
    display: block;
    float: left;
    width: 100%;

    @include tablet {
      width: 25%;
    }
  }

  .profile-details__second-column {
    display: block;
    float: left;
    width: 75%;
    width: 100%;

    @include tablet {
      width: 75%;
    }
  }
}
