.article-testimonial {
  padding: var(--component-margin) 0;
  position: relative;

  .article-testimonial__container {
    padding: 0 0 50px;
    position: relative;

    @include notebook {
      padding-top: 50px;
    }
  }

  .article-testimonial__grid {
    @include grid-container;
  }

  .article-testimonial__wrapper {
    grid-column: var(--layout-content-large);

    @include notebook {
      padding: 0 45px;
    }
  }

  .article-testimonial__items {
    display: grid;
    gap: 60px;
  }

  .article-testimonial__item {
    @include notebook {
      display: flex;

      .article-testimonial__image {
        flex-shrink: 0;
      }

      &:nth-child(odd) {
        .article-testimonial__image {
          margin-right: 30px;
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        .article-testimonial__image {
          margin-left: 30px;
        }
      }
    }
  }

  .article-testimonial__quote-start {
    @include quote-icon-start;
  }

  .article-testimonial__quote-end {
    @include quote-icon-end;
  }

  .article-testimonial__image {
    border-radius: 50%;
    height: 82px;
    margin: 0 auto;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
    width: 82px;

    @include notebook {
      height: 120px;
      width: 120px;
    }

    @include notebook {
      height: 120px;
      width: 120px;
    }

    @include desktop {
      height: 150px;
      width: 150px;
    }
  }

  .article-testimonial__text {
    @include typography(introduction);
  }

  .article-testimonial__attribution {
    @include typography(caption, true, true);

    color: $color-purple-light;
  }
}
