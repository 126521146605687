
:root {
  --link-menu-font-size: 18px;
  --link-menu-font-stack: #{$graphik-font-stack};
  --link-menu-font-style: normal;
  --link-menu-font-variant: normal;
  --link-menu-font-weight: #{get-graphik-weight(normal)};
  --link-menu-letter-spacing: .12px;
  --link-menu-line-height: 1.4em;
  --link-menu-margin-bottom: 0;
  --link-menu-margin-top: 0;
  --link-menu-word-spacing: normal;

  @include notebook {
    --link-menu-font-size: 13px;
    --link-menu-font-weight: #{get-graphik-weight(semibold)};
  }
}
