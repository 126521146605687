.accordion {
  padding: var(--component-margin) 0;
  position: relative;

  .accordion__header {
    margin-bottom: var(--component-margin);
  }

  .accordion__title {
    @include typography(heading-2);

    padding-bottom: 5px;
  }

  .accordion__introduction {
    @include typography(body-1);
  }

  .accordion__items {
    width: 100%;
  }
}
