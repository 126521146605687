.idea-section {
  align-items: start;
  display: block;
  //grid-auto-rows: min-content;
  //grid-column: var(--layout-content-large);
  //
  //@include tablet {
  //  @include grid-container;
  //}

  .idea-section__title {
    @include typography(heading-3);

    grid-column: column 3 / span 12;
    margin-bottom: 24px;
    margin-left: calc(100vw / 14);

    @include tablet {
      margin-left: var(--grid-gutter);
    }

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .idea-section-header {
    @include grid-container;

    background-color: rgba($color-purple-dark, .15);
    grid-column: 2 / 14;
    margin-bottom: 2px;
    overflow: hidden;
    padding: 30px 0;
    position: relative;
    width: 100%;

    .idea-section-header__text {
      grid-column: 2 / 14;
      margin-bottom: 25px;

      @include typography(introduction);
    }

    .idea-section-header__button {
      grid-column: 2 / 14;
      width: fit-content;
    }

    button {
      border-radius: 5px; // shrug
    }

    @include notebook {
      padding: 30px;
    }
  }

  .idea-section-body {
    grid-column: 2 / 14;

    .idea-section-list__items {
      padding: 0;
    }
  }

  .idea-section-idea {
    @include grid-container;
    @include animated-underline('.idea-section-idea-owner', '.idea-section-idea-owner__name');

    &:first-child {
      .idea-section-idea-status__count {
        font-size: 24px;

        @include notebook {
          font-size: 32px;
        }
      }
    }

    &:last-child {
      .idea-section-idea__divider {
        display: none;
      }
    }

    .idea-section-idea-status__liked {
      // display: none;
      grid-column: 2/2;
      margin-top: .125rem;
      opacity: 0;
      text-align: center;

      svg {
        fill: currentColor;
        height: 20px;
        stroke: transparent;
        width: 20px;
      }

      @include notebook {
        grid-column: 2/3;
      }
    }

    &.idea-section-idea--is-last-liked {
      @keyframes like-animation {
        0% {
          opacity: 0;
          transform: translate(0, 5px);
        }

        10% {
          opacity: 1;
        }

        15% {
          transform: translate(0, -5px);
        }

        30% {
          opacity: 1;
          transform: translate(0, 0);
        }

        75% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      .idea-section-idea-status__liked {
        animation: like-animation 2.5s ease-in-out;
        // display: initial;
      }
    }

    .idea-section-idea__header {
      grid-column: 3 / 13;
      margin-bottom: 10px;
      margin-left: 10px;

      .idea-section__title {
        margin-bottom: 1.25rem;
      }

      @include mobile-only {
        margin-left: 10px;
      }

      @include notebook {
        grid-column: 3 / 11;
      }
    }

    .comment-section-comment__action-dropdown {
      grid-column: 13;
      justify-self: end;
      position: relative;
      right: unset;
      top: -1px;
    }

    .idea-section-idea__body {
      grid-column: 3 / 13;
      margin-bottom: 1.25rem;
      margin-left: 10px;

      @include mobile-only {
        grid-column: 3 / 14;
        margin-left: 10px;
      }
    }

    .idea-section-idea__footer {
      grid-column: 3 / 15;
      margin-left: 10px;

      @include mobile-only {
        margin-left: 10px;
      }

      @include notebook {
        grid-column: 3 / 13;
      }
    }

    .idea-section-idea__divider {
      border-bottom: 1px solid rgba($color-purple-dark, .2);
      grid-column: 2/14;
      padding-top: 40px;
    }

    .idea-section-idea__button {
      .button-toggle__span,
      .button-toggle__span--toggle {
        font-size: 16px;
      }

      .button-toggle__title {
        margin-left: 5px;
      }

      svg {
        fill: currentColor;
        stroke: transparent;
      }

      float: left;
      margin-bottom: 5px;
      position: relative;
      text-align: left;
      top: -4px;
      width: 150px;

      @include tablet {
        margin-bottom: 0;
      }
    }

    .idea-section-idea-owner {
      .idea-section-idea-owner__avatar {
        border-radius: 50%;
        float: left;
        height: 25px;
        margin-right: 10px;
        width: 25px;
      }

      .idea-section-idea-owner__name {
        float: left;
        font-size: 16px;
        margin-right: 10px;
        width: auto;
      }

      .idea-section-idea-owner__labels {
        float: left;
        padding: 0;
      }

      display: block;
      float: left;
      width: auto;
    }

    overflow: hidden;
    padding: 40px 0;
    padding-bottom: 0;
    position: relative;

    @include tablet {
      width: auto;
    }

    @include notebook {
      padding: 40px 30px;

      &:not(:last-child) {
        padding-bottom: 0;
      }
    }
  }

  .idea-section-list__footer {
    margin-top: 15px;
    text-align: center;
  }

  .link.idea-section-load-more-button {
    color: currentColor;
    padding: 5px 20px;

    .link__icon--after {
      margin-left: 0;
    }

    .link__span {
      @include typography(link-small);
    }

    .link__icon {
      height: 1.4em;
      width: 1.4em;
    }
  }
}

.idea-section-idea-status {
  .idea-section-idea-status__count {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;

    @include notebook {
      font-size: 24px;
    }
  }

  .idea-section-idea-status__likes {
    @include typography(caption);

    margin-top: .25rem;
    text-align: center;
  }

  color: currentColor;
  display: flex;
  flex-direction: column;
  grid-column: 2 / 2;
  position: relative;

  @include notebook {
    grid-column: 2 / 3;
  }
}

.idea-section-user-label {
  background: $color-grey;
  border-width: 0;
  color: $color-purple-dark;
  margin-right: 10px;
}
