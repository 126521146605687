.author {
  @include typography(caption);

  align-items: center;
  color: $color-purple-light;
  display: flex;
  line-height: 1.3;

  .author__image {
    border-radius: 50%;
    height: 60px;
    margin-right: 20px;
    min-height: 60px;
    min-width: 60px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0); // fixes border radius with overflow hidden not working in safari
    width: 60px;
  }

  .author__content {
    width: 275px;
  }

  .author__name {
    font-weight: 600;
  }

  a {
    @include style-fancy-link-underline;

    color: inherit;
  }
}
