
:root {
  --heading-3-font-size: 20px;
  --heading-3-font-stack: #{$graphik-font-stack};
  --heading-3-font-style: normal;
  --heading-3-font-variant: normal;
  --heading-3-font-weight: #{get-graphik-weight(bold)};
  --heading-3-letter-spacing: -.34px;
  --heading-3-line-height: 1.3em;
  --heading-3-margin-bottom: 10px;
  --heading-3-margin-top: 30px;
  --heading-3-word-spacing: normal;

  @include notebook {
    --heading-3-font-size: #{get-fluid-value(20px, 24px)};
    --heading-3-letter-spacing: -.41px;
    --heading-3-margin-bottom: 15px;
    --heading-3-margin-top: 40px;
  }

  @include desktop {
    --heading-3-font-size: #{get-fluid-value(24px, 30px)};
    --heading-3-letter-spacing: -.5px;
    --heading-3-margin-top: 60px;
  }
}
