
.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    overflow: hidden;
    padding-top: 56.25%; // 16:9 ratio
    position: relative;
    width: 100%;

    .picture {
      height: auto;
      opacity: 1;
      width: 100%;
    }
  }

  .video-embed__title {
    @include typography(heading-3);

    color: $color-white;
    left: 20px;
    position: absolute;
    top: 20px;
    transition: opacity $easing-change-slower;
    z-index: 999;
  }

  .video-embed__caption {
    @include typography(caption, true, false, false);

    color: $color-purple-light;

    a {
      @include style-anchor-plain($inverted: false);

      color: $color-purple-dark;
    }
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__video {
    background-color: $color-purple-dark;
  }

  .video-embed__hit-target {
    cursor: pointer;
  }

  .video-embed__button {
    align-items: center;
    background: $color-purple;
    border-radius: 50%;
    color: $color-white;
    display: none;
    font-size: 2rem;
    height: 60px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 60px;

    @include notebook {
      height: 80px;
      width: 80px;
    }

    svg {
      display: block;
      height: 50%;
      width: 50%;
    }
  }

  .video-embed__iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  &.video-embed--loaded {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--loaded) {
    .video-embed__video {
      //filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      display: flex;
    }
  }
}
