@mixin alt-hover {
  @include notebook {
    > .link {
      display: inline-block;

      .link__icon--after {
        transition: transform 200ms ease;
      }
    }

    &.menu-link--active {
      > .link {
        .link__link {
          opacity: .5;
        }

        .link__span {
          background: none;
        }

        .link__icon--after {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.menu-link {
  @include mobile-only {
    .link__menu-wrapper {
      padding: 0 25px;
    }
  }

  @include notebook {
    > .link {
      display: inline-block;
      position: relative;
      z-index: 100;
    }
  }

  .menu-link--link {
    .link--cluster:hover {
      background: $color-purple-light2;
      border-radius: 11px 0 0 11px;
    }

    &.menu-link--active {
      .link--cluster {
        background: $color-white !important;
        border-radius: 11px 0 0 11px;

        .link__menu-wrapper {
          color: $color-purple;
        }
      }
    }
  }
}

.menu-link--popup-menu {
  @include alt-hover;
}

.menu-link--regular-link {
  .link__span {
    line-height: 38px;
  }

  @include mobile-only {
    .link .link__icon--after {
      display: none;
    }
  }

  @include notebook {
    .link__menu-wrapper {
      @include typography(paragraph);
    }

    .link__link {
      position: relative;
    }

    .link__span {
      background: none;
    }

    .link__icon--after {
      position: absolute;
      top: 11px;
    }

    .link:not(:hover):not(:focus) {
      .link__icon--after {
        visibility: hidden;
      }
    }
  }
}

.menu-link--emphasized {
  @include alt-hover;

  .menu-link--link {
    .link:hover {
      background: $color-purple-light2;
      border-radius: 11px 0 0 11px;
    }
  }

  @include mobile-only {
    > .link {
      > .link__menu-wrapper {
        padding: 0 10px;

        > .link__link {
          background: $color-purple-dark;
          border-radius: 10px;
          font-weight: bold;
          height: 54px;
          padding: 0 15px;

          > .link__icon--before {
            margin-right: 12px;

            ~ span {
              // hacky: make the font-weight normal again if there is an icon
              // nonhacky solution: a different variation
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  @include notebook {
    > .link {
      > .link__menu-wrapper {
        > .link__link {
          > .link__icon--before {
            display: none;
          }
        }
      }
    }
  }
}

.menu-link.menu-link--regular-link:hover {
  .link--menu {
    background: transparent;
  }
}

.menu-link--return {
  .link__menu-wrapper {
    font-weight: 600;
  }
}

.menu-link--footer-link {
  text-decoration: underline;
}

.menu-link--login {
  @include link--menu-bar;

  .link {
    .link__icon {
      height: 14px;
      top: -2px;
      transform: translate(1px, 2px);
      width: 12px;
    }

    .link__icon--before {
      margin-right: 10px;
    }

    .link__link {
      margin-top: 0;
    }

    .link__menu-wrapper {
      @include notebook {
        padding: 0;
      }
    }
  }
}

.menu-panel--menu-bar {
  .link {
    &.menu-link--logout {
      color: $color-white;
      margin-right: 25px;
      margin-top: 20px;
      width: auto;

      @include mobile-only {
        bottom: 15px;
        position: absolute;
        right: 10px;
      }

      .link__span {
        position: relative;
        top: -3px;
      }
    }
  }
}

.menu-panel-profile.menu-panel-profile--filled {
  .menu-panel-profile__icon {
    svg {
      fill: $color-white;

      @include mobile-only {
        fill: none;
      }
    }
  }
}
