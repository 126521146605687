.content-double {
  background-color: transparent;
  overflow-x: hidden;
  padding: var(--component-margin) 0;
  position: relative;

  .content-double__grid {
    @include grid-container;
  }

  .content-double__wrapper {
    grid-column: 2 / 14;
    position: relative;

    @include tablet {
      grid-column: 1 / 15;
    }

    @include desktop {
      grid-column: 1 / 17;
    }
  }

  .content-double__content {
    color: $color-purple-dark;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    overflow: hidden;

    @include tablet {
      flex-direction: row;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }

    .content-double__left {
      display: flex;
      flex: 1 0 50%;
      position: relative;
    }

    .content-double__right {
      display: flex;
      flex: 1 0 50%;
      position: relative;
    }

    .content-double__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 28px;
      margin-top: 30px;

      @include typography(introduction, false, true);

      @include tablet {
        //aspect-ratio: 1;
        height: auto;
        margin-top: 0;
        padding: 20px 40px;
      }

      @include notebook {
        //height: 100%;
        padding: 0 100px;
      }

      .content-double__text-title {
        @include typography(heading-2, false, true);

        margin-bottom: var(--component-margin);
      }

      .content-double__link {
        margin-top: 10px;
      }

      .list__item > div {
        color: $color-green;
      }
    }

    .content-double__video {
      @include tablet {
        //aspect-ratio: 1;
      }

      background-color: $color-purple-dark;
      position: relative;
      width: 100%;

      .video-embed {
        @include tablet {
          aspect-ratio: 16 / 9;
          height: 100%;
        }

        .video-embed__container {
          @include tablet {
            height: 100%;
            padding-top: initial;
          }
        }

        .video-embed__title {
          display: none;
        }

        @include tablet {
          .picture {
            height: 100%;
          }
        }
      }
    }

    .content-double__image {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;

      .picture--background {
        position: relative;
      }

      @include tablet {
        height: auto;
        margin: 10px;
        overflow: visible;
        position: static;

        .picture--background {
          position: absolute;
        }
      }
    }
  }

  .list__item:nth-of-type(1) {
    margin-top: 10px;
  }
}
