.hero-basic {
  overflow-x: hidden;
  position: relative;

  .hero-basic__container {
    display: flex;
    flex-direction: column;

    &::after {
      background: $color-grey;
      border-top-right-radius: var(--round-corner-size);
      bottom: 0;
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  .hero-basic__wrapper {
    background: $color-purple;
    color: $color-white;
    position: relative;

    @include grid-container;
  }

  .hero-basic__image {
    grid-column: column 1 / span 14;
    min-height: 290px;
    position: relative;
    width: 100%;

    @include notebook {
      min-height: 340px;
    }

    .picture {
      height: 100%;
      width: 100%;
    }
  }

  .hero-basic__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    grid-column: column 2 / span 12;
    justify-content: flex-end;
    padding: 60px 0 100px;
    position: relative;
    width: 100%;

    @include notebook {
      grid-column: column 3 / span 10;
      padding: 65px 0 110px;
    }
  }

  .hero-basic__title {
    @include typography(heading-1);

    margin-bottom: 30px;
  }

  .hero-basic__introduction {
    margin-bottom: 15px;
  }

  .hero-basic__date {
    margin-bottom: 10px;
  }
}
