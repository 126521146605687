
:root {
  --heading-6-font-size: 14px;
  --heading-6-font-stack: #{$graphik-font-stack};
  --heading-6-font-style: normal;
  --heading-6-font-variant: normal;
  --heading-6-font-weight: #{get-graphik-weight(bold)};
  --heading-6-letter-spacing: -.22px;
  --heading-6-line-height: 1.3em;
  --heading-6-margin-bottom: 10px;
  --heading-6-margin-top: 30px;
  --heading-6-word-spacing: normal;

  @include notebook {
    --heading-6-font-size: #{get-fluid-value(14px, 16px)};
    --heading-6-margin-top: 40px;
  }

  @include desktop {
    --heading-6-font-size: #{get-fluid-value(16px, 18px)};
    --heading-6-margin-top: 60px;
  }
}
