.u-padding-top-40 {
  padding-top: 0;

  @include tablet {
    padding-top: 40px;
  }
}

.u-padding-bottom-40 {
  padding-bottom: 0;

  @include tablet {
    padding-bottom: 40px;
  }
}
