
.article-quote {
  margin: 0;
  padding: var(--component-margin) 0;

  .article-quote__container {
    padding: 40px 0;
    position: relative;

    @include notebook {
      padding-bottom: 30px;
    }
  }

  .article-quote__grid {
    @include grid-container;
  }

  .article-quote__wrapper {
    grid-column: var(--layout-content-large);

    @include notebook {
      padding: 0 45px;
    }
  }

  .article-quote__quote {
    display: block;
  }

  .article-quote__text {
    @include typography(introduction);

    padding-top: var(--caption-line-height);
    position: relative;

    @include notebook {
      padding-top: 0;
    }
  }

  .article-quote__attribution {
    @include typography(caption, true, true);

    color: $color-purple-light;
  }

  .article-quote__quote-start {
    @include quote-icon-start;
  }

  .article-quote__quote-end {
    @include quote-icon-end;
  }
}
