.group-detail {
  background-color: $color-purple;
  color: $color-white;
  display: block;
  position: relative;

  &::after {
    background-color: $color-white;
    border-top-left-radius: var(--round-corner-size);
    content: '';
    display: block;
    height: var(--round-corner-size);
    width: 100%;
  }

  .group-detail__grid {
    align-items: start;
    display: block;
    grid-auto-rows: min-content;
    grid-column: var(--layout-content-large);
    padding: 45px 0;

    @include notebook {
      @include grid-container;

      grid-column: 2 / 14;
    }
  }

  .group-detail__container {
    grid-column: var(--layout-content-large);
  }

  .group-detail__wrapper {
    @include grid-container;

    grid-column: 2 / 14;
    margin: 0;

    @include notebook {
      display: block;
    }

    @include notebook {
      grid-column: var(--layout-content-large);
    }
  }

  .group-detail__description {
    font-size: larger;

    + .link {
      display: block;
      font-size: larger;
      margin-bottom: var(--component-margin);
      margin-bottom: calc(var(--component-margin) * 2);

      @include notebook {
        margin-bottom: var(--component-margin);
      }
    }
  }
}

.group-detail-header {
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  grid-column: var(--layout-content-large);

  @include notebook {
    flex-flow: row;
  }

  .group-detail-header__article-header {
    display: block;
    flex-basis: 100%;
    padding-top: calc(var(--component-margin) * 1.6);

    @include notebook {
      flex-basis: auto;
      flex-grow: 1;
      padding-top: calc(var(--component-margin) * 2);
    }

    .group-detail-header__title {
      display: inline-block;
      font-size: 30px;
      line-height: 1.1;
      margin-bottom: 18px;
      width: 89%;

      @include notebook {
        margin-bottom: 22px;
        margin-left: 0;
      }

      @include notebook {
        font-size: 40px;
      }
    }

    .group-detail-header__dropdown {
      float: right;
      top: 10px;
    }

    .modal-group-leave {
      .modal__wrapper {
        max-width: 100%;

        @include notebook {
          max-width: 500px;
        }
      }

      .form {
        margin-top: 20px;
      }
    }
  }

  .group-detail-header__members-container,
  .group-detail-header__button-container {
    width: 100%;

    @include notebook {
      display: inline-block;
      margin: 0;
      padding-left: calc(var(--component-margin) * 1.4);
      padding-top: calc(var(--component-margin) * 2);
      width: auto;
    }
  }

  .group-detail-header__members-container {
    margin-bottom: 12px;
    margin-top: 30px;

    @include notebook {
      margin-bottom: 0;
      margin-top: 0;
    }

    .members-info {
      margin-top: 5px;
      white-space: nowrap;

      @include notebook {
        text-align: right;
      }
    }

    .link {
      .link__span {
        background-size: 100% 1px;
        white-space: nowrap;
      }
    }
  }

  .group-detail-header__button-container {
    .modal .button {
      width: auto;
    }

    .button {
      width: 100%;

      @include notebook {
        width: auto;
      }
    }
  }

  .group-detail-header__description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: larger;
    -webkit-line-clamp: 2;
    line-height: 1.5;
    margin-bottom: 4px;
    overflow: hidden;

    @include notebook {
      -webkit-line-clamp: 3;
      width: 75%;
    }

    + .link {
      display: block;
      font-size: larger;
      margin-bottom: var(--component-margin);
      margin-bottom: calc(var(--component-margin) * 2);

      @include notebook {
        margin-bottom: var(--component-margin);
      }
    }
  }
}

.group-detail-header-meta {
  margin-bottom: calc(var(--component-margin) * 2);

  .group-detail-header-meta__status-icon {
    svg {
      height: 20px;
      stroke: $color-white;
      vertical-align: bottom;
      width: 20px;
    }
  }

  .group-detail-header-meta__status {
    margin-left: 4px;
    margin-right: 3px;
  }

  .group-detail-header-meta__owner {
    margin-left: 4px;
  }
}
