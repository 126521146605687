.membership-calculation {
  @include typography(caption);

  background-color: $color-purple;
  border-radius: 2px;
  color: $color-white;
  display: grid;
  gap: 20px;
  justify-items: start;
  padding: 15px;

  .membership-calculation__discount-tag {
    @include typography(tag);

    background: $color-white;
    border-radius: 2px;
    color: $color-purple-dark;
    display: inline-block;
    padding: 5px 10px;
  }

  .membership-calculation__price {
    align-items: flex-end;
    display: flex;
    font-weight: 700;
    justify-content: space-between;
    width: 100%;
  }

  .membership-calculation__price-value {
    flex-shrink: 0;
  }

  .membership-calculation__gift-item {
    align-items: start;
    column-gap: 10px;
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  .membership-calculation__gift-text {
    margin-top: -.4em;
  }
}
