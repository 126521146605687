
:root {
  --heading-2-font-size: #{get-fluid-value(22px, 24px)};
  --heading-2-font-stack: #{$graphik-font-stack};
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: #{get-graphik-weight(bold)};
  --heading-2-letter-spacing: -.41px;
  --heading-2-line-height: 1em;
  --heading-2-margin-bottom: 10px;
  --heading-2-margin-top: 30px;
  --heading-2-word-spacing: normal;

  @include notebook {
    --heading-2-font-size: #{get-fluid-value(24px, 30px)};
    --heading-2-line-height: 1em;
    --heading-2-letter-spacing: -.5px;
    --heading-2-margin-bottom: 15px;
    --heading-2-margin-top: 40px;
  }

  @include desktop {
    --heading-2-font-size: #{get-fluid-value(30px, 36px)};
    --heading-2-line-height: 1em;
    --heading-2-letter-spacing: -.64px;
    --heading-2-margin-bottom: 20px;
    --heading-2-margin-top: 60px;
  }
}
