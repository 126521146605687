
:root {
  --heading-4-font-size: 18px;
  --heading-4-font-stack: #{$graphik-font-stack};
  --heading-4-font-style: normal;
  --heading-4-font-variant: normal;
  --heading-4-font-weight: #{get-graphik-weight(bold)};
  --heading-4-letter-spacing: -.28px;
  --heading-4-line-height: 1.2em;
  --heading-4-margin-bottom: 10px;
  --heading-4-margin-top: 30px;
  --heading-4-word-spacing: normal;

  @include notebook {
    --heading-4-font-size: #{get-fluid-value(18px, 20px)};
    --heading-4-letter-spacing: -.31px;
    --heading-4-margin-bottom: 15px;
    --heading-4-margin-top: 40px;
  }

  @include desktop {
    --heading-4-font-size: #{get-fluid-value(20px, 24px)};
    --heading-4-letter-spacing: -.38px;
    --heading-4-margin-top: 60px;
  }
}
