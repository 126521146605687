.settings-action {
  margin-bottom: 10px;

  .settings-action__grid {
    @include grid-container;
  }

  .settings-action__action {
    border: 1px solid $color-purple-lightest;
    border-radius: 2px;
    grid-column: var(--layout-grid-inline);
  }

  .settings-action__settings-list {
    margin: 0;
    padding: 0;
  }

  .settings-action__settings-footer {
    padding: 10px;
  }

  .profile-setting {
    margin-left: auto;

    @include tablet {
      margin-left: 0;
    }
  }

  .form-fieldset {
    padding-bottom: 1.5rem;

    &__legend,
    &__description {
      font-size: 14px;
      margin-left: 1.875rem;
      margin-right: 1.875rem;

      @include tablet {
        font-size: 16px;
      }
    }

    &__description {
      flex-grow: 1;
      line-height: 1.5;
      margin-top: .75rem !important;
      padding-bottom: .75rem;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &__legend {
      margin-bottom: 0 !important;
      padding-top: 1.875rem;

      @include notebook {
        margin-left: 0;
      }
    }
  }

  &__buttons-wrapper {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    margin-top: 1.5rem;
  }
}
