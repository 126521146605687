.article-header {
  padding-top: calc(var(--component-margin) * 2);

  .article-header__grid {
    @include grid-container;
  }

  .article-header__wrapper {
    grid-column: var(--layout-content-large);
  }

  &.article-header--content {
    .article-header__wrapper {
      grid-column: var(--layout-content);
    }
  }

  &.article-header--extralarge {
    .article-header__wrapper {
      grid-column: var(--layout-content-extralarge);
    }
  }

  .article-header__title {
    @include typography(heading-1);
  }

  .article-header__subtitle {
    @include typography(introduction, true);
  }

  .article-header__intro-meta-wrapper {
    padding-bottom: var(--component-margin);
    padding-top: calc(var(--component-margin) * 2);
    width: 100%;
  }

  .article-header__meta-data {
    color: $color-purple-light;
    display: grid;
    margin-bottom: 30px;
    row-gap: 10px;

    @include notebook {
      align-items: end;
      column-gap: 50px;
      grid-template-columns: 1fr auto auto;
    }
  }

  .article-header__creation-date,
  .article-header__modicication-date {
    font-size: 12px;
    line-height: 1.35;
  }

  .article-header__introduction {
    @include typography(introduction);
  }
}

.article-header--compact {
  padding-top: calc(var(--component-margin));

  .article-header__intro-meta-wrapper {
    padding-top: calc(var(--component-margin) / 2);
  }
}

.article-header--picture {
  --background-color: #{$color-white};
  --picture-height: calc(66vh - var(--round-corner-size));

  padding: 0;
  position: relative;

  .article-header__image {
    height: calc(var(--picture-height) + var(--round-corner-size));
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 50%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .article-header__headings {
    height: calc(var(--picture-height) - var(--menu-height));
    margin-bottom: var(--round-corner-size);
    position: relative;

    &::after {
      background: var(--background-color);
      border-top-left-radius: var(--round-corner-size);
      content: '';
      height: var(--round-corner-size);
      left: 0;
      position: absolute;
      right: 0;
      top: calc(var(--picture-height) - var(--menu-height));
      transform: translateY(2px);
    }
  }

  .article-header__headings-wrapper {
    bottom: var(--component-margin);
    left: 0;
    position: absolute;
    right: 0;
  }

  .article-header__container,
  .article-header__title,
  .article-header__subtitle {
    color: $color-white;
    grid-column: var(--layout-content);
    text-align: center;
  }

  .article-header__container {
    margin-top: 10px;
  }

  .article-header__button {
    display: inline-block;
    height: auto;
    margin: auto;
    padding: 10px;
    vertical-align: middle;
  }

  .article-header__intro-meta-wrapper {
    background: var(--background-color);
    padding-top: 0;
  }
}

.article-header--picture-fullsize {
  --picture-height: 100vh;

  .article-header__image {
    height: var(--picture-height);
  }
}

.article-header--background-grey {
  --background-color: #{$color-grey};
}

.article-header--background-white {
  --background-color: #{$color-white};
}

.article-header--above-section-wrapper {
  --background-color: #{$color-grey};

  &.article-header--background-white {
    --background-color: #{$color-white};
  }

  @include desktop {
    .article-header__headings::after {
      left: var(--single-grid-column-with-gutter);
    }

    .article-header__introduction::before {
      background-color: $color-purple;
      content: '';
      height: 100%;
      position: absolute;
      width: var(--single-grid-column-with-gutter);
    }
  }
}

.article-header__intro-meta-date {
  position: relative;
  right: 30px;
  top: -90px;

  @include tablet {
    top: -72px;
  }

  .article-header__intro-meta-date__body,
  .article-header__intro-meta-date__footer {
    background-color: $color-purple-lightest2;
    float: right;
    text-align: center;
    width: 80px;
  }

  .article-header__intro-meta-date__body {
    border-radius: 10px 10px 0 0;
    margin-bottom: 2px;
    padding: 3px 12px;

    @include tablet {
      padding: 7px 12px 12px;
    }
  }

  .article-header__intro-meta-date__footer {
    border-radius: 0 0 10px 10px;
    padding: 1px 12px;
  }

  .article-header__intro-meta-date__day {
    font-size: 34px;
    font-weight: 100;
    line-height: 1em;

    @include tablet {
      font-size: 48px;
    }
  }

  .article-header__intro-meta-date__date,
  .article-header__intro-meta-date__time {
    font-size: 11px;
  }

  @include notebook {
    right: 0;
    top: 0;
  }
}
