.article-downloads {
  padding: var(--component-margin) 0;

  .article-downloads__grid {
    @include grid-container;
  }

  .article-downloads__wrapper {
    grid-column: var(--layout-content-large);
  }

  .article-downloads__title {
    @include typography(heading-3);

    margin-bottom: 30px;

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .article-downloads__primary-download .button {
    margin-bottom: 30px;
  }

  .article-downloads__downloads {
    display: grid;
    gap: var(--grid-gutter);
    list-style: none;
    margin: 0;
    padding: 0;

    @include notebook {
      grid-template-columns: 1fr 1fr;
    }
  }

  .article-downloads__link {
    display: block;
    padding: 10px;
    position: relative;
    top: 10px;

    @include notebook {
      display: inline;
      left: 20px;
      padding: unset;
      top: -12px;
    }
  }

  .modal-cao-downloads__outro {
    margin-bottom: var(--component-margin);
  }
}
