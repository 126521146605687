.content-block {
  background-color: $color-white;
  grid-auto-rows: min-content;
  grid-column: var(--layout-content-aside);
  margin-bottom: 10px;
  padding: 25px 0;

  @include notebook {
    padding: 40px 0;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}

.content-block--no-bottom-space {
  margin-bottom: 0;
  padding-bottom: 0;
}

.content-block--no-top-space {
  padding-top: 0;
}

.content-block--no-background {
  background-color: unset;
  padding: 1em 0;
}
