.dossier-text {
  padding: calc(var(--component-margin) * 2) 0;
  position: relative;

  @include notebook {
    align-items: center;
    display: flex;
    min-height: 100vh;
  }

  .dossier-text__grid {
    @include grid-container;
  }

  .dossier-text__wrapper {
    display: grid;
    grid-column: var(--layout-content-large);
    row-gap: calc(var(--component-margin) * 2);

    @include notebook {
      column-gap: var(--single-grid-column-with-gutter);
      grid-template-columns: 1fr 1fr;
    }
  }

  .dossier-text__title {
    @include typography(heading-2, false, true);
  }
}
