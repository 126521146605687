
.article-iframe-embed {
  @include grid-container;

  .article-iframe-embed__grid {
    grid-column: var(--layout-content-large);
    padding-top: 100%;
    position: relative;
    width: 100%;
  }

  .article-iframe-embed__iframe {
    border: none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
