.profile-delete-button {
  color: $color-error;

  svg {
    height: 20px;
    position: relative;
    stroke: $color-error;
    width: 20px;

    g {
      g {
        stroke: $color-error;
      }
    }
  }
}
