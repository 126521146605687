.article-disclosure {
  padding: var(--component-margin) 0;

  .article-disclosure__grid {
    @include grid-container;
  }

  .article-disclosure__wrapper {
    grid-column: var(--layout-content-large);
  }

  .article-disclosure__title {
    @include typography(heading-3, false, true);

    @include notebook {
      margin-left: var(--single-grid-column-with-gutter);
    }
  }

  .dossier-disclosure__title {
    @include typography(heading-2);
  }

  .dossier-disclosure__wrapper {
    grid-column: var(--layout-content);
  }
}
