.menu-panel-notification {
  padding: $default-spacer;
  position: relative;

  .menu-panel-notification__menu-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    margin-right: 0;
    position: relative;
    width: 32px;

    @include mobile-only {
      display: none;
    }
  }

  .menu-panel-notification__icon {
    background: rgba(255, 255, 255, .15);
    border-radius: 50%;
    color: $color-white;
    display: block;
    height: 32px;
    text-align: center;
    width: 32px;

    &:hover {
      background: rgba(255, 255, 255, .3);
    }

    svg {
      width: 12px;
    }
  }

  .menu-panel-notification__count {
    background: $color-white;
    border-radius: 6.5px;
    font-size: 10px;
    font-weight: bold;
    height: 13px;
    line-height: 1.1em;
    min-width: 13px;
    padding: 0px 3px 13px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 10px;
    width: auto;
  }
}

.menu-panel-notification-dropdown {
  background: $color-white;
  border-radius: 0 0 10px 10px;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 60px;
  transition: all .2s linear;
  width: 100%;

  @include tablet {
    right: -110px;
    width: 335px;
  }

  &::before {
    border-color: transparent transparent $color-white transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    margin-left: -5px;
    position: absolute;
    right: 96px;
    top: -10px;
    z-index: 10;

    @include tablet {
      right: 76px;
    }

    @include notebook {
      right: 127px;
    }
  }

  &.is-collapsed {
    opacity: 0;
    visibility: hidden;
  }

  .menu-panel-notification-dropdown__no-messages {
    background: $color-purple;
    border-radius: 0 0 10px 10px;
    color: #fff;
    display: none;
    min-width: 335px;
    padding: 1rem 1.5rem 1.6rem;
    position: absolute;
    top: -7px;
    z-index: 20;

    h4 {
      margin-bottom: .8rem;
    }

    p {
      font-size: 14px;
    }
  }

  .menu-panel-notification-dropdown__no-messages--active {
    display: block;
  }

  .menu-panel-notification-dropdown__name {
    font-weight: bold;
  }

  .menu-panel-notification-dropdown__list {
    max-height: 50vh;
    overflow-y: scroll;
    padding: 0;
  }

  .menu-panel-notification-dropdown__list-item {
    border-bottom: 1px solid $color-purple-lightest;
    list-style: none;

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
    }

    &:hover {
      @extend .background-primary--lighten-60 !optional;
    }

    &.is-unread {
      background: $color-grey;
    }
  }

  .menu-panel-notification-dropdown__link {
    display: flex;
    padding: 20px;
    text-decoration: none;
  }

  .menu-panel-notification-dropdown__avatar-container {
    margin-right: 20px;
    width: 40px;
  }

  .menu-panel-notification-dropdown__avatar {
    border-radius: 50%;
    display: block;
    height: 40px;
    max-width: 40px;
    width: 40px;
  }

  .menu-panel-notification-dropdown__details-container {
    margin-top: -4px;
  }

  .menu-panel-notification-dropdown__date,
  .menu-panel-notification-dropdown__text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 14px;
    -webkit-line-clamp: 3;
    line-height: 1.5;
    overflow: hidden;
    text-decoration: none;

    strong {
      font-weight: 600;
    }
  }

  .menu-panel-notification-dropdown__date {
    color: $color-purple-lighter;
    margin-top: 6px;
  }
}

.menu-panel-notification--filled {
  .menu-panel-notification__menu-item .menu-panel-notification__icon {
    fill: $color-white;

    svg {
      fill: $color-white;

      g {
        fill: $color-white;
      }

      @include mobile-only {
        fill: none;
      }
    }
  }
}
