
:root {
  --link-font-size: 16px;
  --link-font-stack: #{$graphik-font-stack};
  --link-font-style: normal;
  --link-font-variant: normal;
  --link-font-weight: #{get-graphik-weight(regular)};
  --link-letter-spacing: -.2px;
  --link-line-height: 1.3em;
  --link-margin-bottom: 0;
  --link-margin-top: 0;
  --link-word-spacing: normal;

  @include notebook {
    --link-font-size: #{get-fluid-value(16px, 16px)};
    --link-line-height: 1.3em;
    --link-letter-spacing: -.2px;
  }

  @include desktop {
    --link-font-size: #{get-fluid-value(16px, 18px)};
    --link-line-height: 1.3em;
    --link-letter-spacing: -.3px;
  }
}
