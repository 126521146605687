
:root {
  --field-label-font-size: 16px;
  --field-label-font-stack: #{$graphik-font-stack};
  --field-label-font-style: normal;
  --field-label-font-variant: normal;
  --field-label-font-weight: #{get-graphik-weight(regular)};
  --field-label-letter-spacing: 0;
  --field-label-line-height: 22px;
  --field-label-margin-bottom: 0;
  --field-label-margin-top: 0;
  --field-label-word-spacing: normal;
}
