
.cta-banner {
  padding: var(--component-margin) 0;
  position: relative;

  .cta-banner__grid {
    @include grid-container;

    @include notebook {
      display: block;
    }
  }

  .cta-banner__wrapper {
    grid-column: var(--layout-content);
  }

  .cta-banner__container {
    background-color: $color-purple;
    border-radius: 15px;
    color: $color-white;
    padding: 30px 20px;
  }

  .cta-banner__title {
    @include typography(heading-5);

    margin-bottom: 20px;
  }
}

.cta-banner--hidden {
  display: none;
}

.cta-banner--fixed {
  position: fixed;
  top: 50%;
  transition: transform $easing-change-slower;
  width: 200px;
  z-index: 10;

  @include notebook {
    &.anim-hide {
      transform: translate(100%, -50%);
    }

    right: 0;
    transform: translateY(-50%);
  }

  @include mobile-only {
    position: inherit;
    right: 0;
    width: 100%;
  }

  .cta-banner__grid {
    @include desktop {
      display: block;
    }
  }

  .cta-banner__container {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 200px;

    @include mobile-only {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      width: auto;
    }
  }
}

.cta-banner--fullwidth {
  background-color: $color-purple;
  border-radius: 0 0 2px 2px;
  color: $color-white;
  display: grid;
  grid-column: var(--layout-full);
  padding: 30px;

  @include tablet {
    padding: 30px 0;
  }

  @include desktop {
    padding: 30px;
  }

  .cta-banner__content {
    grid-column: 3 / 14;
    margin-bottom: 25px;

    @include notebook {
      grid-column: 3 / 14;
      margin: 0;
    }

    @include desktop {
      grid-column: 2 / 14;
    }
  }

  .cta-banner__title {
    margin-bottom: 8px;
  }

  .cta-banner__action {
    grid-column: 3 / 14;

    @include notebook {
      grid-column: 14 / 15;
    }
  }
}
