
:root {
  --field-helptext-font-size: 12px;
  --field-helptext-font-stack: #{$graphik-font-stack};
  --field-helptext-font-style: normal;
  --field-helptext-font-variant: normal;
  --field-helptext-font-weight: #{get-graphik-weight(regular)};
  --field-helptext-letter-spacing: 0;
  --field-helptext-line-height: 16px;
  --field-helptext-margin-bottom: 0;
  --field-helptext-margin-top: 0;
  --field-helptext-word-spacing: normal;
}
