
:root {
  --caption-font-size: 14px;
  --caption-font-stack: #{$graphik-font-stack};
  --caption-font-style: normal;
  --caption-font-variant: normal;
  --caption-font-weight: #{get-graphik-weight(regular)};
  --caption-letter-spacing: -.23px;
  --caption-line-height: 18px;
  --caption-margin-bottom: .5rem;
  --caption-margin-top: .5rem;
  --caption-word-spacing: normal;
}
