.container {
  position: relative;
}

.container--header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.container--content {
  .container--content__wrapper {
    background: $color-white;
  }

  .container--content__holder {
    background: $color-white;
  }
}
