.search-header {
  .search-header__title {
    color: $color-purple;
    font: 700 28px/34px $graphik-font-name;
    letter-spacing: -.46875px;
    margin-bottom: 16px;
    width: 100%;

    @include notebook {
      font: 700 35px/48px $graphik-font-name;
      letter-spacing: -.859375px;
      margin: 32px 0;
    }
  }

  .search-header__search {
    width: 100%;
  }

  .search-header__filters {
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    padding: 16px 0 0;
  }
}
