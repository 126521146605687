.cao-overview {
  background: $color-purple;
  color: $color-white;
  padding: var(--component-margin) 0;
  position: relative;

  .cao-overview__grid {
    @include grid-container;
  }

  .cao-overview__wrapper {
    column-gap: var(--grid-gutter);
    display: grid;
    grid-column: var(--layout-content-large);
    row-gap: 50px;

    @include notebook {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }

  .cao-overview__my-cao {
    grid-column: column 2/span 12;
    margin-bottom: 30px;
    margin-top: 15px;

    @include notebook {
      grid-column: column 3/span 10;
      margin-bottom: 50px;
    }

    @include desktop {
      grid-column: column 4/span 10;
    }
  }

  .cao-overview__my-cao-title {
    @include typography(tag);

    color: $color-purple-light2;
    margin-bottom: 8px;
  }

  .cao-overview__item-container {
    @include notebook {
      display: flex;
      flex-direction: column;
    }
  }

  .cao-overview__block {
    align-items: flex-start;
    background-color: rgba($color-purple-dark, .15);
    display: flex;
    flex-direction: column;
    padding: 18px 15px;

    &:last-child {
      flex-grow: 1;
    }
  }

  .cao-overview__title {
    @include typography(heading-4);

    margin-bottom: 10px;

    @include notebook {
      margin-bottom: 20px;
    }
  }

  .cao-overview__in-progress {
    @include typography(tag);

    color: $color-purple-light2;
    margin-bottom: 20px;

    //&::before {
    //  background-color: #28d739;
    //  border-radius: 50%;
    //  content: '';
    //  display: inline-block;
    //  filter: blur(1px);
    //  height: 9px;
    //  margin-right: 8px;
    //  position: relative;
    //  top: 1px;
    //  width: 9px;
    //}
  }

  .cao-overview__block + .cao-overview__block {
    margin-top: 2px;
  }

  #cao-overview-list-companies {
    max-height: 330px;
    min-height: 100px;
    overflow: auto;
    width: 100%;
  }

  .cao-overview__list {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 10px;

    &.is-collapsed {
      // display first 3 items
      li:nth-child(n+4) {
        display: none;
      }
    }
  }

  .cao-overview__toggle-more-btn {
    margin-top: 40px;
  }
}
