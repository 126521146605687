.invite-member {
  align-items: start;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), [column] minmax(0, 1fr));
  max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
  padding-bottom: 1.5rem;
  padding-left: calc(var(--grid-gutter) + var(--grid-margin));
  padding-right: calc(var(--grid-gutter) + var(--grid-margin));
  padding-top: 1.5rem;

  .invite-member__grid {
    display: block;
    grid-column: var(--layout-grid-inline);

    .invite-member__column {
      display: inline-block;
      width: 100%;

      @include notebook {
        display: inline-block;
        width: 49%;
      }

      .button--tertiary-compact {
        margin-bottom: .5rem;
      }
    }

    .whats-app {
      display: inline-flex;
      //
      //@include notebook {
      //  display: none;
      //}
    }

    .button--primary-compact .button__icon--before {
      margin-left: -.25em;
      transform: scale(.81);
    }

    h3 {
      @include typography(heading-3, true, true);

      display: block;
      margin-bottom: var(--heading-3-margin-bottom);
      width: 100%;
    }

    h4 {
      display: block;
      margin-bottom: var(--heading-3-margin-bottom);
      margin-top: var(--heading-3-margin-bottom);
      width: 100%;
    }
  }
}
