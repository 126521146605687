.usp-aside {
  @include typography(caption);

  display: grid;
  row-gap: 1px;

  .usp-aside__item {
    background: $color-grey;
    color: $color-purple-light;
    padding: 15px;

    &:first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .usp-aside__title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .usp-aside__list {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 10px;
  }

  .usp-aside__list-item {
    align-items: baseline;
    display: grid;
    grid-template-columns: var(--paragraph-line-height) auto;

    svg {
      height: var(--paragraph-line-height);
      margin-left: -5px;
      width: var(--paragraph-line-height);
    }
  }
}

.usp-aside--grey {
  .usp-aside__item {
    background: rgba($color-purple-lightest, .5);
    color: $color-purple-light;
  }
}

.usp-aside--purple {
  .usp-aside__item {
    background: rgba($color-purple-light2, .15);
    color: $color-white;
  }
}

.usp-aside--animating {
  .swiper-container {
    width: 100%;
  }
}
