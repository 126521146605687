
.link-list {
  // padding: var(--component-margin) 0;

  .link-list__grid {
    @include grid-container;
  }

  .link-list__wrapper {
    display: grid;
    gap: 15px;
    grid-column: var(--layout-content-large);

    @include notebook {
      gap: 50px var(--grid-gutter);
      grid-template-columns: 1fr 1fr;
    }
  }

  .link-list__content {
    display: grid;
    grid-column: 2 / 14;
    margin-bottom: 40px;

    @include notebook {
      grid-column: 3 / 12;
    }

    @include desktop {
      grid-column: 4 / 12;
    }
  }

  .link-list__title {
    @include typography(heading-5);

    border-bottom: 1px solid $color-purple-light;
    margin-bottom: var(--heading-3-margin-bottom);
    padding-bottom: 10px;
    // margin-left: var(--single-grid-column-with-gutter);
    // margin-right: var(--single-grid-column-with-gutter);
  }

  .link-list__links {
    display: grid;
    list-style: none;
    padding: 0;
    row-gap: 15px;
  }

  .link__span {
    font-size: 16px;
  }

  .link-list__card {
    margin-top: 50px;

    @include notebook {
      max-width: calc(var(--single-grid-column) * 4 + var(--grid-gutter) * 3);
    }
  }
}
