
.diptych {
  padding: var(--component-margin) 0;

  .diptych__grid {
    @include grid-container;
  }

  .diptych__wrapper {
    @include mobile {
      --diptych-grid-columns: 12;
    }

    @include desktop {
      --diptych-grid-columns: 14;
    }

    @include grid-container($columns: 'repeat(var(--diptych-grid-columns), [column] minmax(0, 1fr))', $nested: true);

    background: $color-grey-lighter;
    border-radius: 10px;
    grid-column: 2 / 14;
    grid-gap: 0;
    isolation: isolate;
    overflow: hidden;
    position: relative;

    @include notebook {
      grid-column: 2 / 14;
    }

    @include desktop {
      grid-column: 2 / 16;
    }
  }

  .diptych__side-left {
    grid-column: 1 / 13;
    position: relative;

    @include notebook {
      grid-column: 1 / 7;
    }

    @include desktop {
      grid-column: 1 / 8;
    }
  }

  .diptych__side-right {
    border-radius: 0 10px 10px 0;
    grid-column: 1 / 13;
    position: relative;

    @include notebook {
      grid-column: 7 / 13;
    }

    @include desktop {
      grid-column: 8 / 15;
    }
  }
}

.diptych-side {
  color: $color-purple-dark;

  &--maintain-aspect-ratio {
    aspect-ratio: 1;

    @include notebook {
      aspect-ratio: 1;
    }
  }

  .diptych-side__text {
    line-height: 28px;
    padding: 20px;

    @include tablet {
      padding: 40px;
    }

    @include desktop {
      padding: 60px;
    }

    @include typography(introduction, false, true);
  }

  .diptych-side__text-title {
    @include typography(heading-3, false, true);

    margin-bottom: var(--component-margin);
  }

  .diptych-side__link-container {
    margin-top: 10px;
  }

  .diptych-side__video {
    height: 100%;

    .video-embed {
      height: 100%;

      .video-embed__container {
        height: 100%;
      }

      .video-embed__title {
        display: none;
      }

      .picture {
        height: 100%;
      }
    }
  }

  .diptych-side__image {
    aspect-ratio: 16/9;
    height: 100%;
    overflow: hidden;
    position: relative;

    @include tablet {
      height: auto;
      overflow: visible;
      position: static;
    }
  }
}
