
.article-photo {
  padding: var(--component-margin) 0;
  position: relative;

  .article-photo__grid {
    @include grid-container;
  }

  .article-photo__wrapper {
    grid-column: var(--layout-content-image);
  }
}
