
.article-accordeon {
  padding: var(--component-margin) 0;
  position: relative;

  .article-accordeon__grid {
    @include grid-container;
  }

  .article-accordeon__wrapper {
    grid-column: column 1 / span 12;
    max-width: 32em;
  }

  .article-accordeon__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }
}
